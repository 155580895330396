import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Text from "../Text";
import {
  assetsUnderManagement,
  estimatedWealth,
  yearsOfExperience,
} from "../../../utils/SelectData";
import { API_END_POINT } from "../../../Api/EndPoints";
import "./UserDetailsModal.css";
import { deleteUser, getClientById } from "../../../REDUX/actions/clients";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { CancelIcon, SuccessIcon } from "../../Svgs";
import CercledButton from "../Button/CercledButton";
import { closeModal } from "../../../REDUX/actions/modal";
import { formatDate } from "../../../utils/utils";
import { Button } from "react-bootstrap";
import moment from "moment";
import ToastMessage from "./ToastMessage";
import {
  closeConfirmationModal,
  openBlockModal,
  openConfirmationModal,
} from "../../../REDUX/actions/ConfirmationModal";
const UserDetailsModal = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client, clientDeleteLoading } = useSelector(
    (state) => state.clientsReducer
  );
  const handleClose = () => {
    dispatch(closeModal());
  };
  const nav = (client) => {
    dispatch({ type: "FETCH_CLIENT_ITEM", payload: client });
    if (client?.attributes?.profileType === 0) {
      navigate(`/professionals/${client.id}`);
    } else {
      navigate(`/investors/${client.id}`);
    }
    handleClose();
  };
  useEffect(() => {
    dispatch(getClientById(userId));
    // eslint-disable-next-line
  }, []);

  const handleDeleteUser = () => {
    !client?.attributes?.delete
      ? dispatch(openBlockModal())
      : dispatch(
          openConfirmationModal({
            callBack: () =>
              dispatch(
                deleteUser(
                  userId,
                  client?.attributes?.delete
                    ? null
                    : moment(new Date()).format("YYYY-MM-DD"),
                  client?.attributes?.clubDeals,
                  null,
                  client?.attributes?.profileType
                )
              ),
            message: `Would you like ${
              client?.attributes?.delete ? "activate" : "delete"
            } this user ?`,
            message2: `${client?.attributes.firstName} ${client?.attributes.lastName} `,
            confirmButtonText: "ok",
            cancelButtonText: "cancel",
            cancelCallback: () => dispatch(closeConfirmationModal()),
          })
        );
  };
  return !client ? (
    <Loading />
  ) : (
    <div className="px-4 pb-4">
      <div className="main">
        <div className="cover">
          <div className="position-absolute t-r-0">
            <CercledButton
              iconColor={"white"}
              icon={faPenToSquare}
              bgColor={"blue"}
              callback={() => nav(client)}
            />
          </div>
          <img
            src={
              client?.attributes?.coverPicture.data
                ? API_END_POINT +
                  client?.attributes?.coverPicture.data.attributes.url
                : process.env.PUBLIC_URL + "/default-cover.png"
            }
            alt=""
            className="ob-cover"
          />
        </div>
        <div className="profile">
          <img
            src={
              client?.attributes?.photo.data
                ? API_END_POINT + client?.attributes?.photo.data.attributes.url
                : process.env.PUBLIC_URL + "/user.jpg"
            }
            alt=""
            className="ob-cover w-100 h-100"
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center mb-2 h">
        <Text className={"f20 bold "}>Personal Information</Text>
        <button
          type="button"
          className={`btn  ${
            client?.attributes?.delete ? "bg-success text-white" : "btn-danger"
          } h44 `}
          onClick={handleDeleteUser}
        >
          {clientDeleteLoading
            ? "loading..."
            : client?.attributes?.delete
            ? "Activate"
            : "Delete"}
        </button>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Id: </Text>
        <Text className={"col f14 regular"}>{client?.id}</Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Email: </Text>
        <Text className={"col f14 regular "}>{client?.attributes?.email}</Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>First Name: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.firstName}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Last Name: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.lastName}
        </Text>
      </div>
      {client?.attributes?.phoneNumber && (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>phone Number: </Text>
          <Text className={"col f14 regular"}>
            {client?.attributes?.phoneNumber}
          </Text>
        </div>
      )}

      <div className="row">
        <Text className={"col f16 py-1 medium "}>Created at: </Text>
        <Text className={"col f14 regular"}>
          {formatDate(client?.attributes?.createdAt)}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Job Title: </Text>
        <Text className={"col f14 regular"}>
          {client?.attributes?.jobTitle}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Years of experience: </Text>
        <Text className={"col f14 regular"}>
          {
            yearsOfExperience.find(
              (year) => year.value === client?.attributes?.yearsOfExperience
            )?.label
          }
        </Text>
      </div>
      {client?.attributes?.profileType === 0 ? (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>
            assets Under Management:{" "}
          </Text>

          <Text className={"col f14 regular "}>
            {
              assetsUnderManagement.find(
                (asset) =>
                  asset.value === client?.attributes?.assetsUnderControl
              )?.label
            }
          </Text>
        </div>
      ) : (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>Estimated wealth: </Text>

          <Text className={"col f14 regular "}>
            {
              estimatedWealth.find(
                (asset) => asset.value === client?.attributes?.wealth
              )?.label
            }
          </Text>
        </div>
      )}
      <div className="row">
        <Text className={"col f16 py-1 medium "}>Country: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.country.data?.attributes.countryName}
        </Text>
      </div>
      {client?.attributes?.profileType === 0 && (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>languages: </Text>
          <Text className={"col f14 regular "}>
            {client?.attributes?.languages?.data?.map(({ attributes, id }) => (
              <Text key={id}>{attributes?.languageName}</Text>
            ))}
          </Text>
        </div>
      )}
      {client?.attributes?.profileType === 0 && (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>premium </Text>
          <Text className={"col f14 regular "}>
            {client?.attributes?.premium ? <SuccessIcon /> : <CancelIcon />}
          </Text>
        </div>
      )}

      {client?.attributes?.profileType === 0 && (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>access to moneeverse: </Text>
          <Text className={"col f14 regular "}>
            {client?.attributes?.moneeverseToken === true ? (
              <SuccessIcon />
            ) : (
              <CancelIcon />
            )}
          </Text>
        </div>
      )}
      <div className="row">
        <Text className={"col f16 py-1 medium "}>connected app: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.connectedApp ? <SuccessIcon /> : <CancelIcon />}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>connected site: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.connectedSite ? <SuccessIcon /> : <CancelIcon />}
        </Text>
      </div>

      <div className="row">
        <Text className={"col f16 py-1 medium "}>Notifications on posts: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.mailNotificationOnPosts ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>
          Notifications on Comment :
        </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.mailNotificationOnComment ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>
          Notifications on clubdeals:{" "}
        </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.mailNotificationOnClubDeal ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>
          Notifications on messages:{" "}
        </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.mailNotificationOnMessage ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>
          Notifications on team member:{" "}
        </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.mailNotificationOnTeamMember ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 py-1 medium "}>email confirmed: </Text>
        <Text className={"col f14 regular "}>
          {client?.attributes?.emailConfirmed ? (
            <SuccessIcon />
          ) : (
            <CancelIcon />
          )}
        </Text>
      </div>

      {client?.attributes?.profileType === 1 && (
        <div className="row">
          <Text className={"col f16 py-1 medium "}>confident :</Text>
          <Text className={"col f14 regular "}>
            {client?.attributes?.confident ? <SuccessIcon /> : <CancelIcon />}
          </Text>
        </div>
      )}

      {/* <div className="row align-items-center justify-content-between pt-4 px-2">
        {!client?.attributes.state || client?.attributes.state === 0 ? (
          <Button
            textButton={"update"}
            className={"col "}
            color="blue"
            callback={() =>
              dispatch(
                openConfirmationModal({
                  callBack: () => dispatch(UpdateClientState(1, client?.id)),
                  message: `Would you like to update the state of this investor ?`,
                  message2: `${client?.attributes.firstName} ${" "} ${
                    client?.attributes.lastName
                  } `,
                  confirmButtonText: "Activate",
                  cancelButtonText: "disactivate",
                  cancelCallback: () =>
                    dispatch(UpdateClientState(2, client?.id)),
                })
              )
            }
          />
        ) : (
          <Button
            textButton={
              client?.attributes.state === 1 ? "disactivate" : "activate"
            }
            className={`col ${
              client?.attributes.state === 1 ? "border-red" : "border-green"
            }`}
            color={`${client?.attributes.state === 1 ? "red" : "green"}`}
            callback={() =>
              dispatch(
                openConfirmationModal({
                  callBack: () =>
                    dispatch(
                      UpdateClientState(
                        client?.attributes.state === 1 ? 2 : 1,
                        client?.id
                      )
                    ),
                  icon: "archive",
                  message: `Would you like to ${
                    client?.attributes.state === 1 ? "disactivate" : "activate"
                  } this investor ?`,
                  message2: `${client?.attributes.firstName} ${" "}${
                    client?.attributes.lastName
                  }`,
                  confirmButtonText: "Submit",
                  cancelButtonText: "Cancel",
                })
              )
            }
          />
        )}
      </div> */}
      <ToastMessage />
    </div>
  );
};

export default UserDetailsModal;
