import "./App.css";
import "./custom.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import AuthCheck from "./Components/AuthCheck/AuthCheck";
import Layout from "./Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { useEffect, useReducer } from "react";
import { getAllCountries } from "./REDUX/actions/country";
import { getAllDeals } from "./REDUX/actions/deals";
import { getAllLanguages } from "./REDUX/actions/Language";

function App() {
  const dispatch = useDispatch();

  const { user } = useReducer((state) => state.usersReducer);
  useEffect(() => {
    if (user) {
      dispatch(getAllCountries());
      dispatch(getAllDeals());
      dispatch(getAllLanguages());
    }
  }, [user]);
  return (
    <div className="App">
      <BrowserRouter>
        <AuthCheck>
          <Layout>
            <Routes />
          </Layout>
        </AuthCheck>
      </BrowserRouter>
    </div>
  );
}

export default App;
