export const ATTRIBUTES_COUNTRIES = `
    code
    countryName
    prefixCode
    flag {
        data {
            id
            attributes {
                url
            }
        }
    }
`;
