import React, { useEffect, useState } from "react";
import { ChevronLeft } from "../Svgs";
import { ChevronRight } from "../Svgs";
import { useDispatch } from "react-redux";
import "./Paggination.css";

const PagginationButton = ({
  action = () => {},
  pages,
  pageNumber,
  type,
  filters,
  sortingOptions,
  totalItems,
}) => {
  const dispatch = useDispatch();
  const [selectedPage, setselectedPage] = useState(pageNumber);
  const pagginate = (targetPage) => {
    if (targetPage !== 0 && targetPage !== pages + 1) {
      setselectedPage(targetPage);
      if (type === 0 || type === 1) {
        dispatch(action(type, targetPage, filters, sortingOptions));
      } else {
        dispatch(action(targetPage, 20, filters, sortingOptions));
      }
    }
  };

  useEffect(() => {
    setselectedPage(pageNumber);
  }, [pageNumber]);

  return !pages ? null : (
    <div className="d-flex text-blue align-items-center justify-content-between align-items-center w90 mx-auto mt-5 mb-2">
      <button
        onClick={() => pagginate(selectedPage - 1)}
        className="all-unset f20 d-flex gap-3 align-items-center"
      >
        <ChevronLeft />
        Previous
      </button>
      <div className="text-blue medium f18 px-2 m-0 pointer d-flex flex-column alignC">
        <div>
          <strong>{selectedPage}</strong> /{" "}
          <span className="f18">{pages} pages</span>
        </div>{" "}
        <div className="f18">
          (total items <strong>{totalItems}</strong> )
        </div>
      </div>
      <button
        onClick={() => pagginate(selectedPage + 1)}
        className="all-unset f20 d-flex gap-3 align-items-center"
      >
        Next <ChevronRight />
      </button>
    </div>
  );
};

export default PagginationButton;
