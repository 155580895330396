import API from "../../Api/Api";
import { UPDATE_AD, UPDATE_ADS_STATE } from "../Graphql/Mutations/Ads";
import { DELETE_FILE } from "../Graphql/Mutations/files";
import { GET_ADS, GET_ADS_BY_ID } from "../Graphql/Queries/Ads";
import client from "./Client";
import { handleError, handleInfo } from "./toastMessage";

export const getAllAds =
  (page = 1, pageSize = 20, search, sort) =>
  async (dispatch) => {
    dispatch({ type: "ADVERTISES_LOADING" });

    try {
      const { data } = await client.query({
        query: GET_ADS(page, pageSize, search, sort),
      });

      dispatch({
        type: "FETCH_ADVERTISES",
        payload: data.advertises.data,
        totalPages: data.advertises.meta?.pagination?.pageCount,
        page: data.advertises.meta?.pagination?.page,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const UpdateAdsState = (state, adsId) => async (dispatch) => {
  dispatch({ type: "ADVERTISES_LOADING" });
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_ADS_STATE,
      variables: {
        id: parseInt(adsId),
        state: state,
      },
    });

    dispatch({
      type: "UPDATE_ADVERTISES",
      payload: data.updateAdvertise.data,
    });
    dispatch({
      type: "FETCH_ADS_ITEM",
      payload: data.updateAdvertise.data,
    });

    dispatch(handleInfo("Advertise state updated", 1));
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};

export const getAdsItem = (id) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_ADS_BY_ID(id),
    });

    dispatch({
      type: "FETCH_ADS_ITEM",
      payload: data.advertises.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch(handleError("Error try again later", 1));
  }
};

export const updateAd =
  (id, attributes, uploads, oldFiles, callback) => async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_AD_ITEM_LOADING" });
      if (Object.keys(uploads)?.length > 0) {
        for (const field of Object.keys(uploads)) {
          if (!uploads[field]) return;
          const formData = new FormData();
          formData.append("files", uploads[field]);
          formData.append("refId", id);
          formData.append("ref", "api::advertise.advertise");
          formData.append("field", field);
          await API.post("/api/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        }
      }

      await client.mutate({
        mutation: UPDATE_AD,
        variables: {
          id,
          ...attributes,
        },
      });

      oldFiles?.forEach(async (id) => {
        await client.mutate({
          mutation: DELETE_FILE,
          variables: {
            id,
          },
        });
      });

      callback();
      dispatch({ type: "UPDATE_AD_ITEM_SUCC" });
      dispatch(getAdsItem(id));
    } catch (error) {
      dispatch({ type: "UPDATE_AD_ITEM_FAIL", payload: error });
    }
  };
