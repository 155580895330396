import React from "react";
import { handleError, handleInfo } from "../../../REDUX/actions/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "react-bootstrap";
import "./ToastMessage.css";
const ToastMessage = () => {
  const dispatch = useDispatch();

  const { info, infoMessage, error, errorMessage } = useSelector(
    (state) => state.toastMessagesReducer
  );

  return (
    <>
      <ToastContainer className="myNotif">
        <Toast
          show={info}
          delay={4000}
          autohide
          onClose={() => dispatch(handleInfo(null, 0))}
        >
          <Toast.Body
            className={`f14 xbold text-start bg-success py-4 text-white d-flex align-items-center`}
          >
            <i className={`bi bi-check-circle-fill me-4 f26 text-white`}></i>
            {infoMessage}
          </Toast.Body>
        </Toast>
        <Toast
          show={error}
          delay={4000}
          autohide
          onClose={() => dispatch(handleError(null, 0))}
        >
          <Toast.Body className="f14 xbold text-start bg-danger py-4 text-white d-flex align-items-center">
            <i className="bi bi-x-circle-fill f26 me-4"></i>
            {errorMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ToastMessage;
