import React from "react";
import { useSelector } from "react-redux";

import Text from "../Common/Text";

import AdsItem from "./AdsItem";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
const AdsList = ({ sortingOptions, setsortingOptions }) => {
  const { advertises, advertisesLoading } = useSelector(
    (state) => state.advertisesReducer
  );
  const handleSort = (champs) => {
    let temparray = sortingOptions;
    temparray = `sort: "${champs}:${
      sortingOptions.includes("asc") ? "desc" : "asc"
    }"`;
    setsortingOptions(temparray);
  };
  return (
    <div className="mb-5">
      <div className="container-fluid w100 pagginationContainer">
        <div className="row h44">
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("id")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>ID</Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("tagline")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Tagline
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("companyName")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Company Name
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("createdAt")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Created at
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("startingDay")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Starting day
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("duration")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              duration
            </Text>{" "}
          </div>
          <div
            className="col-3 alignC bordered py-3"
            onClick={() => handleSort("link")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              link
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("numberDisplays")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Display number
            </Text>{" "}
          </div>
          <div className="col-1 alignC bordered py-3">
            <Text className={" f14 medium me-1 text-center "}>Clicks</Text>{" "}
          </div>
          <div className="col-1 alignC bordered py-3">
            <Text className={" f14 medium me-1 text-center "}>Actions</Text>{" "}
          </div>
        </div>
      </div>

      {advertisesLoading ? (
        <Loading />
      ) : advertises.length === 0 ? (
        <EmptyList text={"No Ads found"} />
      ) : (
        advertises.map((item, index) => {
          return <AdsItem item={item} key={index} index={index} />;
        })
      )}
    </div>
  );
};
export default AdsList;
