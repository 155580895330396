import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { check } from "../../REDUX/actions/users";

const AuthCheck = ({ children }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(check(navigate));
    // eslint-disable-next-line
  }, []);
  return <div>{children}</div>;
};

export default AuthCheck;
