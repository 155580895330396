export const BreadCrumb = (path) => {
  const pathArray = path.split("/");
  switch (pathArray[1]) {
    case "":
      return <>Dashboard</>;
    case "investors":
      return <>Investors List</>;
    case "professionals":
      return <>Professionals List</>;
    case "companies":
      return <>Companies List</>;
    case "meetings":
      return <>Meetings List</>;
    case "feedback":
      return <>Feedback List</>;

    case "deals":
      return <>Deals List</>;
    case "ads":
      return <>Ads List</>;
    case "adminManagement":
      return <>Admin List</>;

    default:
      break;
  }
};
