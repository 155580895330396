export const ATTRIBUTES_DEAL = `
tagline
description
dealSize
commitment
minInvestement
state
dealLogo{
    data{
        id
        attributes{
            url
        }
    }
}
user{
    data{
        id
        attributes{
            profileType
            firstName
            lastName
            email
        }
    }
}
`;
