import React from "react";
import { useSelector } from "react-redux";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import Text from "../Common/Text";
import FeedBackItem from "./FeedBackItem";
const FeedBackList = ({ sortingOptions, setsortingOptions }) => {
  const { feedbacks, feedbacksLoading } = useSelector(
    (state) => state.feedbacksReducer
  );

  const handleSort = (champs) => {
    let temparray = sortingOptions;
    temparray = `sort: "${champs}:${
      sortingOptions.includes("asc") ? "desc" : "asc"
    }"`;
    setsortingOptions(temparray);
  };

  return (
    <div className="mb-5">
      <div className="container-fluid w100 pagginationContainer">
        <div className="row h44">
          <div
            className="col-1 alignC bordered"
            onClick={() => handleSort("id")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>ID</Text>{" "}
          </div>

          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("createdAt")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              created At
            </Text>{" "}
          </div>
          <div className="col-3 alignC bordered py-3">
            <Text className={" f14 medium me-1 text-center pointer "}>
              User id
            </Text>{" "}
          </div>
          <div
            className="col alignC bordered py-3"
            onClick={() => handleSort("content")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Content
            </Text>{" "}
          </div>
        </div>
      </div>
      {feedbacksLoading ? (
        <Loading />
      ) : feedbacks.length === 0 ? (
        <EmptyList text={"No Feedback found"} />
      ) : (
        feedbacks.map((item, index) => {
          return <FeedBackItem item={item} key={index} index={index} />;
        })
      )}
    </div>
  );
};

export default FeedBackList;
