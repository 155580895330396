import { GET_COUNTRIES } from "../Graphql/Queries/Country";
import client from "./Client";
import { handleError } from "./toastMessage";

export const getAllCountries = () => async (dispatch) => {
  dispatch({ type: "COUNTRIES_LOADING" });

  try {
    const { data } = await client.query({
      query: GET_COUNTRIES,
    });
    dispatch({
      type: "FETCH_COUNTRIES",
      payload: data.countries.data,
    });
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};
