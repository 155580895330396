export const ATTRIBUTES_CLIENTS = `
email
username
firstName
lastName
status
state
wealth
confirmed
premium
paid
moneeverseToken
confident
blocked
yearsOfExperience
connectedSite
connectedApp
profileType
createdAt
 mailNotificationOnPosts
 mailNotificationOnComment
 mailNotificationOnMessage
 mailNotificationOnClubDeal
 mailNotificationOnTeamMember
 emailConfirmed
 delete
 license {
          data {
            attributes {
              url
            }
          }
        }
assetsUnderControl
updatedAt
jobTitle
phoneNumber
languages{
    data{
        id
        attributes{
            languageName
        }
    }
}
country{
    data{
        id
        attributes{
            countryName
        }
    }
}
clubDeals{
    data{
        id
        attributes{
            tagline
        }
    }
}
photo{
    data{
        id
        attributes{
            url
        }
    }
}

roi
coverPicture{
    data{
        id
        attributes{
            url
        }
    }
}
company{
    data{
        id
        attributes{
            companyName
        }
    }
}
`;
