import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const Select = ({
  list = [],
  totalInputs,
  setValue,
  champs,
  val,
  placeholder = "",
  height = 44,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const handleSelect = (item) => {
    setSelected(item.label);
    setValue({ ...totalInputs, [champs]: item.value });
    setOpen(false);
  };
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };
  return (
    <div
      className={`containerSelect ${open ? "listOpen" : ""}`}
      onBlur={handleBlur}
      tabIndex="-1"
      style={{ height: `${height}px` }}
    >
      <span className={"buttonContainer h-100"} onClick={() => setOpen(!open)}>
        <span className=" f12">
          {(val ? list.find((l) => l.value === val)?.label : selected) ??
            placeholder}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`icon ${open && "listOpen"} `}
        />
      </span>
      <ul className={`optionList   ${open ? "listOpen" : ""}`}>
        {list.map((item, index) => {
          return (
            <li
              key={index}
              className="optionItem  "
              onClick={(e) => handleSelect(item)}
            >
              <span className=" f14 text-dark ">{item.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Select;
