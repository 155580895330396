import React from "react";
import {
  faArrowDownShortWide,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons";
import {
  faChevronRight,
  faChevronLeft,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { assetsUnderManagement, estimatedWealth } from "../utils/SelectData";
export const DashboardSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.997"
      height="20"
      viewBox="0 0 19.997 20"
    >
      <path
        id="dashboard_icon"
        data-name="dashboard icon"
        d="M54,179a3.086,3.086,0,0,1-3.026-3.1V172.88A3.061,3.061,0,0,1,54,169.791h2.96A3.085,3.085,0,0,1,60,172.88V175.9a3.1,3.1,0,0,1-3.034,3.1Zm-1.4-6.121V175.9a1.432,1.432,0,0,0,.408,1.015,1.37,1.37,0,0,0,.983.421h2.981a1.37,1.37,0,0,0,.983-.421,1.428,1.428,0,0,0,.407-1.015V172.88a1.44,1.44,0,0,0-.41-1.011,1.38,1.38,0,0,0-.99-.421H54a1.418,1.418,0,0,0-1.4,1.433Zm-9.567,6.105A3.133,3.133,0,0,1,40,175.892V172.88a3.124,3.124,0,0,1,.889-2.187,2.992,2.992,0,0,1,2.145-.9h1.216a.81.81,0,0,1,.779.387.848.848,0,0,1,0,.884.811.811,0,0,1-.779.387H43.035a1.425,1.425,0,0,0-1.41,1.43v2.984a1.454,1.454,0,0,0,1.41,1.43h2.979a1.382,1.382,0,0,0,1-.421,1.443,1.443,0,0,0,.414-1.015v-4.476a.842.842,0,0,1,.365-.856.8.8,0,0,1,.915.026.843.843,0,0,1,.316.876v4.462a3.066,3.066,0,0,1-3.034,3.1h0Zm12.747-10.746a.836.836,0,0,1,0-1.659h1.188a1.393,1.393,0,0,0,1-.432,1.459,1.459,0,0,0,.4-1.029V162.1a1.436,1.436,0,0,0-1.4-1.43H54a1.417,1.417,0,0,0-1.4,1.43v4.519a.833.833,0,0,1-.835.815.8.8,0,0,1-.569-.258.836.836,0,0,1-.215-.595V162.1a3.122,3.122,0,0,1,.883-2.19A3,3,0,0,1,54,159h2.96a3.133,3.133,0,0,1,3.025,3.1v3.013a3.123,3.123,0,0,1-.859,2.195,3,3,0,0,1-2.128.933ZM46,168.209H43.035A3.087,3.087,0,0,1,40,165.12V162.1a3.1,3.1,0,0,1,3.034-3.1H46a3.084,3.084,0,0,1,3.03,3.1v3.022a3.1,3.1,0,0,1-.889,2.187A2.976,2.976,0,0,1,46,168.209Zm-3.974-7.133a1.454,1.454,0,0,0-.421,1.021v3.022a1.451,1.451,0,0,0,.41,1.04,1.389,1.389,0,0,0,.988.421h3a1.387,1.387,0,0,0,1-.432,1.449,1.449,0,0,0,.4-1.028V162.1a1.432,1.432,0,0,0-1.4-1.43H43.008A1.38,1.38,0,0,0,42.026,161.076Z"
        transform="translate(-40.001 -159)"
        fill={"#9EA6B9"}
      />
    </svg>
  );
};

export const BienSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
        fill="#9EA6B9"
      />
      <path
        d="M20.79 14.7C19.67 15.45 18.1 15.73 16.65 15.54C17.03 14.72 17.23 13.81 17.24 12.85C17.24 11.85 17.02 10.9 16.6 10.07C18.08 9.86998 19.65 10.15 20.78 10.9C22.36 11.94 22.36 13.65 20.79 14.7Z"
        fill="#9EA6B9"
      />
      <path
        d="M6.44 7.77C6.51 7.76 6.58 7.76 6.65 7.77C8.2 7.72 9.43 6.45 9.43 4.89C9.43 3.29 8.14 2 6.54 2C4.95 2 3.66 3.29 3.66 4.89C3.66 6.45 4.89 7.72 6.44 7.77Z"
        fill="#9EA6B9"
      />
      <path
        d="M6.55 12.85C6.55 13.82 6.76 14.74 7.14 15.57C5.73 15.72 4.26 15.42 3.18 14.71C1.6 13.66 1.6 11.95 3.18 10.9C4.25 10.18 5.76 9.88998 7.18 10.05C6.77 10.89 6.55 11.84 6.55 12.85Z"
        fill="#9EA6B9"
      />
      <path
        d="M12.12 15.87C12.04 15.86 11.95 15.86 11.86 15.87C10.02 15.81 8.55 14.3 8.55 12.44C8.56 10.54 10.09 9 12 9C13.9 9 15.44 10.54 15.44 12.44C15.43 14.3 13.97 15.81 12.12 15.87Z"
        fill="#9EA6B9"
      />
      <path
        d="M8.87 17.94C7.36 18.95 7.36 20.61 8.87 21.61C10.59 22.76 13.41 22.76 15.13 21.61C16.64 20.6 16.64 18.94 15.13 17.94C13.42 16.79 10.6 16.79 8.87 17.94Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};

export const SignalementsSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3H6C3.79 3 2 4.78 2 6.97V17.03C2 19.22 3.79 21 6 21H18C20.21 21 22 19.22 22 17.03V6.97C22 4.78 20.21 3 18 3ZM8.5 7.17C9.77 7.17 10.81 8.21 10.81 9.48C10.81 10.75 9.77 11.79 8.5 11.79C7.23 11.79 6.19 10.75 6.19 9.48C6.19 8.21 7.23 7.17 8.5 7.17ZM12.37 16.66C12.28 16.76 12.14 16.82 12 16.82H5C4.86 16.82 4.72 16.76 4.63 16.66C4.54 16.56 4.49 16.42 4.5 16.28C4.67 14.6 6.01 13.27 7.69 13.11C8.22 13.06 8.77 13.06 9.3 13.11C10.98 13.27 12.33 14.6 12.49 16.28C12.51 16.42 12.46 16.56 12.37 16.66ZM19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75ZM19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75ZM19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};

export const ComptesSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 4.63998L6.32 2.44998C3.93 1.27998 1.97 2.46998 1.97 5.08998V19.93C1.97 21.07 2.92 22 4.08 22H11.5C12.05 22 12.5 21.55 12.5 21V7.40998C12.5 6.35998 11.71 5.10998 10.75 4.63998ZM8.97 13.75H5.5C5.09 13.75 4.75 13.41 4.75 13C4.75 12.59 5.09 12.25 5.5 12.25H8.97C9.38 12.25 9.72 12.59 9.72 13C9.72 13.41 9.39 13.75 8.97 13.75ZM8.97 9.74998H5.5C5.09 9.74998 4.75 9.40998 4.75 8.99998C4.75 8.58998 5.09 8.24998 5.5 8.24998H8.97C9.38 8.24998 9.72 8.58998 9.72 8.99998C9.72 9.40998 9.39 9.74998 8.97 9.74998Z"
        fill="#9EA6B9"
      />
      <path
        d="M22 18.04V19.5C22 20.88 20.88 22 19.5 22H14.97C14.43 22 14 21.57 14 21.03V18.87C15.07 19 16.2 18.69 17.01 18.04C17.69 18.59 18.56 18.92 19.51 18.92C20.44 18.92 21.31 18.59 22 18.04Z"
        fill="#9EA6B9"
      />
      <path
        d="M22 15.05V15.06C21.92 16.37 20.85 17.42 19.51 17.42C18.12 17.42 17.01 16.29 17.01 14.92C17.01 16.45 15.6 17.68 14 17.37V12C14 11.36 14.59 10.88 15.22 11.02L17.01 11.42L17.49 11.53L19.53 11.99C20.02 12.09 20.47 12.26 20.86 12.51C20.86 12.52 20.87 12.52 20.87 12.52C20.97 12.59 21.07 12.67 21.16 12.76C21.62 13.22 21.92 13.89 21.99 14.87C21.99 14.93 22 14.99 22 15.05Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};
export const AdsSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39 18.49V8.32998C8.39 7.92998 8.51 7.53998 8.73 7.20998L11.46 3.14998C11.89 2.49998 12.96 2.03998 13.87 2.37998C14.85 2.70998 15.5 3.80997 15.29 4.78997L14.77 8.05998C14.73 8.35998 14.81 8.62998 14.98 8.83998C15.15 9.02998 15.4 9.14997 15.67 9.14997H19.78C20.57 9.14997 21.25 9.46997 21.65 10.03C22.03 10.57 22.1 11.27 21.85 11.98L19.39 19.47C19.08 20.71 17.73 21.72 16.39 21.72H12.49C11.82 21.72 10.88 21.49 10.45 21.06L9.17 20.07C8.68 19.7 8.39 19.11 8.39 18.49Z"
        fill="#9EA6B9"
      />
      <path
        d="M5.21 6.38H4.18C2.63 6.38 2 6.98 2 8.46V18.52C2 20 2.63 20.6 4.18 20.6H5.21C6.76 20.6 7.39 20 7.39 18.52V8.46C7.39 6.98 6.76 6.38 5.21 6.38Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};
export const AdminSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 14.9 3.25 17.51 5.23 19.34C5.23 19.35 5.23 19.35 5.22 19.36C5.32 19.46 5.44 19.54 5.54 19.63C5.6 19.68 5.65 19.73 5.71 19.77C5.89 19.92 6.09 20.06 6.28 20.2C6.35 20.25 6.41 20.29 6.48 20.34C6.67 20.47 6.87 20.59 7.08 20.7C7.15 20.74 7.23 20.79 7.3 20.83C7.5 20.94 7.71 21.04 7.93 21.13C8.01 21.17 8.09 21.21 8.17 21.24C8.39 21.33 8.61 21.41 8.83 21.48C8.91 21.51 8.99 21.54 9.07 21.56C9.31 21.63 9.55 21.69 9.79 21.75C9.86 21.77 9.93 21.79 10.01 21.8C10.29 21.86 10.57 21.9 10.86 21.93C10.9 21.93 10.94 21.94 10.98 21.95C11.32 21.98 11.66 22 12 22C12.34 22 12.68 21.98 13.01 21.95C13.05 21.95 13.09 21.94 13.13 21.93C13.42 21.9 13.7 21.86 13.98 21.8C14.05 21.79 14.12 21.76 14.2 21.75C14.44 21.69 14.69 21.64 14.92 21.56C15 21.53 15.08 21.5 15.16 21.48C15.38 21.4 15.61 21.33 15.82 21.24C15.9 21.21 15.98 21.17 16.06 21.13C16.27 21.04 16.48 20.94 16.69 20.83C16.77 20.79 16.84 20.74 16.91 20.7C17.11 20.58 17.31 20.47 17.51 20.34C17.58 20.3 17.64 20.25 17.71 20.2C17.91 20.06 18.1 19.92 18.28 19.77C18.34 19.72 18.39 19.67 18.45 19.63C18.56 19.54 18.67 19.45 18.77 19.36C18.77 19.35 18.77 19.35 18.76 19.34C20.75 17.51 22 14.9 22 12ZM16.94 16.97C14.23 15.15 9.79 15.15 7.06 16.97C6.62 17.26 6.26 17.6 5.96 17.97C4.44 16.43 3.5 14.32 3.5 12C3.5 7.31 7.31 3.5 12 3.5C16.69 3.5 20.5 7.31 20.5 12C20.5 14.32 19.56 16.43 18.04 17.97C17.75 17.6 17.38 17.26 16.94 16.97Z"
        fill="#9EA6B9"
      />
      <path
        d="M12 6.93005C9.93 6.93005 8.25 8.61005 8.25 10.6801C8.25 12.7101 9.84 14.3601 11.95 14.4201C11.98 14.4201 12.02 14.4201 12.04 14.4201C12.06 14.4201 12.09 14.4201 12.11 14.4201C12.12 14.4201 12.13 14.4201 12.13 14.4201C14.15 14.3501 15.74 12.7101 15.75 10.6801C15.75 8.61005 14.07 6.93005 12 6.93005Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};
export const FeedBackSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};
export const DocumentsSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.97"
      height="20"
      viewBox="0 0 16.97 20"
    >
      <path
        id="Statement"
        d="M46.565,279A4.579,4.579,0,0,1,42,274.4q0-.049,0-.1v-2.523a.747.747,0,1,1,1.493,0v2.523a3.1,3.1,0,0,0,3.011,3.181h8.006a3.159,3.159,0,0,0,2.964-3.182v-8.925l-3.809-4.014v1.316a1.829,1.829,0,0,0,1.809,1.837.755.755,0,0,1,0,1.51h0a3.334,3.334,0,0,1-3.3-3.348v-2.162H46.565a3.075,3.075,0,0,0-3.068,3v4.159a.747.747,0,1,1-1.493,0v-4.159a4.6,4.6,0,0,1,4.562-4.51h6.3a.449.449,0,0,1,.057-.012c.02,0,.037.01.057.012h.2a.743.743,0,0,1,.539.233l5.04,5.312a.761.761,0,0,1,.211.523v9.228A4.64,4.64,0,0,1,54.514,279Zm.8-5.594a.755.755,0,0,1,0-1.51h5.371a.755.755,0,0,1,0,1.51Zm0-4.986a.755.755,0,0,1,0-1.51H50.7a.755.755,0,0,1,0,1.51Z"
        transform="translate(-42.002 -259)"
        fill={color}
      />
    </svg>
  );
};

export const AssembleeSvg = ({ color = "#9EA6B9" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V7C21.25 4.42 19.58 2.75 17 2.75H7C4.42 2.75 2.75 4.42 2.75 7V9C2.75 9.41 2.41 9.75 2 9.75C1.59 9.75 1.25 9.41 1.25 9V7C1.25 3.56 3.56 1.25 7 1.25H17C20.44 1.25 22.75 3.56 22.75 7V9C22.75 9.41 22.41 9.75 22 9.75Z"
        fill="#9EA6B9"
      />
      <path
        d="M17 22.75H7C3.56 22.75 1.25 20.44 1.25 17V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17C2.75 19.58 4.42 21.25 7 21.25H17C19.58 21.25 21.25 19.58 21.25 17V15C21.25 14.59 21.59 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15V17C22.75 20.44 20.44 22.75 17 22.75Z"
        fill="#9EA6B9"
      />
      <path
        d="M16.4 8.20996L12.64 6.17996C12.24 5.96996 11.77 5.96996 11.37 6.17996L7.6 8.20996C7.33 8.35996 7.16 8.64996 7.16 8.96996C7.16 9.29996 7.33 9.58996 7.6 9.73996L11.36 11.77C11.56 11.88 11.78 11.93 12 11.93C12.22 11.93 12.44 11.88 12.64 11.77L16.4 9.73996C16.67 9.58996 16.84 9.29996 16.84 8.96996C16.84 8.64996 16.67 8.35996 16.4 8.20996Z"
        fill="#9EA6B9"
      />
      <path
        d="M10.74 12.4701L7.24 10.7201C6.97 10.5901 6.66 10.6001 6.4 10.7601C6.15 10.9201 6 11.1901 6 11.4901V14.8001C6 15.3701 6.32 15.8901 6.83 16.1401L10.33 17.8901C10.45 17.9501 10.58 17.9801 10.72 17.9801C10.88 17.9801 11.03 17.9401 11.17 17.8501C11.43 17.6901 11.58 17.4201 11.58 17.1201V13.8101C11.57 13.2401 11.26 12.7301 10.74 12.4701Z"
        fill="#9EA6B9"
      />
      <path
        d="M17.59 10.7601C17.33 10.6001 17.02 10.5901 16.75 10.7201L13.25 12.4701C12.74 12.7301 12.42 13.2401 12.42 13.8101V17.1201C12.42 17.4201 12.57 17.6901 12.83 17.8501C12.97 17.9401 13.12 17.9801 13.28 17.9801C13.41 17.9801 13.54 17.9501 13.67 17.8901L17.17 16.1401C17.68 15.8801 18 15.3701 18 14.8001V11.4901C18 11.1901 17.85 10.9201 17.59 10.7601Z"
        fill="#9EA6B9"
      />
    </svg>
  );
};
export const DeconnexionSvg = ({ color = "#2C659D" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="18.276"
      viewBox="0 0 19.5 18.276"
    >
      <g
        id="Groupe_1207"
        data-name="Groupe 1207"
        transform="translate(368.29 -76.141)"
      >
        <path
          id="Tracé_267"
          data-name="Tracé 267"
          d="M-352.661,90.6a.778.778,0,0,1,.006,1.1,9.072,9.072,0,0,1-6.5,2.713,9.149,9.149,0,0,1-9.138-9.138,9.149,9.149,0,0,1,9.138-9.138,9.073,9.073,0,0,1,6.5,2.713.778.778,0,0,1-.006,1.1.778.778,0,0,1-1.1-.006,7.527,7.527,0,0,0-5.39-2.251,7.59,7.59,0,0,0-7.581,7.581,7.59,7.59,0,0,0,7.581,7.581,7.527,7.527,0,0,0,5.39-2.251A.779.779,0,0,1-352.661,90.6Z"
          fill={color}
        />
        <path
          id="Tracé_268"
          data-name="Tracé 268"
          d="M-246.209,163.157a.778.778,0,0,0-1.1,0,.779.779,0,0,0,0,1.1l1.4,1.4h-8.742a.779.779,0,0,0-.778.778.779.779,0,0,0,.778.779h8.742l-1.4,1.4a.779.779,0,0,0,0,1.1.776.776,0,0,0,.55.228.776.776,0,0,0,.55-.228l2.729-2.729a.779.779,0,0,0,.228-.55.778.778,0,0,0-.228-.55Z"
          transform="translate(-105.538 -81.157)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const HeaderLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="163"
      height="44.084"
      viewBox="0 0 163 44.084"
    >
      <g
        id="Groupe_6"
        data-name="Groupe 6"
        transform="translate(-43.614 -117.498)"
      >
        <g
          id="Groupe_1"
          data-name="Groupe 1"
          transform="translate(97.384 157.306)"
        >
          <path
            id="Tracé_1"
            data-name="Tracé 1"
            d="M185.955,225.806h.856v.489h-.832v1.028h-.392V224.8h1.37v.489h-.979v.514Z"
            transform="translate(-185.588 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_2"
            data-name="Tracé 2"
            d="M192.048,225.93c0-.734.44-1.321.979-1.321s.978.587.978,1.321-.44,1.321-.978,1.321A1.256,1.256,0,0,1,192.048,225.93Zm.391,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C192.684,225.074,192.439,225.49,192.439,225.93Z"
            transform="translate(-189.601 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_3"
            data-name="Tracé 3"
            d="M200.9,227.033h-.391v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-194.859 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_4"
            data-name="Tracé 4"
            d="M209.046,227.122c-.538,0-.979-.587-.979-1.321s.44-1.321.979-1.321a.984.984,0,0,1,.832.685l-.342.2a.65.65,0,0,0-.489-.391c-.342,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.342.2A.869.869,0,0,1,209.046,227.122Z"
            transform="translate(-199.554 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_5"
            data-name="Tracé 5"
            d="M216.141,227.194v-2.52h.392v2.52Z"
            transform="translate(-204.57 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_6"
            data-name="Tracé 6"
            d="M221.248,224.442h.881v.489h-.881v.538h.979v.489h-1.37v-2.52h1.37v.489h-.979Zm.2-1.248-.392-.392.343-.2.342.538h-.294Z"
            transform="translate(-207.5 -222.607)"
            fill="#fff"
          />
          <path
            id="Tracé_7"
            data-name="Tracé 7"
            d="M227.9,227.194v-2.52h.783c.392,0,.636.342.636.881a.917.917,0,0,1-.342.783c.147.294.342.587.44.832h-.44c-.147-.245-.294-.489-.44-.783h-.245v.783H227.9Zm.783-1.223c.1,0,.245-.147.245-.391,0-.147-.049-.392-.245-.392h-.391v.783Z"
            transform="translate(-211.873 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_8"
            data-name="Tracé 8"
            d="M235.459,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-216.328 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_9"
            data-name="Tracé 9"
            d="M246.763,227.033h-.392v-2.618l1.223,1.541v-1.493h.391v2.618l-1.223-1.541Z"
            transform="translate(-223.351 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_10"
            data-name="Tracé 10"
            d="M254.445,227.194v-2.52h.392v2.52Z"
            transform="translate(-228.367 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_11"
            data-name="Tracé 11"
            d="M259.752,224.544a.984.984,0,0,1,.832.685l-.342.2a.65.65,0,0,0-.489-.392c-.342,0-.587.392-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.342.2a1,1,0,0,1-.783.685l-.049.1h.1a.3.3,0,0,1,.147.294c0,.294-.2.294-.294.294a.374.374,0,0,1-.245-.1l.049-.147a.18.18,0,0,0,.147.049.105.105,0,0,0,.1-.1c0-.049-.049-.049-.1-.049a.171.171,0,0,0-.1.049l-.049-.1.1-.294a1.219,1.219,0,0,1-.832-1.272C258.773,225.131,259.213,224.544,259.752,224.544Z"
            transform="translate(-231.056 -223.81)"
            fill="#fff"
          />
          <path
            id="Tracé_12"
            data-name="Tracé 12"
            d="M266.589,225.93c0-.734.44-1.321.979-1.321s.979.587.979,1.321-.44,1.321-.979,1.321A1.215,1.215,0,0,1,266.589,225.93Zm.342,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C267.225,225.074,266.931,225.49,266.931,225.93Z"
            transform="translate(-235.911 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_13"
            data-name="Tracé 13"
            d="M275.051,227.194v-2.52h.391v2.52Z"
            transform="translate(-241.169 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_14"
            data-name="Tracé 14"
            d="M280.211,226.713a.327.327,0,0,0,.342-.294c0-.2-.2-.245-.391-.294a.755.755,0,0,1-.734-.783.664.664,0,0,1,.734-.734.935.935,0,0,1,.685.342l-.2.392a.779.779,0,0,0-.489-.245c-.1,0-.293.049-.293.245s.2.2.342.245c.294.1.734.147.734.832a.685.685,0,0,1-.734.783,1.123,1.123,0,0,1-.832-.392l.2-.391A1.247,1.247,0,0,0,280.211,226.713Z"
            transform="translate(-243.858 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_15"
            data-name="Tracé 15"
            d="M287.134,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-248.432 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_16"
            data-name="Tracé 16"
            d="M298.5,225.806h.881v.489H298.5v.538h.979v.489h-1.37V224.8h1.37v.489H298.5Z"
            transform="translate(-255.495 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_17"
            data-name="Tracé 17"
            d="M305.564,225.163v2.079h-.391v-2.079h-.538v-.489h1.419v.489Z"
            transform="translate(-259.548 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_18"
            data-name="Tracé 18"
            d="M315.939,224.674h.734a.788.788,0,0,1,.636.294,1.41,1.41,0,0,1,.293.929,1.324,1.324,0,0,1-.293.93.788.788,0,0,1-.636.294h-.734Zm.734,2.079c.245,0,.538-.147.538-.783s-.294-.783-.538-.783h-.343v1.59l.343-.025Z"
            transform="translate(-266.571 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_19"
            data-name="Tracé 19"
            d="M323.888,225.806h.88v.489h-.88v.538h.979v.489H323.5V224.8h1.37v.489h-.979Z"
            transform="translate(-271.266 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_20"
            data-name="Tracé 20"
            d="M336.333,225.579c0,.489-.244.881-.734.881h-.343v.734h-.391v-2.52h.734C336.088,224.674,336.333,225.065,336.333,225.579Zm-.391,0c0-.2-.1-.44-.294-.44h-.342v.832h.391C335.844,226.019,335.941,225.824,335.941,225.579Z"
            transform="translate(-278.329 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_21"
            data-name="Tracé 21"
            d="M341.906,227.194v-2.52h.783c.391,0,.636.342.636.881a.917.917,0,0,1-.342.783c.147.294.342.587.44.832h-.44c-.147-.245-.293-.489-.44-.783H342.3v.783h-.391Zm.783-1.223c.1,0,.245-.147.245-.391,0-.147-.049-.392-.245-.392H342.3v.783Z"
            transform="translate(-282.704 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_22"
            data-name="Tracé 22"
            d="M348.753,225.93c0-.734.44-1.321.978-1.321s.979.587.979,1.321-.44,1.321-.979,1.321A1.279,1.279,0,0,1,348.753,225.93Zm.416,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C349.414,225.074,349.169,225.49,349.169,225.93Z"
            transform="translate(-286.958 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_23"
            data-name="Tracé 23"
            d="M357.256,227.372l-.881-2.569h.44l.489,1.37.489-1.37h.44Z"
            transform="translate(-291.693 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_24"
            data-name="Tracé 24"
            d="M364.582,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-296.548 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_25"
            data-name="Tracé 25"
            d="M371.558,227.033h-.391v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-300.883 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_26"
            data-name="Tracé 26"
            d="M379.767,227.122c-.538,0-.978-.587-.978-1.321s.44-1.321.978-1.321a.984.984,0,0,1,.832.685l-.343.2a.65.65,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.343.2C380.4,226.877,380.11,227.122,379.767,227.122Z"
            transform="translate(-305.618 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_27"
            data-name="Tracé 27"
            d="M387.061,225.806h.881v.489h-.881v.538h.978v.489h-1.37V224.8h1.37v.489h-.978Z"
            transform="translate(-310.514 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_28"
            data-name="Tracé 28"
            d="M397.908,228.586V228.1h.783v.489Z"
            transform="translate(-317.496 -226.018)"
            fill="#fff"
          />
          <path
            id="Tracé_29"
            data-name="Tracé 29"
            d="M408.172,224.415l.979,2.618h-.44l-.147-.392h-.832l-.147.392h-.44Zm.2,1.786-.245-.636-.245.636Z"
            transform="translate(-323.235 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_30"
            data-name="Tracé 30"
            d="M416.8,225.9v.147a1.034,1.034,0,0,1-.93,1.076c-.538,0-.978-.587-.978-1.321s.44-1.321.978-1.321a1.014,1.014,0,0,1,.832.587l-.343.294a.649.649,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.57.57,0,0,0,.538-.489h-.685v-.489H416.8Z"
            transform="translate(-328.051 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_31"
            data-name="Tracé 31"
            d="M423.556,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-333.187 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_32"
            data-name="Tracé 32"
            d="M430.6,227.033h-.392v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-337.561 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_33"
            data-name="Tracé 33"
            d="M438.742,227.122c-.538,0-.979-.587-.979-1.321s.44-1.321.979-1.321a.984.984,0,0,1,.832.685l-.343.2a.65.65,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.343.2A.889.889,0,0,1,438.742,227.122Z"
            transform="translate(-342.257 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_34"
            data-name="Tracé 34"
            d="M446.1,225.806h.881v.489H446.1v.538h.979v.489h-1.37V224.8h1.37v.489H446.1Z"
            transform="translate(-347.193 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_35"
            data-name="Tracé 35"
            d="M457.779,226.713a.327.327,0,0,0,.343-.294c0-.2-.2-.245-.392-.294a.755.755,0,0,1-.734-.783.664.664,0,0,1,.734-.734.936.936,0,0,1,.685.342l-.2.392a.777.777,0,0,0-.489-.245c-.1,0-.293.049-.293.245s.2.2.342.245c.294.1.734.147.734.832a.685.685,0,0,1-.734.783,1.125,1.125,0,0,1-.832-.392l.2-.391A.97.97,0,0,0,457.779,226.713Z"
            transform="translate(-354.175 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_36"
            data-name="Tracé 36"
            d="M464.311,227.194v-2.52h.391v2.52Z"
            transform="translate(-358.75 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_37"
            data-name="Tracé 37"
            d="M470.95,227.033h-.392v-1.174l-.538.881-.538-.881v1.174h-.391v-2.618l.929,1.492.93-1.492v2.618Z"
            transform="translate(-361.72 -223.73)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_3"
          data-name="Groupe 3"
          transform="translate(97.384 131.442)"
        >
          <path
            id="Tracé_38"
            data-name="Tracé 38"
            d="M195.638,159.7v16.087h-4.285V159.661h-5.765v-3.7h15.893v3.7h-5.843Z"
            transform="translate(-185.588 -155.338)"
            fill="#fff"
          />
          <g id="Groupe_2" data-name="Groupe 2" transform="translate(12.971)">
            <path
              id="Tracé_39"
              data-name="Tracé 39"
              d="M241.611,174.765,230.7,154.315l-10.868,20.45h5.025l5.843-11.88,5.882,11.88h5.025Z"
              transform="translate(-219.837 -154.315)"
              fill="#fff"
            />
          </g>
          <path
            id="Tracé_40"
            data-name="Tracé 40"
            d="M295.629,165.529a5.056,5.056,0,0,1,3.311,4.635c0,4.44-3.389,5.765-6.972,5.765H283.4V156.063h8.257c3.857,0,6.466,1.4,6.466,5.492A4.433,4.433,0,0,1,295.629,165.529Zm-3.9-1.636a2.02,2.02,0,0,0,2.064-2.181,2.064,2.064,0,0,0-2.064-1.987h-4.012v4.168Zm2.883,6c0-1.208-.935-2.259-2.766-2.259h-4.168v4.479h4.246C293.72,172.15,294.616,171.06,294.616,169.891Z"
            transform="translate(-246.354 -155.401)"
            fill="#fff"
          />
          <path
            id="Tracé_41"
            data-name="Tracé 41"
            d="M331.119,165.215A10.7,10.7,0,1,1,341.792,175.5,10.487,10.487,0,0,1,331.119,165.215Zm4.246,0a6.427,6.427,0,1,0,12.854,0,6.428,6.428,0,0,0-12.854,0Z"
            transform="translate(-276.002 -154.698)"
            fill="#fff"
          />
          <path
            id="Tracé_42"
            data-name="Tracé 42"
            d="M401.291,175.641h-4.246V155.035l13.672,11.8v-11.8H415v20.606l-13.75-11.608v11.608Z"
            transform="translate(-316.96 -154.762)"
            fill="#fff"
          />
          <path
            id="Tracé_43"
            data-name="Tracé 43"
            d="M457.52,175.724V155.858H461.8v19.866Z"
            transform="translate(-354.531 -155.274)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_4"
          data-name="Groupe 4"
          transform="translate(97.384 122.093)"
        >
          <path
            id="Tracé_44"
            data-name="Tracé 44"
            d="M188.042,134.589a2.4,2.4,0,0,1-2.454-2.376,2.459,2.459,0,0,1,4.6-1.13l-.935.351a1.527,1.527,0,0,0-1.246-.74,1.5,1.5,0,0,0-1.48,1.48,1.479,1.479,0,0,0,1.48,1.48,1.528,1.528,0,0,0,1.246-.74l.935.351A2.423,2.423,0,0,1,188.042,134.589Z"
            transform="translate(-185.588 -129.759)"
            fill="#fff"
          />
          <path
            id="Tracé_45"
            data-name="Tracé 45"
            d="M216.673,129.734l2.532,4.713h-1.091l-.351-.7h-2.142l-.351.7H214.18Zm.623,3.155-.584-1.207-.584,1.207Z"
            transform="translate(-203.351 -129.695)"
            fill="#fff"
          />
          <path
            id="Tracé_46"
            data-name="Tracé 46"
            d="M248.148,132.16a1.178,1.178,0,0,1,.779,1.052c0,1.013-.779,1.324-1.6,1.324h-1.986v-4.6h1.869c.857,0,1.48.312,1.48,1.285A.932.932,0,0,1,248.148,132.16Zm-.935-.35a.457.457,0,0,0,.467-.506.5.5,0,0,0-.467-.468h-.935v.974Zm.7,1.363c0-.273-.234-.506-.623-.506h-.974v1.052h.974A.555.555,0,0,0,247.915,133.173Z"
            transform="translate(-222.713 -129.823)"
            fill="#fff"
          />
          <path
            id="Tracé_47"
            data-name="Tracé 47"
            d="M273.73,134.536v-4.6h1.013v4.6Z"
            transform="translate(-240.348 -129.823)"
            fill="#fff"
          />
          <path
            id="Tracé_48"
            data-name="Tracé 48"
            d="M296.713,134.344h-.974v-4.713l3.155,2.8v-2.648h1.013V134.5l-3.155-2.766v2.61Z"
            transform="translate(-254.022 -129.631)"
            fill="#fff"
          />
          <path
            id="Tracé_49"
            data-name="Tracé 49"
            d="M326.784,132.015h2.181v.857h-2.181v.974h2.454v.857h-3.467v-4.557h3.467V131h-2.454Z"
            transform="translate(-272.68 -129.951)"
            fill="#fff"
          />
          <path
            id="Tracé_50"
            data-name="Tracé 50"
            d="M354.952,130.9v3.7h-1.013v-3.7h-1.324v-.857h3.662v.857Z"
            transform="translate(-289.357 -129.887)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_5"
          data-name="Groupe 5"
          transform="translate(43.614 117.498)"
        >
          <path
            id="Tracé_51"
            data-name="Tracé 51"
            d="M85.3,198.461l-3.5-6.628a26.951,26.951,0,0,1-7.175,7.206,9.441,9.441,0,0,0,2.736-.243s.365,3.709-1.855,5.533a4.354,4.354,0,0,1-2.615.973,9.74,9.74,0,0,1-2.828-.274c-.334-2.067-.578-2.706-1.064-3.314-7.327,2.28-14.928.578-21.282-1.642-.73,1.307-1.368,2.523-1.916,3.618-1.368,2.523-2.189,4.074-2.189,4.074H90.252Z"
            transform="translate(-43.614 -163.68)"
            fill="#fff"
          />
          <path
            id="Tracé_52"
            data-name="Tracé 52"
            d="M146.33,190.629h0C146.421,190.751,146.391,190.72,146.33,190.629Z"
            transform="translate(-107.414 -162.932)"
            fill="#fff"
          />
          <path
            id="Tracé_53"
            data-name="Tracé 53"
            d="M76.728,117.5c0,.486-10.367,19.944-17.3,32.835a37.719,37.719,0,0,0,12.86-1.885,2.5,2.5,0,0,0-1.7-1.916c-.73-.243-1.216-.273-1.49-.365a4.464,4.464,0,0,1,2.22-.79,2.69,2.69,0,0,1,2.158-.639c2.372.183,2.979,1.4,3.588,1.307a27.552,27.552,0,0,0,2.219-1.794c1.095-1.156.973-1,1.095-1.156a22.117,22.117,0,0,0,4.743-9.85Z"
            transform="translate(-53.439 -117.498)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
export const SmallBadgeIcon = ({ color = "bronze", user }) => {
  const index =
    user.status === 0
      ? { text: "bronze", color: "#f1b37c" }
      : user.status === 1
      ? { text: "silver", color: "#9ea6b9" }
      : user.status === 2
      ? { text: "gold", color: "#f9c73b" }
      : { text: "not indexed", color: "grey" };
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="button-tooltip">{index.text}</Tooltip>}
    >
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.19366 13.6346L4.19367 13.6346L4.19025 13.632C4.05525 13.5324 3.88942 13.3324 3.75587 13.0645C3.62261 12.7972 3.56055 12.5406 3.56055 12.367V5.95454C3.56055 5.68936 3.66573 5.36653 3.87223 5.06867C4.07845 4.7712 4.34446 4.55821 4.59462 4.4636C4.59486 4.46351 4.5951 4.46342 4.59534 4.46333L8.94553 2.83399C9.06325 2.79096 9.2653 2.7561 9.50409 2.7561C9.74288 2.7561 9.94493 2.79096 10.0627 2.83399C10.0632 2.83417 10.0637 2.83436 10.0642 2.83455L14.4106 4.46249C14.4112 4.46271 14.4118 4.46292 14.4123 4.46314C14.6583 4.55736 14.9223 4.7697 15.1266 5.06659C15.3314 5.36415 15.4342 5.68524 15.4318 5.9479L15.4318 5.9479V5.95454V12.367C15.4318 12.54 15.3701 12.7944 15.2369 13.0608C15.1041 13.3264 14.9373 13.5297 14.7972 13.6357C14.7968 13.636 14.7965 13.6362 14.7962 13.6365L10.4445 16.8883L10.4425 16.8899C10.211 17.0641 9.87075 17.1726 9.49617 17.1726C9.12159 17.1726 8.78132 17.0641 8.54987 16.8899L8.54783 16.8883L4.19366 13.6346ZM7.47818 12.1262L7.47817 12.1262L7.48147 12.1295C7.74828 12.3963 8.09279 12.5233 8.43138 12.5233C8.76997 12.5233 9.11448 12.3963 9.38129 12.1295L12.7818 8.7289C13.3174 8.20537 13.3155 7.33969 12.7934 6.81754C12.2709 6.29507 11.416 6.29507 10.8936 6.81754L8.4393 9.2718L8.11463 8.94713C7.59215 8.42465 6.73728 8.42465 6.2148 8.94713C5.69297 9.46896 5.69232 10.3224 6.21286 10.845C6.21351 10.8457 6.21415 10.8463 6.2148 10.847L7.47818 12.1262Z"
          fill={index.color}
          stroke={index.color}
          strokeWidth="1.5"
        />
      </svg>
    </OverlayTrigger>
  );
};
export const SortIcon = ({ sort }) => {
  return (
    <div>
      {sort?.asc ? (
        <FontAwesomeIcon icon={faArrowDownShortWide} />
      ) : (
        <FontAwesomeIcon icon={faArrowUpShortWide} />
      )}
    </div>
  );
};

export const SortIconDefault = () => {
  return (
    <div>
      <FontAwesomeIcon icon={faArrowUpShortWide} />
    </div>
  );
};

export const ChevronLeft = () => {
  return <FontAwesomeIcon icon={faChevronLeft} />;
};

export const ChevronRight = () => {
  return <FontAwesomeIcon icon={faChevronRight} />;
};

export const SuccessIcon = () => {
  return (
    <button className="all-unset btn-success">
      <FontAwesomeIcon icon={faCheck} />
    </button>
  );
};

export const CancelIcon = () => {
  return (
    <button className="all-unset btn-cancel">
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );
};

export const PremiumIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user.premium ? "Premium" : "Not Premium"}
        </Tooltip>
      }
    >
      <svg
        width={25}
        height={25}
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_922_3060" fill="white">
          <path d="M13.4584 17.9167H5.54169C5.2171 17.9167 4.94794 17.6476 4.94794 17.323C4.94794 16.9984 5.2171 16.7292 5.54169 16.7292H13.4584C13.7829 16.7292 14.0521 16.9984 14.0521 17.323C14.0521 17.6476 13.7829 17.9167 13.4584 17.9167Z" />
        </mask>
        <path
          d="M13.4584 17.9167H5.54169C5.2171 17.9167 4.94794 17.6476 4.94794 17.323C4.94794 16.9984 5.2171 16.7292 5.54169 16.7292H13.4584C13.7829 16.7292 14.0521 16.9984 14.0521 17.323C14.0521 17.6476 13.7829 17.9167 13.4584 17.9167Z"
          fill={!user.premium ? "grey" : "#045AFD"}
        />
        <path
          d="M13.4584 16.4167H5.54169V19.4167H13.4584V16.4167ZM5.54169 16.4167C6.04553 16.4167 6.44794 16.8192 6.44794 17.323H3.44794C3.44794 18.476 4.38868 19.4167 5.54169 19.4167V16.4167ZM6.44794 17.323C6.44794 17.8268 6.04553 18.2292 5.54169 18.2292V15.2292C4.38868 15.2292 3.44794 16.17 3.44794 17.323H6.44794ZM5.54169 18.2292H13.4584V15.2292H5.54169V18.2292ZM13.4584 18.2292C12.9545 18.2292 12.5521 17.8268 12.5521 17.323H15.5521C15.5521 16.17 14.6114 15.2292 13.4584 15.2292V18.2292ZM12.5521 17.323C12.5521 16.8192 12.9545 16.4167 13.4584 16.4167V19.4167C14.6114 19.4167 15.5521 18.476 15.5521 17.323H12.5521Z"
          fill={!user.premium ? "grey" : "#045AFD"}
          mask="url(#path-1-inside-1_922_3060)"
        />
        <path
          d="M16.1104 4.86999L12.9437 7.13416C12.5242 7.43499 11.9225 7.25291 11.7404 6.76999L10.2442 2.77999C9.99082 2.09124 9.01707 2.09124 8.76374 2.77999L7.25957 6.76207C7.07749 7.25291 6.48374 7.43499 6.06416 7.12624L2.89749 4.86207C2.26416 4.41874 1.42499 5.04416 1.68624 5.78041L4.97957 15.0033C5.09041 15.32 5.39124 15.5258 5.72374 15.5258H13.2683C13.6008 15.5258 13.9017 15.3121 14.0125 15.0033L17.3058 5.78041C17.575 5.04416 16.7358 4.41874 16.1104 4.86999ZM11.4792 12.1771H7.52082C7.19624 12.1771 6.92707 11.9079 6.92707 11.5833C6.92707 11.2587 7.19624 10.9896 7.52082 10.9896H11.4792C11.8037 10.9896 12.0729 11.2587 12.0729 11.5833C12.0729 11.9079 11.8037 12.1771 11.4792 12.1771Z"
          fill={!user.premium ? "grey" : "#045AFD"}
        />
      </svg>
    </OverlayTrigger>
  );
};
export const Moneeverse = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user.moneeverseToken
            ? "Connected to Moneeverse"
            : "Not Moneeverse member"}
        </Tooltip>
      }
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 38 45"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity={"0.5"}
          d="M15.8701 15.7047L10.2364 9.41876V2.69843C10.2388 1.20702 11.5465 0 13.16 0H15.8701V15.7047ZM24.84 0H22.1299V15.7114L27.7636 9.42772V2.69843C27.7636 1.20926 26.4559 0 24.84 0ZM13.16 45H15.8701V33.6531L10.2364 27.3673V42.3016C10.2364 43.7907 11.5441 45 13.16 45ZM22.1299 45H24.84C26.4535 45 27.7636 43.793 27.7636 42.3016V27.3673L22.1299 33.6487V44.9978V45ZM33.8875 7.45484L18.9685 24.0933L4.06155 7.45484H2.92121C1.30775 7.45484 0 8.6641 0 10.1533V34.9385C0 36.4277 1.30775 37.637 2.92364 37.637H5.63376V17.3663L16.6829 29.6984C17.2385 30.3187 18.0635 30.677 18.9345 30.677H19.0024C19.8735 30.677 20.6984 30.3187 21.254 29.6984L32.3662 17.3036V37.637H35.0764C36.6898 37.637 38 36.43 38 34.9385V10.1533C38 8.6641 36.6923 7.45484 35.0764 7.45484H33.8851H33.8875Z"
          fill={user.moneeverseToken ? "#5560E9" : "grey"}
        />
      </svg>
    </OverlayTrigger>
  );
};

export const PhoneIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.connectedApp
            ? "Connected with mobile app"
            : "Not Connected with mobile app"}
        </Tooltip>
      }
    >
      <svg height="25" width="25" viewBox="0 0 27.442 27.442">
        <path
          fill={user?.connectedApp ? "#045AFD" : "grey"}
          d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546
   c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481
   s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469
   c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z
    M19.995,21.1H7.448V3.373h12.547V21.1z"
        />
      </svg>
    </OverlayTrigger>
  );
};
export const DesktopIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.connectedSite
            ? "Connected with platform"
            : "Not Connected with platform"}
        </Tooltip>
      }
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="white"
          d="M3 19H21M6 17H18C19.1046 17 20 16.1046 20 15V8C20 6.89543 19.1046 6 18 6H6C4.89543 6 4 6.89543 4 8V15C4 16.1046 4.89543 17 6 17Z"
          stroke={user?.connectedSite ? "#045AFD" : "grey"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </OverlayTrigger>
  );
};
export const ActiveIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.state !== 2 ? "Active" : "Inactive"}
        </Tooltip>
      }
    >
      <svg width="30" height="30" viewBox="0 0 1000 1000">
        <g>
          <path
            fill={user?.state !== 2 ? "#045AFD" : "red"}
            d="M983.2,774.9L784.8,973.2c-0.2,0.2-0.2,0.4-0.4,0.6c-9.1,9.2-24,9.1-33.3-0.2l-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0L632.9,855.3c-9.2-9.3-9.3-24.2-0.2-33.3c9.2-9.1,24.1-9.1,33.3,0.2l101.4,101.4l182.3-182.3c9.1-9.2,24.1-9.1,33.3,0.2C992.3,750.8,992.3,765.7,983.2,774.9z M747.4,674c-16.5,0-40.3-22.2-40.3-22.2c-38.9-33.6-84.6-59.8-135.2-75.6c-5-0.6-9.7-2-14-4c-2-0.5-4-1.2-6.1-1.7v-1.6c-12.3-8.3-20.4-22.2-20.4-38c0-15.8,8.1-29.7,20.4-38v-3c71-34.2,120-106.1,120-189.4c0-116.5-95.5-210.9-213.3-210.9c-117.8,0-213.3,94.4-213.3,210.9c0,79.4,44.7,148,110.2,184c18.5,6,32,23.1,32,43.5c0,17.7-10.3,33-25.2,40.6v2.6C226.5,606.5,120.8,714.1,89.6,850c-5,18.1-9.5,36.3-9.5,36.3h11.1h5.3h456.8c0,0,7.7,0,11.9,0c19.6,0,35.5,15.8,35.5,35.2c0,19.4-15.9,35.1-35.5,35.1c-4.2,0-11.9,0-11.9,0H91.2c-6.3,0-28.4-1.5-34.6-1.5c-25.7,0-46.5-20.6-46.5-45.9c0-8.4-0.1-22.9-0.1-22.9C27.4,721.7,135.8,584,284.4,522.6c-67-51.4-110.3-131.6-110.3-222c0-155.3,127.3-281.2,284.4-281.2C615.6,19.4,743,145.3,743,300.6c0,90.6-43.5,171-110.9,222.4c50,20.8,95.1,50.5,134,86.7c9.9,6.2,16.9,16.7,16.9,29.2C783,658.3,767.1,674,747.4,674z"
          />
        </g>
      </svg>
    </OverlayTrigger>
  );
};

export const BlockIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.blocked ? "Blocked" : "Unblocked"}
        </Tooltip>
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="25"
        height="25"
        viewBox="0 0 256 256"
      >
        <defs></defs>
        <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
          <path
            d="M 45 90 c -12.02 0 -23.32 -4.681 -31.82 -13.181 C 4.681 68.32 0 57.02 0 45 c 0 -12.02 4.681 -23.32 13.18 -31.82 l 0 0 l 0 0 C 21.68 4.681 32.98 0 45 0 c 12.02 0 23.32 4.681 31.819 13.18 C 85.319 21.68 90 32.98 90 45 c 0 12.02 -4.681 23.32 -13.181 31.819 C 68.32 85.319 57.02 90 45 90 z M 45 8 c -9.883 0 -19.174 3.849 -26.163 10.837 l 0 0 C 11.849 25.826 8 35.117 8 45 c 0 9.883 3.849 19.174 10.837 26.163 C 25.826 78.151 35.117 82 45 82 c 9.883 0 19.174 -3.849 26.163 -10.837 C 78.151 64.174 82 54.883 82 45 c 0 -9.883 -3.849 -19.174 -10.837 -26.163 C 64.174 11.849 54.883 8 45 8 z"
            transform=" matrix(1 0 0 1 0 0) "
            strokeLinecap="round"
            fill={!user?.blocked ? "grey" : "red"}
          />
          <rect
            x="4"
            y="41"
            rx="0"
            ry="0"
            width="82"
            height="8"
            fill={!user?.blocked ? "grey" : "red"}
            transform=" matrix(0.707 -0.7072 0.7072 0.707 -18.6396 45.0055) "
          />
        </g>
      </svg>
    </OverlayTrigger>
  );
};

export const LinkedinIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.linkedinId
            ? "Connected with Linkedin"
            : "Not connected with linkedin"}
        </Tooltip>
      }
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={user.linkedinId ? "#007AB5" : "grey"}
      >
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
      </svg>
    </OverlayTrigger>
  );
};

export const EmailConfirmedIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {user?.emailConfirmed ? "Email confirmed" : "Email not confirmed"}
        </Tooltip>
      }
    >
      <svg
        fill={user?.emailConfirmed ? "#045AFD" : "grey"}
        height="25"
        width="25"
        viewBox="0 0 75.294 75.294"
      >
        <path
          d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9
		c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089
		H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065
		c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016
		c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102
		c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069
		c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"
        />
      </svg>
    </OverlayTrigger>
  );
};

export const AumIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {
            assetsUnderManagement.filter(
              (el) => user?.assetsUnderControl === el.value
            )[0]?.label
          }
        </Tooltip>
      }
    >
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.97007 0.898454C4.45007 0.918454 -0.0199331 5.40845 6.68532e-05 10.9285C0.0200669 16.4485 4.51007 20.9185 10.0301 20.8985C15.5501 20.8785 20.0201 16.3885 20.0001 10.8685C19.9801 5.34845 15.4901 0.888454 9.97007 0.898454ZM12.2601 10.9985C13.0401 11.2685 14.0901 11.8485 14.0901 13.6385C14.0901 15.1785 12.8801 16.4185 11.4001 16.4185H10.7501V16.9985C10.7501 17.4085 10.4101 17.7485 10.0001 17.7485C9.59007 17.7485 9.25007 17.4085 9.25007 16.9985V16.4185H8.89007C7.25007 16.4185 5.92007 15.0385 5.92007 13.3385C5.92007 12.9285 6.26007 12.5885 6.67007 12.5885C7.08007 12.5885 7.42007 12.9285 7.42007 13.3385C7.42007 14.2085 8.08007 14.9185 8.89007 14.9185H9.25007V11.5385L7.74007 10.9985C6.96007 10.7285 5.91007 10.1485 5.91007 8.35845C5.91007 6.81845 7.12007 5.57845 8.60007 5.57845H9.25007V4.99845C9.25007 4.58845 9.59007 4.24845 10.0001 4.24845C10.4101 4.24845 10.7501 4.58845 10.7501 4.99845V5.57845H11.1101C12.7501 5.57845 14.0801 6.95845 14.0801 8.65845C14.0801 9.06845 13.7401 9.40845 13.3301 9.40845C12.9201 9.40845 12.5801 9.06845 12.5801 8.65845C12.5801 7.78845 11.9201 7.07845 11.1101 7.07845H10.7501V10.4585L12.2601 10.9985Z"
          fill="grey"
        />
      </svg>
    </OverlayTrigger>
  );
};

export const WealthIcon = ({ user }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {estimatedWealth.filter((el) => user?.wealth === el.value)[0]?.label}
        </Tooltip>
      }
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.85 3.95078V7.75078H13.35V3.95078C13.35 3.68078 13.11 3.55078 12.95 3.55078C12.9 3.55078 12.85 3.56078 12.8 3.58078L4.87 6.57078C4.34 6.77078 4 7.27078 4 7.84078V8.51078C3.09 9.19078 2.5 10.2808 2.5 11.5108V7.84078C2.5 6.65078 3.23 5.59078 4.34 5.17078L12.28 2.17078C12.5 2.09078 12.73 2.05078 12.95 2.05078C13.95 2.05078 14.85 2.86078 14.85 3.95078Z"
          fill="grey"
        />
        <path
          d="M21.5007 14.5V15.5C21.5007 15.77 21.2907 15.99 21.0107 16H19.5507C19.0207 16 18.5407 15.61 18.5007 15.09C18.4707 14.78 18.5907 14.49 18.7907 14.29C18.9707 14.1 19.2207 14 19.4907 14H21.0007C21.2907 14.01 21.5007 14.23 21.5007 14.5Z"
          fill="grey"
        />
        <path
          d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V18.24C2.5 20.31 4.19 22 6.26 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.29 14.77 17.54 14.04 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
          fill="grey"
        />
      </svg>
    </OverlayTrigger>
  );
};
