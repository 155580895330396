import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../Components/Common/Button/Button";
import Text from "../../Components/Common/Text";
import { BreadCrumb } from "../../utils/BreadCrumbs";
import CompaniesList from "../../Components/companies/CompaniesList";
import SearchInput from "../../Components/Common/SearchInput";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useResponsive from "../../utils/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../REDUX/actions/company";
import PagginationButton from "../../Components/paginationButton/paginationButton";
const CompaniesView = () => {
  const dispatch = useDispatch();
  const { totalPages, pageNumber, totalCompanies } = useSelector(
    (state) => state.companiesReducer
  );

  const location = useLocation();
  const [sortingOptions, setsortingOptions] = useState(`sort: "id:desc"`);
  const [searchCompanies, setSearchCompanies] = useState("");
  const navigate = useNavigate();
  const isMobileSize = useResponsive();
  const handlechange = (e) => {
    const { value } = e.target;
    setSearchCompanies(value);
  };

  useEffect(() => {
    dispatch(getAllCompanies(1, 20, searchCompanies, sortingOptions));
    // eslint-disable-next-line
  }, [sortingOptions]);

  return (
    <div className="container-fluid h6">
      <div className="d-flex justify-content-between mb-4 alignH ">
        <Text className={"sBold f30 text-deepBlue "}>
          {BreadCrumb(location.pathname)}
        </Text>
        <div>
          <Button
            color={"white"}
            className={"bg-blue "}
            textButton="New Company"
            callback={() => navigate("/companies/new")}
          />
        </div>
      </div>
      <div className="BorderList p-2 ">
        <div
          className={` mb-4 ${
            isMobileSize
              ? " justify-content-center"
              : "justify-content-sm-start"
          }   alignC `}
        >
          <div className="col-sm-4 ">
            <SearchInput
              value={searchCompanies}
              placeholder={"Name"}
              onChange={handlechange}
            />
          </div>
          <div className="col-sm-1">
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton={`${isMobileSize ? "" : "Search"}`}
              icon={faSearch}
              callback={() =>
                dispatch(
                  getAllCompanies(null, null, searchCompanies, sortingOptions)
                )
              }
            />
          </div>
        </div>
        <div className="w100 d-flex align-items-center justify-content-center mb-2">
          <PagginationButton
            action={getAllCompanies}
            pages={totalPages}
            pageNumber={pageNumber}
            sortingOptions={sortingOptions}
            search={searchCompanies}
            totalItems={totalCompanies}
          />
        </div>
        <CompaniesList
          sortingOptions={sortingOptions}
          setsortingOptions={setsortingOptions}
        />
      </div>
    </div>
  );
};

export default CompaniesView;
