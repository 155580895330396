import { Modal } from "react-bootstrap";
import Text from "../Text";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeBlockModal } from "../../../REDUX/actions/ConfirmationModal";
import moment from "moment";
import { deleteUser } from "../../../REDUX/actions/clients";

const BlockModal = () => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState();
  const { openBlockModal } = useSelector(
    (state) => state.confirmationModalReducer
  );
  const { client } = useSelector((state) => state.clientsReducer);

  useEffect(() => {
    setReason();
  }, [openBlockModal]);
  const handleConfirm = () => {
    dispatch(
      deleteUser(
        client?.id,
        client?.attributes?.delete
          ? null
          : moment(new Date()).format("YYYY-MM-DD"),
        client?.attributes?.clubDeals,
        reason,
        client?.attributes?.profileType
      )
    );
  };

  const hide = () => {
    dispatch(closeBlockModal());
  };
  const handleChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <Modal
      show={openBlockModal}
      onHide={hide}
      size={"md"}
      centered
      className=" modal-fullscreen-sm-down"
    >
      <Modal.Body className=" my-4">
        <Text className=" f16 text-center bold">
          Why would you like to delete this user ?{" "}
        </Text>
        <div className="w-100 mt-3">
          {/* <Text className={"xbold f12 mb-2"}>Reason</Text> */}
          <input
            placeholder={"Enter reason"}
            // value={reason}
            className={`input  w-100 h40  f12 p-2 `}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <div className="row  justify-content-end">
          <div className="col ">
            <Button
              textButton={"Cancel"}
              className="border-red"
              color="red"
              bgColor={"white"}
              callback={hide}
            />{" "}
          </div>
          <div className="col">
            <Button
              textButton={"submit"}
              color={"white"}
              bgColor={"blue"}
              callback={handleConfirm}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BlockModal;
