import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./MultiSelect.css";

const MultiSelect = ({ list = [], champs, setValue, totalInputs, value }) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    setSelectedList(value);
  }, [value]);

  const handleSelect = (index) => {
    if (selectedList.includes(list[index].id)) {
      const filtred = selectedList.filter((el) => el !== list[index].id);
      setSelectedList([...filtred]);
    } else {
      setSelectedList([...selectedList, list[index].id]);
    }
  };
  function handleBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsListOpen(false);

      setValue({
        ...totalInputs,
        [champs]: selectedList,
      });
    }
  }

  return (
    <div
      className={`container  ${isListOpen && "listOpen"}`}
      tabIndex="-1"
      onBlur={handleBlur}
    >
      <div
        className={"buttonContainer"}
        onClick={() => setIsListOpen(!isListOpen)}
      >
        <div className="row ">
          {selectedList?.map((item, index) => {
            return (
              <div key={index} className="items col f12">
                {list.find(({ id }) => id === item)?.attributes.languageName}
              </div>
            );
          })}
        </div>

        <FontAwesomeIcon
          icon={faChevronDown}
          className={`icon ${isListOpen && "listOpen"}`}
        />
      </div>
      <ul className={`optionsContainer   ${isListOpen && "listOpen"}`}>
        {list.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => handleSelect(index, item)}
              className={` optionsItem ${
                selectedList?.includes(list[index].id) && "selected"
              }`}
            >
              <span className=" f14 text-dark ">
                {item.attributes.languageName}
              </span>
              {selectedList?.includes(list[index].id) ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MultiSelect;
