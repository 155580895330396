import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "../Components/Common/Loading";

import Text from "../Components/Common/Text";
import AssetsUnderManagement from "../Components/dashboard/AssetsUnderManagement";
import DoghnutsChart from "../Components/dashboard/DoghnutsChart";
import HorizontalBars from "../Components/dashboard/HorizontalBars";
import PieChart from "../Components/dashboard/PieChart";
import { getStats } from "../REDUX/actions/stats";

import { BreadCrumb } from "../utils/BreadCrumbs";

const DashboardView = () => {
  const { stats, statsLoading } = useSelector((state) => state.statsReducer);
  const [sortedCountriesProfessionals, setsortedCountriesProfessionals] =
    useState({});
  const [sortedCountriesInvestors, setsortedCountriesInvestors] = useState({});

  useEffect(() => {
    const handleCountries = () => {
      if (stats.coutriesStatInvestors) {
        const sortablePros = Object.entries(stats.coutriesStatProfessionals)
          .sort(([, a], [, b]) => a - b)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        setsortedCountriesProfessionals(sortablePros);
        const sortableInvs = Object.entries(stats.coutriesStatInvestors)
          .sort(([, a], [, b]) => a - b)
          .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        setsortedCountriesInvestors(sortableInvs);
      }
    };
    handleCountries();
    // eslint-disable-next-line
  }, [stats]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!stats.allUsers) {
      dispatch(getStats());
    }
    // eslint-disable-next-line
  }, []);

  const location = useLocation();
  const { deals } = useSelector((state) => state.dealsReducer);
  return statsLoading || !stats.allUsers ? (
    <Loading />
  ) : (
    <div className="container-fluid h6">
      <div className="d-flex justify-content-between mb-4 alignH">
        <Text className={"bold f25 text-deepBlue "}>
          {BreadCrumb(location.pathname)}
        </Text>
      </div>
      <div className="w95 mx-auto">
        <Text className={"bold f25 text-deepBlue mb-3"}>All users</Text>
        <div className="row">
          <div className="col-6">
            <AssetsUnderManagement stats={stats} deals={deals} />
          </div>

          <div className="col-3 gap-3">
            <PieChart
              title={"Active / Inactive Users"}
              total={stats.allUsers}
              labels={["Active", "Inactive"]}
              values={[stats.activeUsers, stats.inactiveUsers]}
            />
          </div>
          <div className="col-3">
            <PieChart
              title={"Professionals / Investors"}
              total={stats.allUsers}
              labels={["Professionals", "Investors"]}
              values={[stats.professionals, stats.investors]}
            />
          </div>
        </div>

        {/* <DoghnutsChart chartData={deals} /> */}

        <Text className={"bold f25 text-deepBlue mt-5"}>Professionals</Text>
        <div className="row my-4 justify-content-center">
          <div className="col-3 d-flex flex-column justify-content-between gap-4 ">
            <PieChart
              title={"Trust Index"}
              total={stats.professionals}
              labels={["Bronze", "Silver", "Gold", "Not Indexed"]}
              values={[
                stats.bronzeUsers,
                stats.silverUsers,
                stats.goldUsers,
                stats.notIndexedUsers,
              ]}
            />

            <DoghnutsChart
              title={"Assets Under Management"}
              total={stats.professionals}
              labels={[
                "Under 0",
                "-100M",
                "100M-500M",
                "500M-1B",
                "1B-5B",
                "5B-10B",
                "+10B",
              ]}
              values={[
                stats.aum0,
                stats.aum1,
                stats.aum2,
                stats.aum3,
                stats.aum4,
                stats.aum5,
                stats.aum6,
              ]}
            />
          </div>
          <div className="col-9">
            <HorizontalBars
              title={"Professionals Per Countries"}
              total={stats.professionals}
              labels={Object?.keys(sortedCountriesProfessionals)
                .reverse()
                .slice(0, 25)}
              values={Object?.values(sortedCountriesProfessionals)
                .reverse()
                .slice(0, 25)}
            />
          </div>
        </div>
        <Text className={"bold f25 text-deepBlue "}>Investors</Text>
        <div className="row my-4 justify-content-center">
          <div className="col-3">
            <PieChart
              title={"Investors Estimated Wealth"}
              total={stats.professionals}
              labels={[
                "100K-500K",
                "500K-2M",
                "2M-5M",
                "5M-10M",
                "10M-25M",
                "+25M",
              ]}
              values={[
                stats.wealth0,
                stats.wealth1,
                stats.wealth2,
                stats.wealth3,
                stats.wealth4,
                stats.wealth5,
              ]}
            />
          </div>

          <div className="col-9">
            <HorizontalBars
              title={"Investors Per Countries"}
              total={stats.investors}
              labels={Object?.keys(sortedCountriesInvestors)
                .reverse()
                .slice(0, 25)}
              values={Object?.values(sortedCountriesInvestors)
                .reverse()
                .slice(0, 25)}
            />
          </div>
        </div>

        <Text className={"bold f25 text-deepBlue "}>Advertise Clicks</Text>
        <div className="row my-4 justify-content-center">
          <div className="col-4">
            <PieChart
              title={"Clicks Per Ad"}
              total={
                stats.sideBarClick +
                stats.homeClick +
                stats.profileClick +
                stats.clubDealClick
              }
              labels={Object.keys(stats.objectClicksbyAd)}
              values={Object.values(stats.objectClicksbyAd)}
            />
          </div>
          <div className="col-4">
            <PieChart
              title={"Clicks Per Position"}
              total={
                stats.sideBarClick +
                stats.homeClick +
                stats.profileClick +
                stats.clubDealClick
              }
              labels={["Side Banner", "Home Feed", "Profile Card", "Club Deal"]}
              values={[
                stats.sideBarClick,
                stats.homeClick,
                stats.profileClick,
                stats.clubDealClick,
              ]}
            />
          </div>
        </div>
        {/* <div
          className={`${
            isMobileSize ? "d-block" : "d-flex justify-content-between"
          } w100 `}
        >
          <VerticalBarChart
            title={"Advertises"}
            data={[10, 250, 30, 25, 50, 100, 70]}
            label={"number of clicks per month"}
            color={"#A6AAAB	"}
            total={450}
            cardWidth="w50"
          />
          <VerticalBarChart
            title={"Chat room"}
            data={[50, 20, 30, 50, 30, 60, 90]}
            label={"number of room created per month"}
            color={"#F05C5C"}
            total={300}
            cardWidth="w45"
          />
        </div> */}
      </div>
    </div>
  );
};

export default DashboardView;
