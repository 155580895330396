import API from "../../Api/Api";

import { GET_ME, LOGIN } from "../Graphql/Mutations/user";
import client from "./Client";
import { handleError } from "./toastMessage";
export const login = (email, password) => async (dispatch) => {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN,
      variables: {
        email: email,
        password: password,
      },
    });
    localStorage.setItem("token", "Bearer " + data.login.jwt);

    const me = await client.query({
      query: GET_ME,
      variables: {
        id: data.login.user.id,
      },
    });
    if (
      me.data.usersPermissionsUser.data.attributes.role.data.attributes.name !==
      "admin"
    ) {
      dispatch(handleError("login error", 1));
    } else {
      dispatch({
        type: "SUCCESS_AUTH",
        payload: me.data.usersPermissionsUser.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(logout());

    dispatch(handleError("login error", 1));
  }
};

export const check = (navigate) => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    dispatch({ type: "USERS_LOADING" });
    API.defaults.headers.common["Authorization"] = token;

    try {
      const { data } = await API.get("/api/users/me");

      const me = await client.query({
        query: GET_ME,
        variables: {
          id: data.id,
        },
      });
      dispatch({
        type: "SUCCESS_AUTH",
        payload: me.data.usersPermissionsUser.data,
      });
    } catch (error) {
      dispatch(logout());
      navigate("/");
      dispatch(handleError("network Error", 1));
    }
  }
};

export const logout = (navigate) => async (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  navigate("/");
  window.location.reload(false);
};
