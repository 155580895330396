export const ATTRIBUTES_ADVERTISES = `
tagline
companyName
position
duration
link
createdAt
email
startingDay
contactPerson
state
numberDisplays
user {
    data {
        id
        attributes{
            email
            
        }
    }
}
email
phoneNumber 
postalCode
payed
sideAd{
    data{
        id
        attributes{
            url
        }
    }
}

clubdealAd{
    data{
        id
        attributes{
            url
        }
    }
}

homeFeedAd{
    data{
        id
        attributes{
            url
        }
    }
}

profileNetworkAd{
    data{
        id
        attributes{
            url
        }
    }
}
clicks(pagination: {  pageSize: 10000 }) {
    data {
        id
        attributes {
            type
        }
        
    }
    
    
}
`;
