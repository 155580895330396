import { estimatedWealth } from "../../utils/SelectData";

export const searchInputs = [
  {
    placeholder: "id",
    name: "id",
  },
  {
    placeholder: "First name",
    name: "firstName",
  },
  {
    placeholder: "Last name",
    name: "lastName",
  },
  {
    placeholder: "Email",
    name: "email",
  },
  {
    placeholder: "Wealth",
    name: "wealth",
    type: "select",
    options: estimatedWealth,
  },
  {
    placeholder: "Country",
    name: "country",
    type: "select_country",
  },
  {
    label: "Moneeverse",
    name: "moneeverseToken",
    id: "moneeverseToken",
    type: "checkbox",
  },
  {
    label: "State",
    name: "state",
    id: "state",
    type: "checkbox",
  },
  {
    label: "Verified email",
    name: "emailConfirmed",
    id: "emailConfirmed",
    type: "checkbox",
  },
  {
    label: "Connected with app",
    name: "connectedApp",
    id: "connectedApp",
    type: "checkbox",
  },
  {
    label: "Connected with platform",
    name: "connectedSite",
    id: "connectedSite",
    type: "checkbox",
  },
];
