import API from "../../Api/Api";

export const getStats = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STATS" });
    const users = await API.get("/api/users-permissions/user/getStats");
    dispatch({ type: "GET_STATS", payload: users.data });
  } catch (error) {
    console.log(error);
  }
};
