const initialState = {
  advertises: [],
  advertisesLoading: false,
  advertise: null,
  loading: false,
  error: null,
};

const advertisesReducer = (
  state = initialState,
  { type, payload, page, totalPages }
) => {
  switch (type) {
    case "ADVERTISES_LOADING":
      return { ...state, advertisesLoading: true };
    case "FETCH_ADVERTISES":
      return {
        ...state,
        advertises: payload,
        pageNumber: page,
        totalPages: totalPages,
        advertisesLoading: false,
      };
    case "FETCH_ADS_ITEM":
      return { ...state, advertise: payload };
    case "UPDATE_ADVERTISES":
      return {
        ...state,
        advertises: state.advertises.map((ad) =>
          ad.id === payload.id ? payload : ad
        ),
        advertisesLoading: false,
      };

    case "UPDATE_AD_ITEM_LOADING":
      return { ...state, loading: true };
    case "UPDATE_AD_ITEM_SUCC":
      return { ...state, loading: false };
    case "UPDATE_AD_ITEM_FAIL":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
export default advertisesReducer;
