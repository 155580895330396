import React from "react";
import { Bar } from "react-chartjs-2";
import Text from "../Common/Text";
import useResponsive from "../../utils/useResponsive";
import "./Charts.css";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const HorizontalBars = ({
  title,
  total,
  text,
  labels,
  values,
  colors = [
    "#515DC6",
    "#E0BAA9",
    "#836CC3",
    "#00B1C0",
    "#ffb55a",
    "#79CEB8",
    "#6B735E",
    "#fdcce5",
    "#765C9E",
  ],
  hoverOffset = 10,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverOffset: hoverOffset,
      },
    ],
  };
  const isMobileSize = useResponsive();
  return (
    <div
      className={`shadow p-4 bg-white rounded-20 horizontalBars  ${
        isMobileSize ? "w-100" : ""
      }`}
    >
      <div className="d-flex justify-content-between">
        <Text className={"bold f20"}>{title} </Text>
        <Text className={"bold f20"}>{total} </Text>
      </div>
      <div className="h100 alignC">
        <Bar
          data={data}
          className="rounded-20"
          options={{
            indexAxis: "y",

            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default HorizontalBars;
