export const openConfirmationModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_CONFIRMATION_MODAL",
    payload: data,
  });
};

export const closeConfirmationModal = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_CONFIRMATION_MODAL",
  });
};

export const openBlockModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_BLOCK_MODAL",
    payload: data,
  });
};

export const closeBlockModal = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_BLOCK_MODAL",
  });
};
