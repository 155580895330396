import React, { useEffect, useState } from "react";
const useResponsive = () => {
  const [isMobileSize, setIsMobileSize] = useState(false);
  const handleResponsive = () => {
    if (window.innerWidth < 960) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 960) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
    window.addEventListener("resize", handleResponsive);
    return () => window.removeEventListener("resize", handleResponsive);
  }, []);
  return isMobileSize;
};
export default useResponsive;
