import { faEye } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useDispatch } from "react-redux";
import { UpdateClientState } from "../../REDUX/actions/clients";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../REDUX/actions/ConfirmationModal";
import { openModal } from "../../REDUX/actions/modal";
import { estimatedWealth } from "../../utils/SelectData";
import { formatDate, formatDateTime } from "../../utils/utils";
import CercledButton from "../Common/Button/CercledButton";
import Switch from "../Common/Button/Switch";
import UserDetailsModal from "../Common/Modal/UserDetailsModal";
import Text from "../Common/Text";
import {
  Moneeverse,
  DesktopIcon,
  PhoneIcon,
  ActiveIcon,
  EmailConfirmedIcon,
  WealthIcon,
  BlockIcon,
  LinkedinIcon,
} from "../Svgs";

const InvestorItem = ({ item, index, handleSort }) => {
  const dispatch = useDispatch();
  const updateState = (item) => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(UpdateClientState(item?.state === 1 ? 2 : 1, item.id)),
        message: `Would you like to ${
          item?.state === 1 ? "disactivate" : "activate"
        } this investor ?`,
        message2: `${item.firstName} ${item.lastName}`,
        confirmButtonText: `${
          item?.state === 1 ? "disactivate" : "activate"
        }      `,
        cancelButtonText: "Cancel",
        cancelCallback: () => dispatch(closeConfirmationModal()),
      })
    );
  };
  return (
    <div
      className={`container-fluid w100 ${
        index % 2 === 0 ? "bg-darkGrey" : ""
      } position-relative`}
    >
      <div className="row">
        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>

        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{item.firstName}</Text>
        </div>
        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{item.lastName}</Text>
        </div>

        <div className="alignC bordered eltList col-2">
          <Text className={"f14 regular "}>{item.email}</Text>
        </div>

        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{formatDate(item.createdAt)}</Text>
        </div>

        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{item?.country?.countryName}</Text>
        </div>
        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>
            {formatDateTime(item?.lastConnected)}
          </Text>
        </div>
        <div className="alignC justify-content-between justify-content-xxl-center gap-xxl-3 bordered eltList col">
          <div onClick={() => handleSort("wealth")}>
            <WealthIcon user={item} />
          </div>
          <div onClick={() => handleSort("moneeverseToken")}>
            <Moneeverse user={item} />
          </div>
          <div onClick={() => handleSort("connectedSite")}>
            <DesktopIcon user={item} />
          </div>
          <div onClick={() => handleSort("connectedApp")}>
            <PhoneIcon user={item} />
          </div>
          <div onClick={() => handleSort("state")}>
            <ActiveIcon user={item} />
          </div>
          <div onClick={() => handleSort("emailConfirmed")}>
            <EmailConfirmedIcon user={item} />
          </div>
          <div onClick={() => handleSort("blocked")}>
            <BlockIcon user={item} />
          </div>
          <div onClick={() => handleSort("linkedinId")}>
            <LinkedinIcon user={item} />
          </div>
        </div>
        <div className="alignC bordered gap-3 eltList col-1">
          <CercledButton
            iconColor={"black"}
            icon={faEye}
            bgColor={"white"}
            callback={() =>
              dispatch(openModal(<UserDetailsModal userId={item.id} />))
            }
          />
          <Switch value={item.state !== 2} callBack={() => updateState(item)} />
        </div>
      </div>
    </div>
  );
};
export default InvestorItem;
