import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const GET_ME = gql`
  query ($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          lastName
          firstName
          email
          role {
            data {
              attributes {
                name
              }
            }
          }
          photo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserRole = (id) => gql`
  query {
    usersPermissionsUser(id: ${id}) {
      data {
        attributes {
          role {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
