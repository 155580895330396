import React from "react";
import { useLocation } from "react-router-dom";
import AdminList from "../../Components/adminManagement/AdminList";
import Text from "../../Components/Common/Text";
import { BreadCrumb } from "../../utils/BreadCrumbs";

const AdminManagementView = () => {
  const location = useLocation();
  return (
    <div className="container-fluid position-relative">
      <div className="d-flex justify-content-between mb-4 alignH ">
        <Text className={"sBold f30 text-deepBlue"}>
          {BreadCrumb(location.pathname)}
        </Text>
        {/* <div>
          <Button
            color={"white"}
            className={"bg-blue "}
            textButton="New Admin"
            callback={() => dispatch(openModal(<NewAdminModal />))}
          />
        </div> */}
      </div>
      <AdminList />
    </div>
  );
};

export default AdminManagementView;
