import React, { useState } from "react";
// import "./LoginView.css";
import { useDispatch } from "react-redux";
import Button from "../../Components/Common/Button/Button";
import { login } from "../../REDUX/actions/users";

import "./LoginView.css";

const LoginView = () => {
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  return (
    <div className="alignC loginContainer flex-Column">
      <div className="w20 loginInner p-3">
        <div className="d-flex alignC my-5">
          <img src="/pof-logo.png" alt="" width={300} />
        </div>
        <div className=" bold f18 alignC">
          Bienvenue sur votre espace POF Admin
        </div>
        <div className="my-3">
          <div className="f14 bolder  text-start">E-Mail</div>
          <input
            className="loginInput px-3 f14 bold"
            type={"email"}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="my-3">
          <div className="f14 bolder text-start">Mot de passe</div>
          <input
            className="loginInput px-3 f14 bold"
            type={"password"}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <div className="mt-5 alignC">
          <Button
            textButton={"Se Connecter"}
            className={"loginButton"}
            disabled={!email || !password}
            callback={() => dispatch(login(email, password))}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginView;
