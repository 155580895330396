import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AdsList from "../Components/ads/AdsList";
import Text from "../Components/Common/Text";
import PagginationButton from "../Components/paginationButton/paginationButton";
import { getAllAds } from "../REDUX/actions/Ads";
import { BreadCrumb } from "../utils/BreadCrumbs";
import useResponsive from "../utils/useResponsive";
const AdsView = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { totalPages, pageNumber, advertises } = useSelector(
    (state) => state.advertisesReducer
  );
  const [sortingOptions, setsortingOptions] = useState(`sort: "id:desc"`);
  const isMobileSize = useResponsive();

  useEffect(() => {
    dispatch(getAllAds(1, 20, "", sortingOptions));
    // eslint-disable-next-line
  }, [sortingOptions]);
  return (
    <div className="container-fluid h6">
      <div className="d-flex justify-content-between mb-4 alignH">
        <Text className={"sBold f30 text-Blue "}>
          {BreadCrumb(location.pathname)}
        </Text>
      </div>
      <div className="BorderList p-2 ">
        <div
          className={`${
            isMobileSize
              ? " justify-content-center"
              : "justify-content-sm-start"
          }alignC mb-4`}
        ></div>

        <div className="w100 d-flex align-items-center justify-content-center mb-2">
          <PagginationButton
            action={getAllAds}
            pages={totalPages}
            pageNumber={pageNumber}
            sortingOptions={sortingOptions}
            totalItems={advertises.length}
          />
        </div>
        <AdsList
          sortingOptions={sortingOptions}
          setsortingOptions={setsortingOptions}
        />
      </div>
    </div>
  );
};

export default AdsView;
