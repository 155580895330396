import React, { useState } from "react";
import { SortIcon, SortIconDefault } from "../Svgs.jsx";
import Text from "../Common/Text";
import { useDispatch, useSelector } from "react-redux";
import { sortFunction } from "../../utils/utils.js";
import EmptyList from "../Common/EmptyList.jsx";
import Loading from "../Common/Loading.jsx";
import AdminItem from "./AdminItem.jsx";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { getAdmins } from "../../REDUX/actions/clients.js";

const AdminList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdmins());
  }, []);
  const { admins, clientsLoading } = useSelector(
    (state) => state.clientsReducer
  );
  const { sortField } = useSelector((state) => state.generalReducer);
  const [sortClients, setsortClients] = useState({ champs: "id", asc: false });
  const sort = (champs) => {
    dispatch({ type: "SET_SORT_FIELD", payload: champs });
    setsortClients({ champs: champs, asc: !sortClients.asc });
  };

  return (
    <div className="table-responsive">
      <Table className="  table ">
        <thead>
          <tr>
            <th scope="col" onClick={() => sort("id")}>
              <div className="alignC">
                <Text className={" f16 medium me-1 "}>ID</Text>{" "}
                {sortField === "id" ? (
                  <SortIcon sort={sortClients} />
                ) : (
                  <SortIconDefault />
                )}
              </div>
            </th>
            <th scope="col" onClick={() => sort("username")}>
              <div className="alignC">
                <Text className={" f16 medium me-1"}>username</Text>{" "}
                {sortField === "username" ? (
                  <SortIcon sort={sortClients} />
                ) : (
                  <SortIconDefault />
                )}
              </div>
            </th>

            <th scope="col">
              <div className="alignC">
                <Text className={" f16 medium me-1"}>Email</Text>
              </div>
            </th>
            {/* <th scope="col">
              <div className="alignC">
                <Text className={" f16 medium me-1"}>state</Text>
              </div>
            </th>
            <th scope="col">
              <div className="alignC">
                <Text className={" f16 medium me-1"}>Actions</Text>
              </div>
            </th> */}
          </tr>
        </thead>
        <tbody className="border border-bottom itemTable">
          {clientsLoading ? (
            <tr>
              <td colSpan={5}>
                <Loading />
              </td>
            </tr>
          ) : admins.length === 0 ? (
            <tr>
              <td colSpan={5}>
                <EmptyList text={"No Admin found"} />
              </td>
            </tr>
          ) : (
            sortFunction(admins, sortClients.champs, sortClients.asc).map(
              (item, index) => {
                return <AdminItem key={index} item={item} />;
              }
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminList;
