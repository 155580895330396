import { gql } from "@apollo/client";
import { ATTRIBUTES_DEAL } from "../Attributes/Deal";

export const GET_DEALS = (
  page = 1,
  pageSize = 20,
  search = undefined,
  sort = ""
) => gql`
   {    
    clubDeals   {
        data {
            id 
            attributes {
              ${ATTRIBUTES_DEAL}
            }
        }
        meta {
          pagination {
              total
              pageCount
              page
          }
        }
    }
  }
`;

export const GET_DEAL_BY_ID = (id) => gql`
  query {
    clubDeals(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_DEAL}
        }
      }
    }
  }
`;
