import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Button from "../../Components/Common/Button/Button";
import Text from "../../Components/Common/Text";
import { BreadCrumb } from "../../utils/BreadCrumbs";
import SearchInput from "../../Components/Common/SearchInput";
import { faArrowRotateBack, faSearch } from "@fortawesome/free-solid-svg-icons";
import useResponsive from "../../utils/useResponsive";
import { getUsers } from "../../REDUX/actions/clients";
import { useDispatch, useSelector } from "react-redux";
import PagginationButton from "../../Components/paginationButton/paginationButton";
import InvestorsList from "../../Components/Investors/InvestorsList";
import { searchInputs } from "./data";
import Checkbox from "../../Components/Common/Checkbox";
import Select from "../../Components/Common/Selects/Select";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvestorsView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { investors, totalInvestorsPages, pageNumberInvestor, totalInvs } =
    useSelector((state) => state.clientsReducer);

  const [sortingOptions, setsortingOptions] = useState(`id:desc`);
  const isMobileSize = useResponsive();
  const [filters, setFilters] = useState({});
  const handleChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };

  const handleReset = () => {
    dispatch(getUsers(1, 1, {}, sortingOptions));
    setFilters({});
  };

  useEffect(() => {
    if (!investors?.length) {
      dispatch(getUsers(1, 1, {}));
    }
    // eslint-disable-next-line
  }, [sortingOptions]);
  return (
    <div className="">
      <div className="d-flex justify-content-between mb-3 alignH ">
        <Text className={"sBold f30 text-deepBlue "}>
          {BreadCrumb(location.pathname)}
        </Text>
        {/* <div>
          <Button
            color={"white"}
            className={"bg-blue "}
            textButton="New Investor"
            callback={() => navigate("/investors/new")}
          />
        </div> */}
      </div>
      <div className="row p-2 align-items-center">
        {searchInputs.map(
          ({ placeholder, name, label, id, type, options }, index) => {
            if (type === "checkbox") {
              return (
                <div className="col-12 col-lg-3 mb-3" key={index}>
                  <Checkbox
                    label={label}
                    id={id}
                    name={name}
                    value={filters[name]}
                    onChange={handleChange}
                  />
                </div>
              );
            }
            if (type === "select") {
              return (
                <div className="col-12 col-lg-3 mb-3" key={index}>
                  <Select
                    list={options}
                    totalInputs={filters}
                    setValue={setFilters}
                    val={filters[name]}
                    champs={name}
                    placeholder={placeholder}
                    height={49}
                  />
                </div>
              );
            }
            if (type === "select_country") {
              return (
                <div className="col-12 col-lg-3 mb-3" key={index}>
                  <SelectCountry
                    champs={name}
                    totalInputs={filters}
                    value={filters[name]}
                    setValue={setFilters}
                    placeholder={placeholder}
                    height={49}
                  />
                </div>
              );
            }
            return (
              <div className="col-12 col-lg-3 mb-3" key={index}>
                <SearchInput
                  value={filters[name] ?? ""}
                  placeholder={placeholder}
                  onChange={handleChange}
                  name={name}
                  defaultBorder={false}
                />
              </div>
            );
          }
        )}
        <div className="col-12 col-lg-12">
          <div className="d-flex align-content-center">
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton={`${isMobileSize ? "" : "Search"}`}
              icon={faSearch}
              callback={() => dispatch(getUsers(1, 1, filters))}
            />
            <button
              className="reset-button h49 alignC px-3 bg-transparent outline-none ms-3"
              onClick={handleReset}
            >
              <FontAwesomeIcon
                icon={faArrowRotateBack}
                size="1x"
                color="#2e3660"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="w100 alignC my-2">
        <PagginationButton
          type={1}
          action={getUsers}
          pages={totalInvestorsPages}
          sortingOptions={sortingOptions}
          pageNumber={pageNumberInvestor}
          totalItems={totalInvs}
          filters={filters}
        />
      </div>
      <InvestorsList
        sortingOptions={sortingOptions}
        setsortingOptions={setsortingOptions}
        filters={filters}
      />
    </div>
  );
};

export default InvestorsView;
