import React from "react";
import { onglets } from "./Onglets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Drawer.css";
import { DeconnexionSvg } from "../../Svgs";
import useResponsive from "../../../utils/useResponsive";
import { useDispatch } from "react-redux";
import { logout } from "../../../REDUX/actions/users";

const Drawer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout(navigate));
  };
  const isMobileSize = useResponsive();
  return (
    <div
      className={`${
        isMobileSize ? "drawerContainerResp" : "drawerContainer"
      } d-flex flex-md-column flex-row transition02 bg-white`}
    >
      <div
        className={`  d-flex flex-md-column ${
          isMobileSize ? "logoNavigationsResp" : "logoNavigations"
        } flex-row  justify-content-between`}
      >
        {onglets.map((el, index) => (
          <Link
            to={el.to}
            className={`${
              isMobileSize ? "drawLinkResp   " : "drawLink "
            } transition02 f18  d-md-flex   ${
              location.pathname === el.to
                ? `${isMobileSize ? "" : "inpage"} text-${
                    isMobileSize ? "black" : "white"
                  }`
                : ""
            }`}
            key={index}
          >
            <div className="d-md-flex d-md-inline justify-content-between align-items-center  gap-md-4 ps-1">
              {el.icon(
                location.pathname === el.to
                  ? `${isMobileSize ? "#2D6AA5" : "white"}`
                  : ""
              )}{" "}
              {isMobileSize ? null : (
                <span className="linkName">{el.name}</span>
              )}
            </div>
          </Link>
        ))}
      </div>
      {isMobileSize ? null : (
        <div
          className="logout mb-2 d-flex align-items-end"
          onClick={handleLogout}
        >
          <div className="drawLink transition02 f16 py-4 d-flex ps-3 justify-content-start m-2 br10">
            <div className="d-flex justify-content-start align-items-center gap-4 ps-1">
              <DeconnexionSvg /> <span className="linkName">Déconnexion</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Drawer;
