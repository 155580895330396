import React from "react";

const Text = ({ className, children, onclickCallback }) => {
  return (
    <div onClick={onclickCallback} className={`${className} transition02`}>
      {children}
    </div>
  );
};

export default Text;
