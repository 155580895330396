const initialState = {
  stats: {},
  statsLoading: false,
};

const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOADING_STATS":
      return { ...state, statsLoading: true };
    case "GET_STATS":
      return { ...state, stats: payload, statsLoading: false };
    default:
      return state;
  }
};
export default statsReducer;
