import { gql } from "@apollo/client";
import { ATTRIBUTES_FEEDBACK } from "../Attributes/Feedback";

export const GET_FEEDBACK = (
  page = 1,
  pageSize = 20,
  search = undefined,
  sort = ""
) => gql`
   query{    
   feedbacks  (pagination:{page:${page},pageSize:${pageSize}} ${sort}) {
        data {
            id 
            attributes {
        ${ATTRIBUTES_FEEDBACK}
            }
        }
        meta {
          pagination {
              total
              pageCount
              page
                  }
            }
    }
  }
`;
