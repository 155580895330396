import React, { useEffect, useState } from "react";
import Button from "../Components/Common/Button/Button";
import DealsList from "../Components/clubDeals/DealsList";
import Text from "../Components/Common/Text";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "../utils/BreadCrumbs";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchInput from "../Components/Common/SearchInput";
import useResponsive from "../utils/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeals } from "../REDUX/actions/deals";
import PagginationButton from "../Components/paginationButton/paginationButton";
const DealsView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { totalPages, pageNumber, totalDeals } = useSelector(
    (state) => state.dealsReducer
  );
  const [sortingOptions, setsortingOptions] = useState(`sort: "id:desc"`);
  const [search, setSearch] = useState("");
  const isMobileSize = useResponsive();

  const handlechange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    dispatch(getAllDeals(1, 20, search, sortingOptions));
    // eslint-disable-next-line
  }, [sortingOptions]);

  return (
    <div className="container-fluid h6">
      <div className="d-flex justify-content-between mb-4 alignH">
        <Text className={"sBold f30 text-deepBlue "}>
          {BreadCrumb(location.pathname)}
        </Text>
      </div>
      <div className="BorderList p-2 ">
        <div
          className={`   ${
            isMobileSize
              ? " justify-content-center"
              : "justify-content-sm-start"
          }  alignC mb-4`}
        ></div>
        <div
          className={`   ${
            isMobileSize
              ? " justify-content-center"
              : "justify-content-sm-start"
          }  alignC mb-4`}
        >
          <div className="col-sm-4 ">
            <SearchInput
              value={search}
              placeholder={"tagline"}
              onChange={handlechange}
            />
          </div>
          <div className="col-sm-1">
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton={`${isMobileSize ? "" : "Search"}`}
              icon={faSearch}
              callback={() =>
                dispatch(getAllDeals(null, null, search, sortingOptions))
              }
            />
          </div>
        </div>

        <div className="w100 d-flex align-items-center justify-content-center mb-2">
          <PagginationButton
            action={getAllDeals}
            pages={totalPages}
            pageNumber={pageNumber}
            sortingOptions={sortingOptions}
            search={search}
            totalItems={totalDeals}
          />
        </div>
        <DealsList
          sortingOptions={sortingOptions}
          setsortingOptions={setsortingOptions}
        />
      </div>
    </div>
  );
};

export default DealsView;
