const InitialState = {
  selectedFile: [],
  uploadLoading: false,
};

const filesReducer = (state = InitialState, { payload, type }) => {
  switch (type) {
    case "LOADING_UPLOAD":
      return { ...state, uploadLoading: true };
    case "SELECTED_FILE":
      if (state.selectedFile.length === 0) {
        return {
          ...state,
          selectedFile: [...state.selectedFile, payload],
        };
      } else {
        const found = state.selectedFile.some(
          (el) => el.champs === payload.champs
        );
        if (!found) {
          return {
            ...state,
            selectedFile: [...state.selectedFile, payload],
          };
        } else {
          return {
            ...state,
            selectedFile: state.selectedFile.map((file) =>
              file.champs === payload.champs ? payload : file
            ),
          };
        }
      }

    case "UPLOAD_DONE":
      return { ...state, selectedFile: [], uploadLoading: false };
    default:
      return state;
  }
};

export default filesReducer;
