import { gql } from "@apollo/client";
import { ATTRIBUTES_ADVERTISES } from "../Attributes/Ads";

export const GET_ADS = (
  page = 1,
  pageSize = 20,
  search = undefined,
  sort = ""
) => gql`
   {    
   advertises  (
      filters: {${search ? `tagline: {containsi: "${search}"}` : ""}}
      pagination:{page:${page},pageSize:${pageSize}} ${sort}) {
        data {
            id 
            attributes {
        ${ATTRIBUTES_ADVERTISES}
            }
        }

        meta {
          pagination {
              total
              pageCount
              page
                  }
            }
    }
  }
`;

export const GET_ADS_BY_ID = (id) => gql`
  query {
    advertises(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_ADVERTISES}
        }
      }
    }
  }
`;
