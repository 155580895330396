import moment from "moment";

export const formatDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const formatDateTime = (date) => {
  return date ? moment(date).format("DD-MM-YYYY hh:mm") : "";
};
export const formatSort = (array) => `sort :  ${JSON.stringify(array)}`;

export const validMail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(mail)) {
    return true;
  }
  return false;
};
export const sortBoolean = (arr, champs, asc) => {
  arr.sort((a, b) =>
    asc
      ? a.attributes[champs] === b.attributes[champs]
        ? 0
        : a.attributes[champs]
        ? -1
        : 1
      : a.attributes[champs] === b.attributes[champs]
      ? 0
      : b.attributes[champs]
      ? -1
      : 1
  );
};
// export const sortByNumber = (arr, champs, asc) => {
//   arr.sort((a, b) =>
//     asc
//       ? a.attributes[champs] - b.attributes[champs]
//       : b.attributes[champs] - a.attributes[champs]
//   );
// };
const booleansFields = [
  "connectedSite",
  "connectedApp",
  "confident",
  "moneeverseToken",
  "premium",
];

export const sortFunction = (arr, champs, asc) => {
  if (booleansFields.includes(champs)) {
    sortBoolean(arr, champs, asc);
  }

  return arr.sort((a, b) =>
    champs === "id"
      ? asc
        ? a.id?.localeCompare(b.id, "fr", { numeric: true })
        : b.id?.localeCompare(a.id, "fr", { numeric: true })
      : asc
      ? a.attributes[champs]
          ?.toString()
          ?.localeCompare(b.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
      : b.attributes[champs]
          ?.toString()
          ?.localeCompare(a.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
  );
};
