import React from "react";
import { useSelector } from "react-redux";
import EmptyList from "../Common/EmptyList";
import Text from "../Common/Text";
import CompanyItem from "./CompanyItem";
import Loading from "../Common/Loading";
const CompaniesList = ({ sortingOptions, setsortingOptions }) => {
  const { companies, companiesLoading } = useSelector(
    (state) => state.companiesReducer
  );

  const handleSort = (champs) => {
    let temparray = sortingOptions;
    temparray = `sort: "${champs}:${
      sortingOptions.includes("asc") ? "desc" : "asc"
    }"`;
    setsortingOptions(temparray);
  };

  return (
    <div className="mb-5">
      <div className="container-fluid w100 pagginationContainer">
        <div className="row h44">
          <div
            className="col-1 alignC bordered pointer py-2"
            onClick={() => handleSort("id")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>ID</Text>{" "}
          </div>

          <div
            className="col-2 alignC bordered pointer py-2"
            onClick={() => handleSort("companyName")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Name
            </Text>{" "}
          </div>

          <div
            className="col-1 alignC bordered pointer py-2"
            onClick={() => handleSort("companyType")}
          >
            <Text className={" f16 medium me-1 text-center"}>Company Type</Text>
          </div>
          <div
            className="col-2 alignC bordered pointer py-2"
            onClick={() => handleSort("assetsUnderManagement")}
          >
            <Text className={" f16 medium me-1 text-center"}>
              Assets Under Management
            </Text>
          </div>
          <div
            className="col-2 alignC bordered pointer py-2"
            onClick={() => handleSort("website")}
          >
            <Text className={" f16 medium me-1 text-center"}>website Link</Text>
          </div>
          <div
            className="col-1 alignC bordered pointer py-2"
            onClick={() => handleSort("createdAt")}
          >
            <Text className={" f16 medium me-1 text-center"}>Created at</Text>
          </div>
          <div className="col-1 alignC bordered py-2">
            <Text className={" f16 medium me-1 text-center"}>
              Number of users
            </Text>
          </div>
          <div className="col-2 alignC bordered py-2">
            <Text className={" f16 medium me-1 text-center"}>Actions</Text>
          </div>
        </div>
      </div>
      {companiesLoading ? (
        <Loading />
      ) : companies.length === 0 ? (
        <EmptyList text={"No Company found"} />
      ) : (
        companies.map((item, index) => {
          return <CompanyItem item={item} key={index} index={index} />;
        })
      )}
    </div>
  );
};
export default CompaniesList;
