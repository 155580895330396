import React from "react";
import { useSelector } from "react-redux";

import Text from "../Common/Text";

import DealItem from "./DealItem";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
const DealsList = ({ sortingOptions, setsortingOptions }) => {
  const { deals, dealsLoading } = useSelector((state) => state.dealsReducer);

  const handleSort = (champs) => {
    let temparray = sortingOptions;
    temparray = `sort: "${champs}:${
      sortingOptions.includes("asc") ? "desc" : "asc"
    }"`;
    setsortingOptions(temparray);
  };
  return (
    <div className="mb-5">
      <div className="container-fluid w100 pagginationContainer">
        <div className="row h44">
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("id")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>ID</Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("tagline")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Tagline
            </Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("dealSize")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Deal Size
            </Text>{" "}
          </div>

          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("commitment")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              commitment
            </Text>{" "}
          </div>

          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("minInvestement")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              Min Investement
            </Text>{" "}
          </div>

          <div className="col-2 alignC bordered py-3">
            <Text className={" f14 medium me-1 text-center pointer "}>
              Creator
            </Text>{" "}
          </div>

          <div
            className="col-4 alignC bordered py-3"
            onClick={() => handleSort("description")}
          >
            <Text className={" f14 medium me-1 text-center pointer "}>
              description
            </Text>{" "}
          </div>

          <div className="col-1 alignC bordered py-3">
            <Text className={" f14 medium me-1 text-center pointer "}>
              Actions
            </Text>{" "}
          </div>
        </div>
      </div>

      {dealsLoading ? (
        <Loading />
      ) : !deals?.length > 0 ? (
        <EmptyList text={"No Deal found"} />
      ) : (
        deals.map((item, index) => {
          return <DealItem item={item} key={index} index={index} />;
        })
      )}
    </div>
  );
};
export default DealsList;
