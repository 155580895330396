import React from "react";
import { useSelector } from "react-redux";

import "./Input.css";
import Text from "./Text";

const Input = ({
  label,
  placeholder,
  setValue,
  champs,
  totalInputs,
  required,
  type = "text",
  value,
}) => {
  const handleChangeInput = (e) => {
    setValue({ ...totalInputs, [champs]: e.target.value });
  };

  return (
    <div className="w-100">
      <Text className={"xbold f12 mb-2"}>
        {label} {required ? <span className="text-red">*</span> : null}
      </Text>
      <input
        type={type}
        placeholder={placeholder}
        value={value ? value : ""}
        className={`input  w-100 h40  f12 p-2 `}
        onChange={(e) => handleChangeInput(e)}
      />
    </div>
  );
};

export default Input;
