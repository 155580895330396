import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_END_POINT } from "../../../Api/EndPoints";
import { getAdsItem, updateAd } from "../../../REDUX/actions/Ads";
import { durationData } from "../../../utils/SelectData";
import Button from "../Button/Button";
import Input from "../Input";
import Select from "../Selects/Select";
import Text from "../Text";
import SelectPhoneNumber from "../Selects/SelectPhoneNumber";

const UpdateAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { advertise, loading } = useSelector(
    (state) => state.advertisesReducer
  );
  const params = useParams();
  const id = params.id;

  const [isDisabled, setIsDisabled] = useState(true);
  const [uploads, setUploads] = useState({});
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    tagline: undefined,
    link: undefined,
    duration: undefined,
    companyName: undefined,
    contactPerson: undefined,
    position: undefined,
    state: undefined,
    email: undefined,
    phoneNumber: undefined,
    postalCode: undefined,
  });

  const handleUploadChange = (e) => {
    setUploads({ ...uploads, [e.target.name]: e.target.files[0] });
    setIsDisabled(false);
  };

  const handleSubmit = () => {
    const oldFiles = Object.keys(uploads).map(
      (key) => advertise?.attributes[key]?.data?.id
    );
    dispatch(
      updateAd(id, formData, uploads, oldFiles, () => {
        setWasSubmitted(true);
      })
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getAdsItem(id));
  }, [id, dispatch]);

  const handleChange = (v) => {
    setFormData(v);
    setIsDisabled(false);
  };

  useEffect(() => {
    setFormData({
      tagline: advertise?.attributes?.tagline,
      link: advertise?.attributes?.link,
      companyName: advertise?.attributes?.companyName,
      duration: advertise?.attributes?.duration,
      contactPerson: advertise?.attributes?.contactPerson,
      position: advertise?.attributes?.position,
      state: advertise?.attributes?.state,
      email: advertise?.attributes?.email,
      phoneNumber: advertise?.attributes?.phoneNumber,
      postalCode: advertise?.attributes?.postalCode,
    });
    setUploads({});
  }, [advertise]);

  useEffect(() => {
    if (wasSubmitted) {
      setIsDisabled(true);
    }
  }, [wasSubmitted]);

  if (!advertise) return null;

  return (
    <div>
      <div className="container-fluid h6">
        <div className="d-flex mb-5 alignH">
          <Text className={"sBold f30 text-deepBlue"}>Update advertise</Text>
        </div>
        <div className="border-0">
          <div className="d-md-flex mb-4">
            <div className="col ms-2">
              <Input
                label={"Tagline"}
                required
                setValue={handleChange}
                champs="tagline"
                totalInputs={formData}
                value={formData.tagline}
              />
            </div>
            <div className="col ms-2">
              <Input
                label={"Company name"}
                required
                setValue={handleChange}
                champs="companyName"
                totalInputs={formData}
                value={formData.companyName}
              />
            </div>
            <div className="col ms-2">
              <Input
                label={"Position"}
                required
                setValue={handleChange}
                champs="position"
                totalInputs={formData}
                value={formData.position}
              />
            </div>
          </div>
          <div className="d-md-flex mb-4">
            <div className="col ms-2">
              <Input
                label={"Link"}
                required
                type="text"
                setValue={handleChange}
                value={formData.link}
                champs="link"
                totalInputs={formData}
              />
            </div>
            <div className="col ms-2">
              <Text className={"xbold f12 mb-2"}>
                Duration<span className="text-red">*</span>
              </Text>
              <Select
                list={durationData}
                totalInputs={formData}
                setValue={handleChange}
                val={formData.duration}
                champs="duration"
              />
            </div>
            <div className="col ms-2">
              <SelectPhoneNumber
                label={"Phone Number"}
                required
                champs="phoneNumber"
                totalInputs={formData}
                value={formData.phoneNumber}
                setValue={handleChange}
              />
            </div>
          </div>
          <div className="d-md-flex mb-4">
            <div className="col ms-2">
              <Input
                label={"email"}
                required
                type="text"
                setValue={handleChange}
                value={formData.email}
                champs="email"
                totalInputs={formData}
              />
            </div>
            <div className="col ms-2">
              <Input
                label={"postalCode"}
                required
                type="number"
                setValue={handleChange}
                value={formData.postalCode}
                champs="postalCode"
                totalInputs={formData}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center my-4 gap-4 px-2">
            <div className="me-2 w-50 d-flex flex-column">
              <Text className="f16 medium mb-3">side advertising</Text>
              <div className="w-100 alignC ad-upload">
                <label className="p-4 alignC">
                  <input
                    type="file"
                    name="sideAd"
                    hidden
                    onChange={handleUploadChange}
                  />
                  <img
                    src={
                      uploads?.sideAd
                        ? URL.createObjectURL(uploads?.sideAd)
                        : advertise?.attributes?.sideAd?.data
                        ? API_END_POINT +
                          advertise?.attributes?.sideAd?.data?.attributes?.url
                        : process.env.PUBLIC_URL + "/coverPicture.png"
                    }
                    alt=""
                    className="ob-contain"
                  />
                  <div className="icons d-flex align-content-center gap-3">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-white"
                      size="2x"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="w-50 d-flex flex-column">
              <Text className={"f16 medium mb-3"}>Home advertising</Text>
              <div className="w-100 alignC ad-upload">
                <label className="p-4 alignC">
                  <input
                    type="file"
                    name="homeFeedAd"
                    hidden
                    onChange={handleUploadChange}
                  />
                  <img
                    src={
                      uploads?.homeFeedAd
                        ? URL.createObjectURL(uploads?.homeFeedAd)
                        : advertise?.attributes?.homeFeedAd?.data
                        ? API_END_POINT +
                          advertise?.attributes?.homeFeedAd?.data?.attributes
                            ?.url
                        : process.env.PUBLIC_URL + "/coverPicture.png"
                    }
                    alt=""
                    className="ob-contain"
                  />
                  <div className="icons d-flex align-content-center gap-3">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-white"
                      size="2x"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center my-4 gap-4 px-2">
            <div className="me-2 w-50 d-flex flex-column">
              <Text className="f16 medium mb-3">Club deal advertising</Text>
              <div className="w-100 alignC ad-upload">
                <label className="p-4 alignC">
                  <input
                    type="file"
                    name="clubdealAd"
                    hidden
                    onChange={handleUploadChange}
                  />
                  <img
                    src={
                      uploads?.clubdealAd
                        ? URL.createObjectURL(uploads?.clubdealAd)
                        : advertise?.attributes?.clubdealAd?.data
                        ? API_END_POINT +
                          advertise?.attributes?.clubdealAd?.data?.attributes
                            ?.url
                        : process.env.PUBLIC_URL + "/coverPicture.png"
                    }
                    alt=""
                    className="ob-contain"
                  />
                  <div className="icons d-flex align-content-center gap-3">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-white"
                      size="2x"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="w-50 d-flex flex-column">
              <Text className={"f16 medium mb-3"}>Network advertising</Text>
              <div className="w-100 alignC ad-upload">
                <label className="p-4 alignC">
                  <input
                    type="file"
                    name="profileNetworkAd"
                    hidden
                    onChange={handleUploadChange}
                  />
                  <img
                    src={
                      uploads?.profileNetworkAd
                        ? URL.createObjectURL(uploads?.profileNetworkAd)
                        : advertise?.attributes?.profileNetworkAd?.data
                        ? API_END_POINT +
                          advertise?.attributes?.profileNetworkAd?.data
                            ?.attributes?.url
                        : process.env.PUBLIC_URL + "/coverPicture.png"
                    }
                    alt=""
                    className="ob-contain"
                  />
                  <div className="icons d-flex align-content-center gap-3">
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="text-white"
                      size="2x"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="d-md-flex justify-content-end">
            <div className="d-flex  justify-content-end">
              <Button
                color={"blue"}
                className={"bg-white "}
                textButton="Cancel"
                callback={handleCancel}
              />
              <Button
                color={"white"}
                className={"bg-blue "}
                textButton="Submit"
                disabled={isDisabled || loading}
                callback={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAd;
