import { GET_FEEDBACK } from "../Graphql/Queries/Feedback";
import client from "./Client";
import { handleError } from "./toastMessage";

export const getAllFeedBacks =
  (page, pageSize, search, sortingOptions) => async (dispatch) => {
    dispatch({ type: "FEEDBACKS_LOADING" });

    try {
      const { data } = await client.query({
        query: GET_FEEDBACK(page, pageSize, search, sortingOptions),
      });

      dispatch({
        type: "FETCH_FEEDBACKS",
        payload: data.feedbacks.data,
        totalPages: data.feedbacks.meta?.pagination?.pageCount,
        page: data.feedbacks.meta?.pagination?.page,
      });
    } catch (error) {
      console.log(error);

      dispatch(handleError("Error try again later", 1));
    }
  };
