import {
  AdminSvg,
  AdsSvg,
  AssembleeSvg,
  BienSvg,
  ComptesSvg,
  DashboardSvg,
  DocumentsSvg,
  FeedBackSvg,
  SignalementsSvg,
} from "../../Svgs";

export const onglets = [
  {
    name: "Dashboard",
    icon: (color) => <DashboardSvg color={color} />,
    to: "/",
    showOnlyToAdmin: true,
  },

  {
    name: "Investors",
    icon: (color) => <BienSvg color={color} />,
    to: "/investors",
    showOnlyToAdmin: true,
  },
  {
    name: "Professionals",
    icon: (color) => <SignalementsSvg color={color} />,
    to: "/professionals",
    showOnlyToAdmin: true,
  },
  {
    name: "Companies",
    icon: (color) => <ComptesSvg color={color} />,
    to: "/companies",
    showOnlyToAdmin: true,
  },

  {
    name: "Feedback",
    icon: (color) => <FeedBackSvg color={color} />,
    to: "/feedback",
    showOnlyToAdmin: true,
  },
  {
    name: "Club Deals",
    icon: (color) => <AssembleeSvg color={color} />,
    to: "/deals",
    showOnlyToAdmin: true,
  },
  {
    name: "Ads",
    icon: (color) => <AdsSvg color={color} />,
    to: "/ads",
    showOnlyToAdmin: true,
  },
  {
    name: "Admin Management",
    icon: (color) => <AdminSvg color={color} />,
    to: "/adminManagement",
    showOnlyToAdmin: false,
  },
];
