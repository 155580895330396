import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Images/Icons/PoF-logo-blue.png";
import useResponsive from "../../../utils/useResponsive";
import { DeconnexionSvg } from "../../Svgs";
import "./Header.css";
import ProfileLink from "../../Common/ProfileLink/ProfileLink";
import { API_END_POINT } from "../../../Api/EndPoints";

const Header = ({ user }) => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload(false);
  };
  const isMobileSize = useResponsive();
  return (
    <div className="headerContainer bg-white d-flex justify-content-between align-items-center px-3 w100">
      <Link to={"/"} className="pointer">
        <img src={logo} alt="pof" width={200} />
      </Link>
      <div className="d-flex">
        <ProfileLink
          profilePic={
            API_END_POINT +
            (user?.attributes?.photo?.data?.attributes?.url ||
              "/uploads/telechargement_9afb9aac29.jfif")
          }
          fullName={user?.attributes.firstName + user?.attributes?.lastName}
          email={user?.attributes.email}
        ></ProfileLink>
      </div>
      {isMobileSize ? (
        <div className="logout mb-2" onClick={handleLogout}>
          <div className="d-flex justify-content-start align-items-center gap-3 ps-1">
            <DeconnexionSvg color="white" />{" "}
            <span className="text-white">Déconnexion</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
