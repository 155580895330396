export const handleInfo = (message, type) => async (dispatch) => {
  dispatch({ type: "HIDE_INFO" });
  dispatch({
    type: type ? "SHOW_INFO" : "HIDE_INFO",
    payload: { message: message },
  });
};

export const handleError = (message, type) => async (dispatch) => {
  dispatch({ type: "HIDE_ERROR" });

  dispatch({
    type: type ? "SHOW_ERROR" : "HIDE_ERROR",
    payload: message,
  });
};

export const closeNotification = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_NOTIFICATION",
  });
};
