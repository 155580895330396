import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../Components/Common/Input";
import SelectPhoneNumber from "../../Components/Common/Selects/SelectPhoneNumber";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import Text from "../../Components/Common/Text";
import Button from "../../Components/Common/Button/Button";
import MultiSelect from "../../Components/Common/Selects/MultiSelect";
import Uploader from "../../Components/Common/Uploader";
import { useNavigate } from "react-router-dom";
import { CreateClient } from "../../REDUX/actions/clients";
import { validMail } from "../../utils/utils";

const NewProfessionalView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { languages } = useSelector((state) => state.languagesReducer);
  const [ProfessionalInput, setProfessionalInput] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    password: "",
    phoneNumber: 0,
    country: null,
    premium: 0,
    profileType: 0,
    photo: "",
    coverPicture: "",
    languages: [],
    state: 0,
  });

  const handleSubmit = () => {
    dispatch(CreateClient(ProfessionalInput));
  };
  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <div className="container-fluid">
      <div className="d-flex mb-4 alignH">
        <Text className={"sBold f30 text-deepBlue"}>New Professional</Text>
      </div>
      <div className=" border-0">
        <div className="d-md-flex ">
          <div className="col m-2">
            <Input
              label={"Username"}
              champs="username"
              required
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.username}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"First name"}
              required
              champs="firstName"
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.firstName}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Last name"}
              champs="lastName"
              required
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.lastName}
              setValue={setProfessionalInput}
            />
          </div>
        </div>
        <div className="d-md-flex">
          <div className="col m-2">
            <Input
              label={"Email"}
              champs="email"
              required
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.email}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Password"}
              champs="password"
              required
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.password}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Job Title"}
              champs="jobTitle"
              required
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.jobTitle}
              setValue={setProfessionalInput}
            />
          </div>
        </div>
        <div className="d-md-flex">
          <div className="col m-2">
            <SelectPhoneNumber
              label={"Phone Number"}
              required
              champs="phoneNumber"
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.phoneNumber}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>Country</Text>
            <SelectCountry
              champs="country"
              totalInputs={ProfessionalInput}
              value={ProfessionalInput.country}
              setValue={setProfessionalInput}
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>Languages</Text>
            <MultiSelect
              list={languages}
              champs="languages"
              totalInputs={ProfessionalInput}
              setValue={setProfessionalInput}
            />
          </div>
        </div>
        <div className="d-md-flex">
          <div className="col m-2">
            <Uploader
              label={"Photo"}
              totalInputs={ProfessionalInput}
              setValue={setProfessionalInput}
              value={ProfessionalInput.photo}
              champs="photo"
            />
          </div>
          <div className="col m-2">
            <Uploader
              label={"Cover Picture"}
              totalInputs={ProfessionalInput}
              setValue={setProfessionalInput}
              value={ProfessionalInput.coverPicture}
              champs="coverPicture"
            />
          </div>
        </div>
        <div className="d-md-flex">
          <div className="d-flex  justify-content-end">
            <Button
              color={"blue"}
              className={"bg-white "}
              textButton="Cancel"
              callback={handleCancel}
            />
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton="Submit"
              disabled={
                !ProfessionalInput.firstName ||
                !ProfessionalInput.lastName ||
                !ProfessionalInput.jobTitle ||
                !validMail(ProfessionalInput.email) ||
                !ProfessionalInput.password ||
                ProfessionalInput.phoneNumber.length < 8
              }
              callback={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProfessionalView;
