const initialState = {
  countries: [],
  countriesLoading: false,
};

const countriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "COUNTRIES_LOADING":
      return { ...state, countriesLoading: true };
    case "FETCH_COUNTRIES":
      return { ...state, countries: payload, countriesLoading: false };
    default:
      return state;
  }
};
export default countriesReducer;
