import { Modal } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ConfirmationModal.css";
import { closeConfirmationModal } from "../../../REDUX/actions/ConfirmationModal";
import Button from "../Button/Button";
import Text from "../Text";

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const {
    open,
    message,
    message2,
    confirmButtonText,
    callBack,
    cancelCallback,
    cancelButtonText,
  } = useSelector((state) => state.confirmationModalReducer);

  const handleConfirm = () => {
    callBack();
    dispatch(closeConfirmationModal());
  };

  const handleClose = () => {
    if (cancelCallback) {
      cancelCallback();
    }
    dispatch(closeConfirmationModal());
  };
  const hide = () => {
    dispatch(closeConfirmationModal());
  };
  return (
    <Modal
      show={open}
      onHide={hide}
      size={"md"}
      centered
      className=" modal-fullscreen-sm-down"
    >
      <Modal.Body className="text-center my-4">
        <Text className=" f16 bold">{message} </Text>
        <Text className="f24 bold mt-1">{message2}</Text>
      </Modal.Body>
      <Modal.Footer className="modalFooter">
        <div className="row  justify-content-end">
          <div className="col ">
            <Button
              textButton={cancelButtonText}
              className="border-red"
              color="red"
              bgColor={"white"}
              callback={handleClose}
            />{" "}
          </div>
          <div className="col">
            <Button
              textButton={confirmButtonText}
              color={"white"}
              bgColor={"blue"}
              callback={handleConfirm}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
