import React from "react";
import "./SearchInput.css";
const SearchInput = ({
  value,
  placeholder,
  onChange = () => {},
  name,
  defaultBorder = true,
}) => {
  return (
    <input
      type="search"
      className={`searchInput ps-3 w-100 ${defaultBorder ? "default" : ""}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
    />
  );
};

export default SearchInput;
