export const openModal = (data) => async (dispatch) => {
  dispatch({
    type: "OPEN_GLOBAL_MODAL",
    payload: data,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: "CLOSE_GLOBAL_MODAL",
  });
};
