import { gql } from "@apollo/client";
import { ATTRIBUTES_COMPANY } from "../Attributes/Company";

export const getCompaniesQuery = (
  page = 1,
  pageSize = 50,
  search = undefined,
  sortingOptions
) => gql`
   {    
   companies(filters: {${
     search ? `companyName: {containsi: "${search}"}` : ""
   }}, pagination:{page:${page},pageSize:50} ${sortingOptions})   {
        data {
          id 
          attributes {
            ${ATTRIBUTES_COMPANY}
          }
        }
        meta {
          pagination {
              total
              pageCount
              page
          }
        }
    }
  }
`;

export const GET_COMPANY_BY_ID = (id) => gql`
  query {
    companies(filters: { id: { eq: ${id} } }) {
      data {
        id
        attributes {
          ${ATTRIBUTES_COMPANY}
        }
      }
    }
  }
`;
