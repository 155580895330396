import API from "../../Api/Api";

export const setFile = (file) => async (dispatch) => {
  dispatch({ type: "SELECTED_FILE", payload: file });
};
export const uploadUserFile =
  (attachementFiles, userId) => async (dispatch) => {
    dispatch({ type: "LOADING_UPLOAD" });

    try {
      for (let i = 0; i < attachementFiles.file.length; i++) {
        let formData = new FormData();
        formData.append("refId", userId);
        formData.append("ref", "plugin::users-permissions.user");
        formData.append(
          "files",

          attachementFiles.file[i].files[0]
        );
        formData.append("field", attachementFiles.file[i].champs);
        await API.post("/api/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const uploadFile = (attachementFiles, userId) => async (dispatch) => {
  dispatch({ type: "LOADING_UPLOAD" });
  let formData = new FormData();
  try {
    for (let i = 0; i < attachementFiles.file.length; i++) {
      const file = attachementFiles.file[i];
      formData.append("files", file.files[0]);
    }
    const result = await API.post("/api/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch({ type: "UPLOAD_DONE" });
    return result;
  } catch (error) {
    console.log(error);
  }
};
