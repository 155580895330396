import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Text from "../Common/Text";
import useResponsive from "../../utils/useResponsive";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const PieChart = ({
  title,
  total,
  text,
  labels,
  values,
  colors = [
    "#515DC6",
    "#E0BAA9",
    "#836CC3",
    "#00B1C0",
    "#ffb55a",
    "#79CEB8",
    "#6B735E",
    "#fdcce5",
    "#765C9E",
  ],
  hoverOffset = 10,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        hoverOffset: hoverOffset,
      },
    ],
  };
  const isMobileSize = useResponsive();
  return (
    <div
      className={`shadow  p-4 bg-white rounded-20  ${
        isMobileSize ? "w-100" : ""
      }`}
    >
      <div className="d-flex justify-content-between">
        <Text className={"bold f20"}>{title} </Text>
        <Text className={"bold f20"}>{total} </Text>
      </div>
      <Doughnut
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: text,
              padding: {
                bottom: 30,
                top: 30,
              },

              weight: "bold",
              color: "#00325c",
              font: {
                size: 13,
              },
              align: "center",
            },
          },
        }}
      />
    </div>
  );
};

export default PieChart;
