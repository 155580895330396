import React from "react";
import { useSelector } from "react-redux";
import { Routes as Switch, Route } from "react-router-dom";
import AdminManagementView from "../Views/AdminManagement/AdminManagementView";
import AdsView from "../Views/AdsView";
import CompaniesView from "../Views/Company/CompaniesView";
import DashboardView from "../Views/DashboardView";
import DealsView from "../Views/DealsView";
import FeedbackView from "../Views/FeedbackView";
import InvestorsView from "../Views/Investor/InvestorsView";
import LoginView from "../Views/LoginView/LoginView";
import NewCompanyView from "../Views/Company/NewCompanyView";
import NewInvestorView from "../Views/Investor/NewInvestorView";
import NewProfessionalView from "../Views/Professional/NewProfessionalView";
import ProfessionalsView from "../Views/Professional/ProfessionalsView";
import UpdateCompanyView from "../Views/Company/UpdateCompanyView";
import UpdateProfessionalView from "../Views/Professional/UpdateProfessionalView";
import UpdateInvestorView from "../Views/Investor/UpdateInvestorView";
import UpdateAd from "../Components/Common/Modal/UpdateAd";

const Routes = () => {
  const { user } = useSelector((state) => state.usersReducer);
  return (
    <Switch>
      {!user ? (
        <>
          <Route path="/" exact element={<LoginView />}></Route>
        </>
      ) : (
        <>
          <Route path="/" exact element={<DashboardView />}></Route>
          <Route path="/investors" exact element={<InvestorsView />}></Route>
          <Route
            path="/investors/new"
            exact
            element={<NewInvestorView />}
          ></Route>
          <Route
            path="/investors/:id"
            exact
            element={<UpdateInvestorView />}
          ></Route>
          <Route
            path="/professionals/:id"
            exact
            element={<UpdateProfessionalView />}
          ></Route>
          <Route
            path="/professionals"
            exact
            element={<ProfessionalsView />}
          ></Route>
          <Route
            path="/professionals/new"
            exact
            element={<NewProfessionalView />}
          ></Route>
          <Route
            path="/professionals/:id"
            exact
            element={<UpdateProfessionalView />}
          ></Route>
          <Route path="/companies" exact element={<CompaniesView />}></Route>
          <Route
            path="/companies/new"
            exact
            element={<NewCompanyView />}
          ></Route>
          <Route
            path="/companies/:id"
            exact
            element={<UpdateCompanyView />}
          ></Route>

          <Route path="/feedback" exact element={<FeedbackView />}></Route>
          <Route path="/deals" exact element={<DealsView />}></Route>
          <Route path="/ads" exact element={<AdsView />}></Route>
          <Route path="/ads/:id" exact element={<UpdateAd />}></Route>
          <Route
            path="/adminManagement"
            exact
            element={<AdminManagementView />}
          ></Route>
        </>
      )}
    </Switch>
  );
};

export default Routes;
