import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_END_POINT } from "../../Api/EndPoints";
import { getAdsItem } from "../../REDUX/actions/Ads";
import Text from "../Common/Text";
import { CancelIcon, SuccessIcon } from "../Svgs";
import PieChart from "../dashboard/PieChart";

const AdsDetails = ({ adId }) => {
  const dispatch = useDispatch();
  const { advertise } = useSelector((state) => state.advertisesReducer);
  useEffect(() => {
    dispatch(getAdsItem(adId));
    // eslint-disable-next-line
  }, [adId]);

  return (
    <div className="container-fluid ps-4 pe-4 pb-4">
      <div className="p-2 ">
        <Text className={"f20 medium mb-3"}>Ad Information</Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Tagline: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.tagline}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Contact person: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.contactPerson}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Position: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.position}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Email: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.email}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>phone Number: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.phoneNumber}
        </Text>
      </div>

      <div className="row">
        <Text className={"col f16 medium "}>Company Name: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.companyName}
        </Text>
      </div>

      <div className="row">
        <Text className={"col f16 medium "}>Duration: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.duration}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Number of display: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.numberDisplays}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Link: </Text>
        <Text className={"col f16 regular "}>
          <a href={advertise?.attributes.link} target="_blank" rel="noreferrer">
            Link
          </a>{" "}
        </Text>
      </div>

      <div className="row pt-1">
        <Text className={"col f16 medium "}>payed: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes.payed ? <SuccessIcon /> : <CancelIcon />}
        </Text>
      </div>

      <div className="row pt-1">
        <Text className={"col f16 medium "}>Total clicks: </Text>
        <Text className={"col f16 regular "}>
          {advertise?.attributes?.clicks?.data.length}
        </Text>
      </div>
      <div className="alignC my-4">
        <div className="w40">
          <PieChart
            title={"Clicks Per position"}
            total={advertise?.attributes?.clicks?.data.length}
            labels={["SideBar", "Home", "Profiles", "Club-Deals"]}
            values={[
              advertise?.attributes?.clicks?.data?.filter(
                (el) => el.attributes?.type === 0
              ).length,
              advertise?.attributes?.clicks?.data?.filter(
                (el) => el.attributes?.type === 1
              ).length,
              advertise?.attributes?.clicks?.data?.filter(
                (el) => el.attributes?.type === 2
              ).length,
              advertise?.attributes?.clicks?.data?.filter(
                (el) => el.attributes?.type === 3
              ).length,
            ]}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center my-4 gap-3">
        <div className="me-2 w-50 d-flex flex-column">
          <Text className="f16 medium mb-3">side advertising</Text>
          <div className="w-100 alignC ad-upload">
            <img
              src={
                advertise?.attributes?.sideAd?.data
                  ? API_END_POINT +
                    advertise?.attributes?.sideAd?.data?.attributes?.url
                  : process.env.PUBLIC_URL + "/coverPicture.png"
              }
              alt=""
              className="ob-contain"
            />
          </div>
        </div>
        <div className="w-50 d-flex flex-column">
          <Text className={"f16 medium mb-3"}>Home advertising</Text>
          <div className="w-100 alignC ad-upload">
            <img
              src={
                advertise?.attributes?.homeFeedAd?.data
                  ? API_END_POINT +
                    advertise?.attributes?.homeFeedAd?.data?.attributes?.url
                  : process.env.PUBLIC_URL + "/coverPicture.png"
              }
              alt=""
              className="ob-contain"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-4 gap-3">
        <div className="me-2 w-50 d-flex flex-column">
          <Text className="f16 medium mb-3">Club deal advertising</Text>
          <div className="w-100 alignC ad-upload">
            <img
              src={
                advertise?.attributes?.clubdealAd?.data
                  ? API_END_POINT +
                    advertise?.attributes?.clubdealAd?.data?.attributes?.url
                  : process.env.PUBLIC_URL + "/coverPicture.png"
              }
              alt=""
              className="ob-contain"
            />
          </div>
        </div>
        <div className="w-50 d-flex flex-column">
          <Text className={"f16 medium mb-3"}>Network advertising</Text>
          <div className="w-100 alignC ad-upload">
            <img
              src={
                advertise?.attributes?.profileNetworkAd?.data
                  ? API_END_POINT +
                    advertise?.attributes?.profileNetworkAd?.data?.attributes
                      ?.url
                  : process.env.PUBLIC_URL + "/coverPicture.png"
              }
              alt=""
              className="ob-contain"
            />
          </div>
        </div>
      </div>
      {/* <div className="row align-items-center">
        <Text className={"col-6 f16 medium "}>State: </Text>

        {!advertise?.attributes.state || advertise?.attributes.state === 0 ? (
          <Button
            textButton={"update"}
            className={"col-2 "}
            color="blue"
            callback={() =>
              dispatch(
                openConfirmationModal({
                  callBack: () => dispatch(UpdateAdsState(1, advertise?.id)),
                  message: `Would you like to update the state of this  advertise ?`,
                  message2: `${advertise?.attributes.tagline} `,
                  confirmButtonText: "Activate",
                  cancelButtonText: "disactivate",
                  cancelCallback: () =>
                    dispatch(UpdateAdsState(2, advertise?.id)),
                })
              )
            }
          />
        ) : (
          <Button
            textButton={
              advertise?.attributes.state === 1 ? "disactivate" : "activate"
            }
            className={`col-12  ${
              advertise?.attributes.state === 1 ? "border-red" : "border-green"
            }`}
            color={`${advertise?.attributes.state === 1 ? "red" : "green"}`}
            callback={() =>
              dispatch(
                openConfirmationModal({
                  callBack: () =>
                    dispatch(
                      UpdateAdsState(
                        advertise?.attributes.state === 1 ? 2 : 1,
                        advertise?.id
                      )
                    ),
                  icon: "archive",
                  message: `Would you like to ${
                    advertise?.attributes.state === 1
                      ? "disactivate"
                      : "activate"
                  } this  advertise ?`,
                  message2: `${advertise?.attributes.tagline} `,
                  confirmButtonText: "Submit",
                  cancelButtonText: "Cancel",
                })
              )
            }
          />
        )}
      </div> */}
    </div>
  );
};

export default AdsDetails;
