import { gql } from "@apollo/client";

export const CREATE_FULL_ADDRESS = gql`
  mutation ($address: String, $zipCode: Int, $country: ID) {
    createFullAddress(
      data: { address: $address, zipCode: $zipCode, country: $country }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_FULL_ADDRESS = gql`
  mutation ($id: ID!, $address: String, $zipCode: Int, $country: ID) {
    updateFullAddress(
      id: $id
      data: { address: $address, zipCode: $zipCode, country: $country }
    ) {
      data {
        id
      }
    }
  }
`;
