import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Common/Button/Button";
import Input from "../../Components/Common/Input";
import Loading from "../../Components/Common/Loading";
import MultiSelect from "../../Components/Common/Selects/MultiSelect";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import SelectPhoneNumber from "../../Components/Common/Selects/SelectPhoneNumber";
import Text from "../../Components/Common/Text";
import Uploader from "../../Components/Common/Uploader";
import { getClientById, UpdateClient } from "../../REDUX/actions/clients";
import useResponsive from "../../utils/useResponsive";
import Switch from "../../Components/Common/Button/Switch";

const UpdateProfessionalView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { selectedFile } = useSelector((state) => state.filesReducer);
  const { client } = useSelector((state) => state.clientsReducer);
  const { languages } = useSelector((state) => state.languagesReducer);
  useEffect(() => {
    dispatch(getClientById(params.id));
  }, [params.id, dispatch]);

  const [UserInput, setUserInput] = useState({
    username: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    jobTitle: undefined,
    // password: undefined,
    phoneNumber: undefined,
    country: undefined,
    premium: undefined,
    profileType: undefined,
    photo: undefined,
    coverPicture: undefined,
    languages: undefined,
    userId: undefined,
    mailNotificationOnClubDeal: false,
    mailNotificationOnComment: false,
    mailNotificationOnMessage: false,
    mailNotificationOnTeamMember: false,
    mailNotificationOnPosts: false,
  });
  useEffect(() => {
    setUserInput({
      username: client?.attributes?.username,
      email: client?.attributes?.email,
      firstName: client?.attributes.firstName,
      lastName: client?.attributes.lastName,
      jobTitle: client?.attributes.jobTitle,
      // password: "",
      phoneNumber: client?.attributes.phoneNumber,
      country: client?.attributes.country.data?.id,
      premium: 0,
      profileType: 0,
      photo: client?.attributes.photo.data?.attributes.url,
      coverPicture: client?.attributes.coverPicture.data?.attributes.url,
      languages: client?.attributes.languages.data.map(({ id }) => id),
      userId: params.id,
      mailNotificationOnClubDeal:
        client?.attributes?.mailNotificationOnClubDeal,
      mailNotificationOnComment: client?.attributes?.mailNotificationOnComment,
      mailNotificationOnMessage: client?.attributes?.mailNotificationOnMessage,
      mailNotificationOnTeamMember:
        client?.attributes?.mailNotificationOnTeamMember,
      mailNotificationOnPosts: client?.attributes?.mailNotificationOnPosts,
    });
  }, [client, params.id]);

  const handleCancel = () => {
    navigate(-1);
  };
  const handleSubmit = () => {
    dispatch(UpdateClient(UserInput, { file: selectedFile }));
  };
  const isMobileSize = useResponsive();

  return (
    <div className="container-fluid position-relative">
      {!client ? (
        <Loading />
      ) : (
        <div className="container-fluid ">
          {!client ? (
            <Loading />
          ) : (
            <div>
              <div className="d-flex mb-4 alignH">
                <Text className={"sBold f30 text-deepBlue"}>
                  Update Professional
                </Text>
              </div>
              <div className=" w-100">
                <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
                  <div className="col m-2">
                    <Input
                      label={"Username"}
                      champs="username"
                      required
                      totalInputs={UserInput}
                      value={UserInput.username}
                      setValue={setUserInput}
                    />
                  </div>
                  <div className="col m-2">
                    <Input
                      label={"First name"}
                      required
                      champs="firstName"
                      totalInputs={UserInput}
                      value={UserInput.firstName}
                      setValue={setUserInput}
                    />
                  </div>
                  <div className="col m-2">
                    <Input
                      label={"Last name"}
                      champs="lastName"
                      required
                      totalInputs={UserInput}
                      value={UserInput.lastName}
                      setValue={setUserInput}
                    />
                  </div>
                </div>
                <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
                  <div className="col m-2">
                    <Input
                      label={"Email"}
                      champs="email"
                      required
                      totalInputs={UserInput}
                      value={UserInput.email}
                      setValue={setUserInput}
                    />
                  </div>
                  <div className="col m-2">
                    <SelectPhoneNumber
                      label={"Phone Number"}
                      required
                      champs="phoneNumber"
                      totalInputs={UserInput}
                      value={UserInput.phoneNumber}
                      setValue={setUserInput}
                    />
                    {/* <Input
                  label={"Password"}
                  champs="password"
                  required
                  totalInputs={UserInput}
                  value={UserInput.password}
                  setValue={setUserInput}
                /> */}
                  </div>
                  <div className="col m-2">
                    <Input
                      label={"Job Title"}
                      champs="jobTitle"
                      required
                      totalInputs={UserInput}
                      value={UserInput.jobTitle}
                      setValue={setUserInput}
                    />
                  </div>
                </div>
                <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
                  <div className="col m-2">
                    <Text className={"xbold f12 mb-2"}>Country</Text>
                    <SelectCountry
                      champs="country"
                      totalInputs={UserInput}
                      value={UserInput.country}
                      setValue={setUserInput}
                    />
                  </div>
                  <div className="col m-2">
                    <Text className={"xbold f12 mb-2"}>Languages</Text>
                    <MultiSelect
                      list={languages}
                      champs="languages"
                      totalInputs={UserInput}
                      setValue={setUserInput}
                      value={UserInput.languages}
                    />
                  </div>
                </div>
                <div className={`${isMobileSize ? "w70" : "w80 d-flex"} `}>
                  <div className={`${isMobileSize ? "" : "w50"} m-2`}>
                    <Uploader
                      label={"Photo"}
                      totalInputs={UserInput}
                      setValue={setUserInput}
                      value={
                        UserInput.photo
                          ? UserInput.photo.replace("/uploads/", "")
                          : UserInput.photo
                      }
                      champs="photo"
                    />
                  </div>
                  <div className={`${isMobileSize ? "" : "w50"} m-2`}>
                    <Uploader
                      label={"Cover Picture"}
                      totalInputs={UserInput}
                      setValue={setUserInput}
                      value={
                        UserInput.coverPicture
                          ? UserInput.coverPicture.replace("/uploads/", "")
                          : UserInput.coverPicture
                      }
                      champs="coverPicture"
                    />
                  </div>
                </div>
                <div className="d-flex w80 m-2">
                  <div className="d-flex flex-column me-5">
                    <Text className={"xbold f12 mb-2"}>
                      Publication of a club deal
                    </Text>
                    <Switch
                      value={UserInput?.mailNotificationOnClubDeal}
                      callBack={() =>
                        setUserInput({
                          ...UserInput,
                          mailNotificationOnClubDeal:
                            !UserInput?.mailNotificationOnClubDeal,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex flex-column me-5">
                    <Text className={"xbold f12 mb-2"}>
                      Comment on a publication
                    </Text>
                    <Switch
                      value={UserInput?.mailNotificationOnComment}
                      callBack={() =>
                        setUserInput({
                          ...UserInput,
                          mailNotificationOnComment:
                            !UserInput?.mailNotificationOnComment,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex flex-column me-5">
                    <Text className={"xbold f12 mb-2"}>
                      Chat message received
                    </Text>
                    <Switch
                      value={UserInput?.mailNotificationOnMessage}
                      callBack={() =>
                        setUserInput({
                          ...UserInput,
                          mailNotificationOnMessage:
                            !UserInput?.mailNotificationOnMessage,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex flex-column me-5">
                    <Text className={"xbold f12 mb-2"}>
                      Registration of a member
                    </Text>
                    <Switch
                      value={UserInput?.mailNotificationOnTeamMember}
                      callBack={() =>
                        setUserInput({
                          ...UserInput,
                          mailNotificationOnTeamMember:
                            !UserInput?.mailNotificationOnTeamMember,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex flex-column me-5">
                    <Text className={"xbold f12 mb-2"}>New Posts</Text>
                    <Switch
                      value={UserInput?.mailNotificationOnPosts}
                      callBack={() =>
                        setUserInput({
                          ...UserInput,
                          mailNotificationOnPosts:
                            !UserInput?.mailNotificationOnPosts,
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  className={`${
                    isMobileSize ? "d-flex" : "w80 d-md-block d-lg-flex"
                  } justify-content-start mt-4 `}
                >
                  <Button
                    color={"blue"}
                    className={"bg-white "}
                    textButton="Cancel"
                    callback={handleCancel}
                  />
                  <Button
                    color={"white"}
                    className={"bg-blue "}
                    textButton="Submit"
                    callback={handleSubmit}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateProfessionalView;
