import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAds } from "../../REDUX/actions/Ads";
import { getAllCompanies } from "../../REDUX/actions/company";
import { getAllCountries } from "../../REDUX/actions/country";
import { getAllDeals } from "../../REDUX/actions/deals";

import Drawer from "./Drawer.jsx/Drawer";
import Header from "./Header/Header";
import "./Layout.css";
import CommonModal from "../Common/Modal/CommonModal";
import ToastMessage from "../Common/Modal/ToastMessage";
import Loading from "../Common/Loading";
import { useLocation } from "react-router-dom";
import { getAllLanguages } from "../../REDUX/actions/Language";
import ConfirmationModal from "../Common/Modal/ConfirmationModal";
import useResponsive from "../../utils/useResponsive";
import BlockModal from "../Common/Modal/BlockModal";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { user, userLoading } = useSelector((state) => state.usersReducer);
  const isMobileSize = useResponsive();
  useEffect(() => {
    if (user) {
      dispatch(getAllCountries());
      dispatch(getAllLanguages());

      dispatch(getAllCompanies());

      dispatch(getAllDeals());

      dispatch(getAllAds());

      // dispatch(getAllFeedBacks());
    }
  }, [user, pathname]);
  return userLoading ? (
    <div>
      <Header user={user} />
      <div className="d-flex gap-4">
        <Drawer />
        <div className={isMobileSize ? "layoutAppResp" : "layoutApp"}>
          <div className="">
            <Loading />
          </div>
        </div>
      </div>
    </div>
  ) : user ? (
    <div>
      <Header user={user} />
      <div className="d-flex gap-4">
        <Drawer />
        <CommonModal />
        <ConfirmationModal />
        <ToastMessage />
        <BlockModal />
        <div className={isMobileSize ? "layoutAppResp" : "layoutApp"}>
          {children}
        </div>
      </div>
    </div>
  ) : (
    <div>
      <ToastMessage />
      {children}
    </div>
  );
};

export default Layout;
