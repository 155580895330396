const initialState = {
  languages: [],
  languagesLoading: false,
};

const languagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LANGUAGES_LOADING":
      return { ...state, languagesLoading: true };
    case "FETCH_LANGUAGES":
      return { ...state, languages: payload, languagesLoading: false };
    default:
      return state;
  }
};
export default languagesReducer;
