import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_END_POINT } from "../../Api/EndPoints";
import { getDealItem } from "../../REDUX/actions/deals";
import Text from "../Common/Text";

const DealDetails = ({ dealId }) => {
  const dispatch = useDispatch();
  const { deal } = useSelector((state) => state.dealsReducer);
  useEffect(() => {
    dispatch(getDealItem(dealId));
    // eslint-disable-next-line
  }, [dealId]);

  return (
    <div className="container-fluid ps-4 pe-4 pb-4">
      <div className="cover ">
        <img
          src={
            deal?.attributes?.dealLogo.data
              ? API_END_POINT + deal?.attributes?.dealLogo.data.attributes.url
              : process.env.PUBLIC_URL + "/coverPicture.png"
          }
          alt=""
        />
      </div>
      <div className="p-2">
        <Text className={"f20 medium mb-3"}>Deal Information</Text>
      </div>
      <div className="row my-5 ">
        <Text className={"col f16 medium "}>Creator: </Text>
        <Text className={"col f16 regular "}>
          {deal?.attributes?.user?.data?.attributes?.profileType === 1
            ? "Professional"
            : "Investor"}
          -{deal?.attributes?.user?.data?.id}
          <br />
          <strong>
            {deal?.attributes?.user?.data?.attributes?.lastName}{" "}
            {deal?.attributes?.user?.data?.attributes?.firstName}
          </strong>
        </Text>
      </div>
      <div className="row my-5 ">
        <Text className={"col f16 medium "}>Tagline: </Text>
        <Text className={"col f14 regular "}>{deal?.attributes.tagline}</Text>
      </div>
      <div className="row my-5">
        <Text className={"col f16 medium "}>Description: </Text>
        <Text className={"col f16 regular "}>
          {deal?.attributes.description}
        </Text>
      </div>
      <div className="row my-5">
        <Text className={"col f16 medium "}>Deal Size: </Text>
        <Text className={"col f16 regular "}>{deal?.attributes.dealSize}</Text>
      </div>
      <div className="row my-5">
        <Text className={"col f16 medium "}>Commitment: </Text>
        <Text className={"col f16 regular "}>
          {deal?.attributes.commitment}
        </Text>
      </div>
      <div className="row my-5 ">
        <Text className={"col f16 medium "}>Min Investement: </Text>
        <Text className={"col f16 regular "}>
          {deal?.attributes.minInvestement}
        </Text>
      </div>
    </div>
  );
};

export default DealDetails;
