import { gql } from "@apollo/client";
import { ATTRIBUTES_CLIENTS } from "../Attributes/Clients";

export const CREATE_CLIENT = gql`
  mutation (
    $firstName: String
    $lastName: String
    $username: String
    $email: String
    $jobTitle: String
    $phoneNumber: String
    $password:String
    $country:ID
    $profileType:Int
    $premium :Boolean
    $languages:[ID]
    $state:Int
    $role:ID
  ) {
    createUsersPermissionsUser(
      data: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        email: $email
        jobTitle: $jobTitle
        phoneNumber: $phoneNumber
        password:$password
        country:$country
        profileType:$profileType
        premium:$premium
        languages:$languages
        state:$state
        role:$role
        
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_CLIENTS}
        }
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation (
    $id:ID!
    $firstName: String
    $lastName: String
    $username: String
    $email: String
    $jobTitle: String
    $phoneNumber: String
    $password:String
    $country:ID
    $profileType:Int
    $premium :Boolean
    $languages:[ID]
    $mailNotificationOnClubDeal: Boolean,
    $mailNotificationOnComment: Boolean,
    $mailNotificationOnMessage: Boolean,
    $mailNotificationOnTeamMember: Boolean,
    $mailNotificationOnPosts: Boolean,
  ) {
    updateUsersPermissionsUser(
      id: $id,
      data: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        email: $email
        jobTitle: $jobTitle
        phoneNumber: $phoneNumber
        password:$password
        country:$country
        profileType:$profileType
        premium:$premium
        languages:$languages
        mailNotificationOnClubDeal: $mailNotificationOnClubDeal,
        mailNotificationOnComment: $mailNotificationOnComment,
        mailNotificationOnMessage: $mailNotificationOnMessage,
        mailNotificationOnTeamMember: $mailNotificationOnTeamMember,
        mailNotificationOnPosts: $mailNotificationOnPosts,
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_CLIENTS}
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_STATE = gql`
  mutation (
    $id:ID!
    $state:Int
  ) {
    updateUsersPermissionsUser(
      id: $id,
      data: {
        
        state:$state
     
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_CLIENTS}
        }
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation (
    $id:ID!
    $delete:Date
    $blocked : Boolean
    $mailNotificationOnClubDeal: Boolean,
    $mailNotificationOnComment: Boolean,
    $mailNotificationOnMessage: Boolean,
    $mailNotificationOnTeamMember: Boolean,
    $mailNotificationOnPosts: Boolean,
    $pushToken:String
  ) {
    updateUsersPermissionsUser(
      id: $id,
      data: {
        delete:$delete
        blocked:$blocked
        mailNotificationOnClubDeal: $mailNotificationOnClubDeal,
        mailNotificationOnComment: $mailNotificationOnComment,
        mailNotificationOnMessage: $mailNotificationOnMessage,
        mailNotificationOnTeamMember: $mailNotificationOnTeamMember,
        mailNotificationOnPosts: $mailNotificationOnPosts,
        pushToken: $pushToken,
     
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_CLIENTS}
        }
      }
    }
  }
`;
