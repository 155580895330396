import { gql } from "@apollo/client";
import { ATTRIBUTES_ADVERTISES } from "../Attributes/Ads";

export const UPDATE_ADS_STATE = gql`
  mutation (
    $id:ID!
    $state:Int
  ) {
    updateAdvertise(
      id: $id,
      data: {
        
        state:$state
     
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_ADVERTISES}
        }
      }
    }
  }
`;

export const UPDATE_AD = gql`
  mutation (
    $id: ID!
    $tagline: String
    $link: String
    $companyName: String
    $duration: Int
    $contactPerson: String
    $position: String
    $state: Int
    $clubdealAd: ID
    $homeFeedAd: ID
    $sideAd: ID
    $profileNetworkAd: ID
    $email: String
    $phoneNumber: String
    $postalCode: String
  ) {
    updateAdvertise(
      id: $id
      data: {
        tagline: $tagline
        link: $link
        companyName: $companyName
        duration: $duration
        contactPerson: $contactPerson
        position: $position
        state: $state
        clubdealAd: $clubdealAd
        homeFeedAd: $homeFeedAd
        sideAd: $sideAd
        profileNetworkAd: $profileNetworkAd
        phoneNumber: $phoneNumber
        email: $email
        postalCode: $postalCode
      }
    ) {
      data {
        id
        attributes {
          tagline
          link
          companyName
          duration
          contactPerson
          position
          state
          clubdealAd {
            data {
              id
              attributes {
                url
              }
            }
          }
          homeFeedAd {
            data {
              id
              attributes {
                url
              }
            }
          }
          sideAd {
            data {
              id
              attributes {
                url
              }
            }
          }
          profileNetworkAd {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
