import { gql } from "@apollo/client";
import { ATTRIBUTES_COMPANY } from "../Attributes/Company";

export const CREATE_COMPANY = gql`
  mutation (
    $companyName: String
    $website: String
    $aboutUs: String
    $companyType: Int
    $assetsUnderManagement: Int
    $fullAddress: ID
    $logo:ID
  ) {
    createCompany(
      data: {
        companyName: $companyName
        website: $website
        aboutUs: $aboutUs
        companyType: $companyType
        assetsUnderManagement: $assetsUnderManagement
        fullAddress: $fullAddress
        logo:$logo
      } 
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_COMPANY}
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation (
    $id:ID!
    $companyName: String
    $website: String
    $aboutUs: String
    $companyType: Int
    $assetsUnderManagement: Int
    $fullAddress: ID
    $logo:ID
  ) {
    updateCompany(
      id: $id,
      data: {
        companyName: $companyName
        website: $website
        aboutUs: $aboutUs
        companyType: $companyType
        assetsUnderManagement: $assetsUnderManagement
        fullAddress: $fullAddress
        logo:$logo
      } 
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_COMPANY}
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_STATE = gql`
  mutation (
    $id:ID!
    $state:Int
  ) {
    updateCompany(
      id: $id,
      data: {
        
        state:$state
     
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_COMPANY}
        }
      }
    }
  }
`;
