const initialState = {
  openGlobal: false,
  content: null,
};

const modalReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_GLOBAL_MODAL":
      return {
        ...state,
        openGlobal: true,
        content: payload,
      };
    case "CLOSE_GLOBAL_MODAL":
      return {
        ...state,
        openGlobal: false,
        content: null,
      };

    default:
      return state;
  }
};

export default modalReducer;
