export const ATTRIBUTES_COMPANY = `
companyName
website
companyType
assetsUnderManagement
aboutUs
createdAt
users {
    data {
        id
    }
}
state
logo{
    data{
        id
        attributes{
            url
        }
    }
}
fullAddress {
    data {
        id
        attributes{
            address
            zipCode
            country{
                data{
                id
                attributes{
                    countryName 

                }
                }
            }
        }
    }
}
`;
