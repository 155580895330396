import React from "react";
import { useDispatch } from "react-redux";
import Text from "../Common/Text";
import { openModal } from "../../REDUX/actions/modal";
import DealDetails from "./DealDetails";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import CercledButton from "../Common/Button/CercledButton";

import { openConfirmationModal } from "../../REDUX/actions/ConfirmationModal";
import { UpdateDealState } from "../../REDUX/actions/deals";
import Switch from "../Common/Button/Switch";
const DealItem = ({ item, index }) => {
  const dispatch = useDispatch();

  const updateState = () => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(
            UpdateDealState(item.attributes.state === 1 ? 2 : 1, item.id)
          ),

        message: `Would you like to ${
          item.attributes.state === 1 ? "disactivate" : "activate"
        } this deal ?`,
        message2: `${item.attributes.tagline}
            `,
        confirmButtonText: "Submit",
        cancelButtonText: "Annuler",
      })
    );
  };

  return (
    <div
      className={`container-fluid w100 ${index % 2 === 0 ? "bg-darkGrey" : ""}`}
    >
      <div className="row">
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular bold"}>{item.attributes.tagline}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.dealSize}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.commitment}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>
            {item.attributes.minInvestement}
          </Text>
        </div>
        <div className="alignC bordered eltList col-2 py-3">
          {item.attributes.user?.data ? (
            <Text className={"f14 regular text-nowrap text-center"}>
              {item.attributes.user?.data?.attributes?.profileType === 1
                ? "Professional"
                : "Investor"}
              -{item.attributes?.user?.data?.id} <br />
              <strong>
                {item.attributes.user?.data?.attributes?.lastName}{" "}
                {item.attributes.user?.data?.attributes?.firstName} <br />
                {item.attributes.user?.data?.attributes?.email}
              </strong>
            </Text>
          ) : (
            <div>utilisateur supprimé</div>
          )}
        </div>
        <div className="alignC bordered eltList col-4 py-3">
          <Text className={"f14 regular "}>{item.attributes.description}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <CercledButton
            iconColor={"black"}
            icon={faEye}
            bgColor={"white"}
            callback={() =>
              dispatch(openModal(<DealDetails dealId={item.id} />))
            }
          />
          <Switch
            value={item?.attributes.state !== 2}
            callBack={() => updateState(item)}
          />
        </div>
      </div>
    </div>
  );
};

export default DealItem;
