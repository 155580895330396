import { gql } from "@apollo/client";
import { ATTRIBUTES_LANGUAGES } from "../Attributes/Languages";
export const GET_LANGUAGES = gql`
   {    
    languages   {
        data {
            id 
            attributes {
        ${ATTRIBUTES_LANGUAGES}
            }
        }
    }
  }
`;
