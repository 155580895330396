export const yearsOfExperience = [
  { label: "1 to 5 years", value: 0 },
  { label: "5 to 10 years", value: 1 },
  { label: "10 to 20 years", value: 2 },
  { label: "More than 20 years", value: 3 },
];

export const accountType = [
  { label: "Premium", value: 0 },
  { label: "Freemium", value: 1 },
];

export const assetsUnderManagement = [
  { label: "USD 100 million", value: 0 },
  { label: "USD 100 to 500 million", value: 1 },
  { label: "USD 500 to 1 billion", value: 2 },
  { label: "USD 1 to 5 billion", value: 3 },
  { label: "USD 5 to 10 billion", value: 4 },
  { label: "USD 10 billion +", value: 5 },
  { label: "No assets", value: 6 },
];

export const companyType = [
  { label: "Private bank", value: 0 },
  { label: "Online bank", value: 1 },
  { label: "Asset manager", value: 2 },
  { label: "Wealth manager", value: 3 },
  { label: "Independent financial advisor", value: 4 },
  { label: "Broker dealer", value: 5 },
  { label: "Roboadvisor", value: 6 },
  { label: "(Multi) family office", value: 7 },
  { label: "Online broker", value: 8 },
  { label: "Legal and tax advisor", value: 9 },
];

export const employeesNumber = [
  { label: "1 to 10", value: 0 },
  { label: "10 to 50", value: 1 },
  { label: "50 to 100", value: 2 },
  { label: "100 to 1000", value: 3 },
  { label: "1000 to 10000", value: 4 },
  { label: "More than 10000", value: 5 },
];

export const estimatedWealth = [
  { label: "USD 100000 to 500000", value: 0 },
  { label: "USD 500000 to 2 million", value: 1 },
  { label: "USD 2 to 5 million", value: 2 },
  { label: "USD 5 to 10 million", value: 3 },
  { label: "USD 10 to 25 million", value: 4 },
  { label: "More than usd 25 million", value: 5 },
];

export const annualIncome = [
  { label: "No income", value: 0 },
  { label: "USD 1 to 100000 ", value: 1 },
  { label: "USD 100000 to 300000", value: 2 },
  { label: "USD 300000 to 1 million", value: 3 },
  { label: "USD 1 to 5 million", value: 4 },
  { label: "More than usd 5 million", value: 5 },
];

export const assetsMixes = [
  { label: "cash", value: 0 },
  { label: "stoks_bonds_investment_funds ", value: 1 },
  { label: "property_assets", value: 2 },
  { label: "direct_investments", value: 3 },
  { label: "art_collections", value: 4 },
  { label: "cryptocurrencies", value: 5 },
];

export const wealthSource = [
  { label: "savings", value: 0 },
  { label: "direct_investments ", value: 1 },
  { label: "sale_of_business", value: 2 },
  { label: "property_sale", value: 3 },
  { label: "inheritance", value: 4 },
  { label: "donation", value: 5 },
  { label: "lottery_gaming", value: 6 },
  { label: "art_sports_contracts", value: 7 },
];

export const incomesSource = [
  { label: "salary", value: 0 },
  { label: "bonuses ", value: 1 },
  { label: "dividends_and_interests", value: 2 },
  { label: "directors_fee", value: 3 },
  { label: "direct_investments", value: 4 },
  { label: "property_rental", value: 5 },
  { label: "service_agreement_cachet", value: 6 },
  { label: "pensions", value: 7 },
  { label: "royalties", value: 8 },
  { label: "third_party_donations", value: 9 },
];

export const expertises = [
  { label: "wealth_management", value: 0 },
  { label: "portfolio_management ", value: 1 },
  { label: "real_estate", value: 2 },
  { label: "socially_responsible_investing", value: 3 },
  { label: "equity_markets", value: 4 },
  { label: "bonds", value: 5 },
  { label: "structed_products", value: 6 },
  { label: "derivates", value: 7 },
  { label: "options", value: 8 },
  { label: "long_only_funds", value: 9 },
  { label: "hedge_funds", value: 10 },
  { label: "private_equity", value: 11 },
  { label: "direct_investments", value: 12 },
  { label: "cryptocurrencies", value: 13 },
  { label: "art_collections", value: 14 },
  { label: "succession_planing", value: 15 },
  { label: "legal_and-tax", value: 16 },
];

export const durationData = [
  {
    label: "none",
    value: 0,
  },
  {
    label: "1 month",
    value: 1,
  },
  {
    label: "3 months",
    value: 3,
  },
  {
    label: "6 months",
    value: 6,
  },
];
