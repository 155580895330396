export const ATTRIBUTES_FEEDBACK = `
content
createdAt
user{
    data{
        id
        attributes{
            email
            profileType
            firstName
            lastName
        }
    }
}
`;
