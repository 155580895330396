import React, { useEffect, useState } from "react";

import "./Checkbox.css";

function Checkbox({
  label,
  description,
  value,
  onChange,
  name,
  id,
  bgColor = "lightGrey",
}) {
  const [checked, setChecked] = useState();

  const onCheckboxClicked = (newValue) => {
    setChecked(handleChange(checked));
    const e = { target: {} };
    e.target.name = name;
    e.target.value = handleChange(newValue);
    onChange && onChange(e);
  };

  const handleChange = (v) => {
    if (v === undefined) {
      return true;
    } else if (v === true) {
      return false;
    } else if (v === false) {
      return undefined;
    }
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div className="text-start w-100 d-flex align-items-center h49">
      <label
        htmlFor={id}
        className="d-flex align-items-center w-100 pointer f14 text-dark medium"
      >
        <div className="d-flex gap-3 f14 text-dark medium">{label}</div>
        <input
          id={id}
          type="checkbox"
          className="d-none"
          value={value}
          name={name}
          onChange={() => onCheckboxClicked(handleChange(checked))}
        />

        <span
          className={`${label ? "ms-auto" : ""} pof-checkbox bg-${bgColor} ${
            value === undefined ? "empty" : value === true ? "checked" : ""
          }`}
          role="checkbox"
          aria-checked={checked}
          onChange={() => onCheckboxClicked(handleChange(checked))}
        />
      </label>
      <p className="f12 text-darkGey mt-2 normal">{description}</p>
    </div>
  );
}

export default Checkbox;
