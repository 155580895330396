import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Common/Button/Button";
import Input from "../../Components/Common/Input";
import Loading from "../../Components/Common/Loading";
import Select from "../../Components/Common/Selects/Select";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import Text from "../../Components/Common/Text";
import Uploader from "../../Components/Common/Uploader";
import { getCompanyItem, updateCompany } from "../../REDUX/actions/company";
import { assetsUnderManagement, companyType } from "../../utils/SelectData";
import "./NewCompanyView.css";

const UpdateCompanyView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { company } = useSelector((state) => state.companiesReducer);
  const { selectedFile } = useSelector((state) => state.filesReducer);
  useEffect(() => {
    dispatch(getCompanyItem(params.id));
    // eslint-disable-next-line
  }, [params.id]);

  const [companyInput, setCompanyInput] = useState({
    companyName: undefined,
    website: undefined,
    addresse: undefined,
    country: undefined,
    zipCode: undefined,
    assetsUnderManagement: undefined,
    companyType: undefined,
    aboutUs: undefined,
    logo: undefined,
  });

  useEffect(() => {
    setCompanyInput({
      companyName: company?.attributes.companyName,
      website: company?.attributes.website,
      addresse: company?.attributes.fullAddress.data?.attributes.address,
      country: company?.attributes.fullAddress.data?.attributes.country.data.id,
      zipCode: company?.attributes.fullAddress.data?.attributes.zipCode,
      assetsUnderManagement: company?.attributes.assetsUnderManagement,
      companyType: company?.attributes.companyType,
      aboutUs: company?.attributes.aboutUs,
      logo: company?.attributes.logo.data?.attributes.url,
    });
  }, [company]);

  const handleSubmit = () => {
    dispatch(
      updateCompany(
        {
          ...companyInput,
          addressId: company.attributes.fullAddress.data.id,
          companyId: params.id,
        },
        { file: selectedFile }
      )
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };
  return !company ? (
    <Loading />
  ) : (
    <div className="container-fluid h6">
      <div className="d-flex mb-4 alignH">
        <Text className={"sBold f30 text-deepBlue"}>Update Company</Text>
      </div>
      <div className="border-0">
        <div className="d-md-flex my-5 ">
          <div className="col ms-2">
            <Input
              label={"Name"}
              setValue={setCompanyInput}
              champs="companyName"
              totalInputs={companyInput}
              value={companyInput.companyName}
            />
          </div>
          <div className="col ms-2">
            <Input
              label={"Website"}
              setValue={setCompanyInput}
              champs="website"
              totalInputs={companyInput}
              value={companyInput.website}
            />
          </div>
          <div className="col ms-2">
            <Input
              label={"Adresse"}
              setValue={setCompanyInput}
              champs="addresse"
              totalInputs={companyInput}
              value={companyInput.addresse}
            />
          </div>
        </div>
        <div className="d-md-flex my-5">
          <div className="col ms-2">
            <Text className={"xbold f12 mb-2"}>Country</Text>
            <SelectCountry
              champs="country"
              totalInputs={companyInput}
              value={companyInput.country}
              setValue={setCompanyInput}
            />
          </div>
          <div className="col ms-2">
            <Input
              label={"Zip Code"}
              type="number"
              setValue={setCompanyInput}
              value={companyInput.zipCode}
              champs="zipCode"
              totalInputs={companyInput}
            />
          </div>
          <div className="col ms-2">
            <Text className={"xbold f12 mb-2"}>Asset under management</Text>
            <Select
              list={assetsUnderManagement}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              val={companyInput.assetsUnderManagement}
              champs="assetsUnderManagement"
            />
          </div>
        </div>
        <div className="d-md-flex my-5 ">
          <div className="col ms-2">
            <Text className={"xbold f12 mb-2"}>Type of company</Text>
            <Select
              list={companyType}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              val={companyInput.companyType}
              champs="companyType"
            />
          </div>
          <div className="col ms-2">
            <Uploader
              label={"logo"}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              value={
                companyInput.logo
                  ? companyInput.logo.replace("/uploads/", "")
                  : companyInput.logo
              }
              champs="logo"
            />
          </div>
          <div className="col ms-2">
            <Text className={"xbold f12 mb-2"}>About Us:</Text>

            <textarea
              className=" transition02  aboutUsContainer f12  p-2  "
              rows="5"
              value={companyInput.aboutUs}
              onChange={(e) =>
                setCompanyInput({ ...companyInput, aboutUs: e.target.value })
              }
            />
          </div>
        </div>
        <div className="d-md-flex justify-content-end">
          <div className="d-flex  justify-content-end">
            <Button
              color={"blue"}
              className={"bg-white "}
              textButton="Cancel"
              callback={handleCancel}
            />
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton="Submit"
              callback={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCompanyView;
