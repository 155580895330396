import React from "react";
import "./Switch.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const Switch = ({ value, callBack }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip">
          {value ? "Disactivate" : "Activate"}
        </Tooltip>
      }
    >
      <div
        onClick={callBack}
        className={`pof-switch-checkbox alignC ${
          value ? "checkedBox" : "uncheckedBox"
        }`}
      >
        <div
          className={`checkboxInner ${
            value ? "checkedInner" : "uncheckedInner"
          }`}
        ></div>
      </div>
    </OverlayTrigger>
  );
};
export default Switch;
