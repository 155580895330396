const initialState = {
  sortField: "",
};

const generalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_SORT_FIELD":
      return { ...state, sortField: payload };
    default:
      return state;
  }
};
export default generalReducer;
