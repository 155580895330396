import { gql } from "@apollo/client";
import { ATTRIBUTES_DEAL } from "../Attributes/Deal";

export const UPDATE_DEAL_STATE = gql`
  mutation (
    $id:ID!
    $state:Int
  ) {
    updateClubDeal(
      id: $id,
      data: {
        
        state:$state
     
      }
    ) {
      data {
        id
        attributes{
          ${ATTRIBUTES_DEAL}
        }
      }
    }
  }
`;

export const DELETE_CLUBDEAL = gql`
  mutation deleteClubdealMutation($id: ID!) {
    deleteClubDeal(id: $id) {
      data {
        id
      }
    }
  }
`;
