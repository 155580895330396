import React, { useEffect, useState } from "react";
import useResponsive from "../../utils/useResponsive";
import Text from "../Common/Text";

const AssetsUnderManagement = ({ stats, deals }) => {
  const isMobileSize = useResponsive();
  const [totalAssets, settotalAssets] = useState(0);
  const [wealth, setwealth] = useState(0);
  useEffect(() => {
    settotalAssets(
      stats.aum0 * 0 +
        stats.aum1 * 50000000 +
        stats.aum2 * 300000000 +
        stats.aum3 * 750000000 +
        stats.aum4 * 3000000000 +
        stats.aum5 * 7500000000 +
        stats.aum6 * 10000000000
    );
  }, [stats]);

  useEffect(() => {
    setwealth(
      stats.wealth0 * 300000 +
        stats.wealth1 * 1250000 +
        stats.wealth2 * 3500000 +
        stats.wealth3 * 7500000 +
        stats.wealth4 * 17500000 +
        stats.wealth5 * 25000000
    );
  }, [stats]);

  return (
    <div
      className={` shadow p-3 bg-lightBlue2 rounded-20 text-white h100   ${
        isMobileSize ? "w-100" : "w100"
      } `}
    >
      <Text className={"bold f20 mt-2"}>Total users : {stats.allUsers}</Text>
      <Text className={"bold f20 mt-2"}>
        Total Professionals : {stats.professionals}
      </Text>
      <Text className={"bold f20 mt-2"}>
        Total Investors : {stats.investors}
      </Text>
      <div className="Separator my-4"></div>
      <Text className={"bold f20 mt-2"}>
        Professional Assets Under Management
      </Text>
      <Text className={"medium f20 mt-2"}> Total assets:</Text>
      <Text className={"regular f16 mt-2"}>
        {new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
        }).format(totalAssets)}
      </Text>
      <Text className={"medium f20 mt-2"}>Average asset per member:</Text>
      <Text className={"regular f16 mt-2"}>
        {new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
        }).format(Math.round(totalAssets / stats.professionals))}
      </Text>
      <div className="Separator my-4"></div>
      <Text className={"bold f20 mt-2"}>Investors Wealth</Text>
      <Text className={"medium f20 mt-2"}> Total wealth:</Text>
      <Text className={"regular f16 mt-2"}>
        {new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
        }).format(wealth)}
      </Text>
      <Text className={"medium f20 mt-2"}>Average wealth per member:</Text>
      <Text className={"regular f16 mt-2"}>
        {new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
        }).format(Math.round(wealth / stats.investors))}
      </Text>
    </div>
  );
};

export default AssetsUnderManagement;
