import React from "react";

const EmptyList = ({ text }) => {
  return (
    <div className=" alignC emptyContainer ">
      <div className={` f20 bold text-blue`}>{text}</div>
    </div>
  );
};

export default EmptyList;
