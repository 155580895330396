import React from "react";
import { formatDate } from "../../utils/utils";
import Text from "../Common/Text";

const FeedBackItem = ({ item, index }) => {
  return (
    <div
      className={`container-fluid w100 ${index % 2 === 0 ? "bg-darkGrey" : ""}`}
    >
      <div className="row">
        <div className="alignC bordered eltList col-1 py-3">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>
        <div className="alignC bordered eltList col-1 py-3">
          <Text className={"f14 regular "}>
            {formatDate(item.attributes.createdAt)}
          </Text>
        </div>
        <div className="alignC bordered eltList col-3 py-3">
          <Text className={"f14 regular text-nowrap text-center"}>
            {item.attributes.user?.data?.attributes?.profileType === 1
              ? "Professional"
              : "Investor"}
            -{item.attributes.user.data.id} <br />
            <strong>
              {item.attributes.user?.data?.attributes?.lastName}{" "}
              {item.attributes.user?.data?.attributes?.firstName} <br />
              {item.attributes.user?.data?.attributes?.email}
            </strong>
          </Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.content}</Text>
        </div>
      </div>
    </div>
  );
};

export default FeedBackItem;
