import React from "react";
import "./profileLink.css";
function ProfileLink({ profilePic, fullName, email }) {
  return (
    <div className="profileLink d-flex align-items-center p-2 br10 pointer position-relative">
      <div className="connectedSign position-absolute bg-green rounded-circle" />
      <img
        src={profilePic}
        alt="profile"
        width="43px"
        height="43px"
        className="rounded-circle me-3"
      />
      <div>
        <p className="mb-0 f12 bold text-blue">{String(fullName)}</p>
        <p className="mb-0 f12 text-blue">{String(email)}</p>
      </div>
    </div>
  );
}

export default ProfileLink;
