import React from "react";
import { useDispatch } from "react-redux";
import Text from "../Common/Text";
import { openModal } from "../../REDUX/actions/modal";
import AdsDetails from "./AdsDetails";
import CercledButton from "../Common/Button/CercledButton";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Switch from "../Common/Button/Switch";
import { UpdateAdsState } from "../../REDUX/actions/Ads";
import {
  closeConfirmationModal,
  openConfirmationModal,
} from "../../REDUX/actions/ConfirmationModal";
import { formatDate } from "../../utils/utils";

const AdsItem = ({ item, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const nav = (item) => {
    navigate(`/ads/${item.id}`);
  };
  const updateState = (advertise) => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(
            UpdateAdsState(
              advertise?.attributes.state === 1 ? 2 : 1,
              advertise?.id
            )
          ),
        message: `Would you like to ${
          advertise?.attributes.state === 2 ? "activate" : "disactivate"
        } this  advertise ?`,
        message2: `${advertise?.attributes.tagline} `,
        confirmButtonText: `${
          advertise?.attributes.state === 2 ? "Activate" : "Disactivate"
        } `,
        cancelButtonText: "Cancel",
        cancelCallback: () => dispatch(closeConfirmationModal()),
      })
    );
  };

  return (
    <div
      className={`container-fluid w100 ${index % 2 === 0 ? "bg-darkGrey" : ""}`}
    >
      <div className="row">
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.tagline}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.companyName}</Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>
            {formatDate(item.attributes.createdAt)}
          </Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>
            {formatDate(item.attributes.startingDay)}
          </Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          <Text className={"f14 regular "}>{item.attributes.duration}</Text>
        </div>
        <div className="alignC bordered eltList col-3 py-3">
          <Text className={"f14 regular "}>
            <a href={item.attributes.link} target="_blank" rel="noreferrer">
              {item.attributes.link}
            </a>
          </Text>
        </div>
        <div className="alignC bordered eltList col-1 py-3">
          <Text className={"f14 regular "}>
            {item.attributes.numberDisplays}
          </Text>
        </div>
        <div className="alignC bordered eltList col py-3">
          {item.attributes.clicks.data.length}
        </div>
        <div className="alignC bordered eltList col py-3">
          <CercledButton
            iconColor={"black"}
            icon={faEye}
            bgColor={"white"}
            callback={() => dispatch(openModal(<AdsDetails adId={item.id} />))}
          />
          <CercledButton
            iconColor={"white"}
            icon={faPenToSquare}
            bgColor={"blue"}
            callback={() => nav(item)}
          />
          <Switch
            value={item?.attributes.state !== 2}
            callBack={() => updateState(item)}
          />
        </div>
      </div>
    </div>
  );
};

export default AdsItem;
