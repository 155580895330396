const initialState = {
  feedbacks: [],
  feedbacksLoading: false,
};

const feedbacksReducer = (
  state = initialState,
  { type, payload, totalPages, page }
) => {
  switch (type) {
    case "FEEDBACKS_LOADING":
      return { ...state, feedbacksLoading: true };
    case "FETCH_FEEDBACKS":
      return {
        ...state,
        feedbacks: payload,
        feedbacksLoading: false,
        totalPages: totalPages,
        pageNumber: page,
      };
    default:
      return state;
  }
};
export default feedbacksReducer;
