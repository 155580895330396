import { gql } from "@apollo/client";
import { ATTRIBUTES_CLIENTS } from "../Attributes/Clients";
export const GET_ADMINS = gql`  {    
   usersPermissionsUsers (filters: { role: { name: { eq: "admin" } } } pagination: { page: 1, pageSize: 10000 })  {
        data {
            id 
            attributes {
        ${ATTRIBUTES_CLIENTS}
            }
        }
    }
  }`;
export const GET_CLIENTS = gql`
   {    
   usersPermissionsUsers (pagination: { page: 1, pageSize: 10000 })  {
        data {
            id 
            attributes {
        ${ATTRIBUTES_CLIENTS}
            }
        }
    }
  }
`;
export const fetchProfessionalClients = (
  type = 0,
  page = 1,
  pageSize = 20,
  searchInvestors = {},
  sortingOptions = ""
) => {
  // role: {
  //   name: {
  //     ne: "admin"
  //   }
  // },
  const {
    email,
    firstName,
    lastName,
    id,
    country,
    status,
    state,
    premium,
    emailConfirmed,
    connectedApp,
    connectedSite,
    moneeverseToken,
    wealth,
  } = searchInvestors;
  return gql`
    {
      usersPermissionsUsers(
        filters: { 
          profileType: { 
            eq: ${type} 
          },
          ${email ? `email: { containsi: "${email}" }` : ""},
          ${firstName ? `firstName: { containsi: "${firstName}" }` : ""},
          ${lastName ? `lastName: { containsi: "${lastName}" }` : ""},
          ${id ? `id: { containsi: "${id}" }` : ""},
          ${country ? `country: {id: { containsi: "${country}" }}` : ""},
          ${status ? `status: { eqi: ${status} }` : ""},
          ${wealth ? `wealth: { eqi: ${wealth} }` : ""},
          ${
            state === true
              ? `state: { eqi: 1 }`
              : state === false
              ? `state: { eqi: 2 }`
              : ""
          },
          ${premium !== undefined ? `premium: { eqi: ${premium} }` : ""},
          ${
            emailConfirmed !== undefined
              ? `emailConfirmed: { eqi: ${emailConfirmed} }`
              : ""
          },
          ${
            connectedApp !== undefined
              ? `connectedApp: { eqi: ${connectedApp} }`
              : ""
          },
          ${
            connectedSite !== undefined
              ? `connectedSite: { eqi: ${connectedSite} }`
              : ""
          },
          ${
            moneeverseToken === true
              ? `moneeverseToken: { ne: null }`
              : moneeverseToken === false
              ? `moneeverseToken: { eq: null }`
              : ""
          },
      },
        pagination: { page: ${page ?? 1}, pageSize: 50 }
        ${sortingOptions}
      ){
          meta {
            pagination {
                total
                pageCount
                page
                    }
              }
            data{
              id
              attributes{
                  ${ATTRIBUTES_CLIENTS}
              }
            }
      }
    }
  `;
};

export const GET_CLIENTS_BY_ID = gql`
 query($id:ID!){
    usersPermissionsUser(id: $id)  {
    data {
      id
      attributes {
        ${ATTRIBUTES_CLIENTS}
      }
    }
  }
}
`;
