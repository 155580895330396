import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../Components/Common/Input";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import SelectPhoneNumber from "../../Components/Common/Selects/SelectPhoneNumber";
import Text from "../../Components/Common/Text";
import Button from "../../Components/Common/Button/Button";

import Uploader from "../../Components/Common/Uploader";
import { useNavigate } from "react-router-dom";
import { CreateClient } from "../../REDUX/actions/clients";
import MultiSelect from "../../Components/Common/Selects/MultiSelect";
import useResponsive from "../../utils/useResponsive";

const NewInvestorView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [InvestorInput, setInvestorInput] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    password: "",
    phoneNumber: 0,
    country: null,
    premium: 0,
    profileType: 1,
    photo: "",
    coverPicture: "",
    languages: [],
    state: 0,
  });
  const { selectedFile } = useSelector((state) => state.filesReducer);
  const { languages } = useSelector((state) => state.languagesReducer);

  const handleSubmit = () => {
    dispatch(CreateClient(InvestorInput, { file: selectedFile }));
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const isMobileSize = useResponsive();
  return (
    <div className="container-fluid ">
      <div className="d-flex mb-4 alignH">
        <Text className={"sBold f30 text-deepBlue"}>New Investor</Text>
      </div>
      <div className=" w-100 ">
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
          <div className="col m-2">
            <Input
              label={"Username"}
              champs="username"
              required
              totalInputs={InvestorInput}
              value={InvestorInput.username}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"First name"}
              required
              champs="firstName"
              totalInputs={InvestorInput}
              value={InvestorInput.firstName}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Last name"}
              champs="lastName"
              required
              totalInputs={InvestorInput}
              value={InvestorInput.lastName}
              setValue={setInvestorInput}
            />
          </div>
        </div>
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
          <div className="col m-2">
            <Input
              label={"Email"}
              champs="email"
              required
              totalInputs={InvestorInput}
              value={InvestorInput.email}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Password"}
              champs="password"
              required
              totalInputs={InvestorInput}
              value={InvestorInput.password}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Job Title"}
              champs="jobTitle"
              required
              totalInputs={InvestorInput}
              value={InvestorInput.jobTitle}
              setValue={setInvestorInput}
            />
          </div>
        </div>
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
          <div className="col m-2">
            <SelectPhoneNumber
              label={"Phone Number"}
              required
              champs="phoneNumber"
              totalInputs={InvestorInput}
              value={InvestorInput.phoneNumber}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>Country</Text>
            <SelectCountry
              champs="country"
              totalInputs={InvestorInput}
              value={InvestorInput.country}
              setValue={setInvestorInput}
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>Languages</Text>
            <MultiSelect
              list={languages}
              champs="languages"
              totalInputs={InvestorInput}
              setValue={setInvestorInput}
              value={InvestorInput.languages}
            />
          </div>
        </div>
        {/* <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
          <div className={`${isMobileSize ? "" : "w40"} m-2`}>
            <Uploader
              label={"Photo"}
              totalInputs={InvestorInput}
              setValue={setInvestorInput}
              value={InvestorInput.photo}
              champs="photo"
            />
          </div>
          <div className={`${isMobileSize ? "" : "w40"} m-2`}>
            <Uploader
              label={"Cover Picture"}
              totalInputs={InvestorInput}
              setValue={setInvestorInput}
              value={InvestorInput.coverPicture}
              champs="coverPicture"
            />
          </div>
          <div
            className={` ${
              isMobileSize ? "d-flex" : "w20 d-md-block d-lg-flex"
            } justify-content-end mt-4 `}
          >
            <Button
              color={"blue"}
              className={"bg-white "}
              textButton="Cancel"
              callback={handleCancel}
            />
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton="Submit"
              disabled={
                !InvestorInput.firstName ||
                !InvestorInput.lastName ||
                !InvestorInput.jobTitle ||
                !validMail(InvestorInput.email) ||
                !InvestorInput.password ||
                InvestorInput.phoneNumber.length < 8
              }
              callback={handleSubmit}
            />
          </div>
        </div> */}
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"} `}>
          <div className={`${isMobileSize ? "" : "w40"} m-2`}>
            <Uploader
              label={"Photo"}
              totalInputs={InvestorInput}
              setValue={InvestorInput}
              value={
                InvestorInput.photo
                  ? InvestorInput.photo.replace("/uploads/", "")
                  : InvestorInput.photo
              }
              champs="photo"
            />
          </div>
          <div className={`${isMobileSize ? "" : "w40"} m-2`}>
            <Uploader
              label={"Cover Picture"}
              totalInputs={InvestorInput}
              setValue={setInvestorInput}
              value={
                InvestorInput.coverPicture
                  ? InvestorInput.coverPicture.replace("/uploads/", "")
                  : InvestorInput.coverPicture
              }
              champs="coverPicture"
            />
          </div>
          <div
            className={` ${
              isMobileSize ? "d-flex" : "w20 d-md-block d-lg-flex"
            } justify-content-end mt-4 `}
          >
            <Button
              color={"blue"}
              className={"bg-white "}
              textButton="Cancel"
              callback={handleCancel}
            />
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton="Submit"
              callback={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvestorView;
