import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useResponsive from "../../../utils/useResponsive";
import "./Button.css";
const Button = ({
  color,
  callback = () => {},
  icon,
  bgColor,
  textButton,
  className,
  disabled,
}) => {
  const bg = disabled ? "grey" : bgColor;
  const textColor = disabled ? "blue" : color;

  const isMobileSize = useResponsive();
  return (
    <button
      disabled={disabled}
      className={`${className}   alignC  px-4 m-1 bg-${bg} coloredButton text-${textColor}  alignC `}
      onClick={callback}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} className={isMobileSize ? "" : "me-3 "} />
      ) : null}
      {textButton}
    </button>
  );
};

export default Button;
