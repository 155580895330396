import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../REDUX/actions/modal";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CommonModal = () => {
  const dispatch = useDispatch();
  const { openGlobal, content } = useSelector((state) => state.modalReducer);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal show={openGlobal} onHide={handleClose} size={"xl"} centered>
      <FontAwesomeIcon
        icon={faXmark}
        className={"pointer bg-white p-2 m-2 text-blue align-self-end "}
        size={"xl"}
        onClick={handleClose}
      />

      {content}
    </Modal>
  );
};

export default CommonModal;
