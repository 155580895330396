import { gql } from "@apollo/client";
import { ATTRIBUTES_COUNTRIES } from "../Attributes/Country";

export const GET_COUNTRIES = gql`
   {    
   countries(pagination:{page:1,pageSize:250})   {
        data {
            id 
            attributes {
        ${ATTRIBUTES_COUNTRIES}
            }
        }
    }
  }
`;
