import { combineReducers } from "redux";
import { usersReducer } from "./users.js";
import modalReducer from "./modal.js";
import clientsReducer from "./clients.js";
import companiesReducer from "./company";
import feedbacksReducer from "./feedback.js";
import countriesReducer from "./country.js";
import dealsReducer from "./deal.js";
import advertisesReducer from "./ads.js";
import filesReducer from "./files.js";
import toastMessagesReducer from "./toastMessage.js";
import generalReducer from "./general.js";
import languagesReducer from "./languages.js";
import confirmationModalReducer from "./confirmationModal";
import statsReducer from "./stats";
const rootReducer = combineReducers({
  usersReducer,
  modalReducer,
  statsReducer,
  clientsReducer,
  companiesReducer,
  feedbacksReducer,
  countriesReducer,
  dealsReducer,
  advertisesReducer,
  filesReducer,
  toastMessagesReducer,
  generalReducer,
  languagesReducer,
  confirmationModalReducer,
});
export default rootReducer;
