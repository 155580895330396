import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_COMPANY_STATE,
} from "../Graphql/Mutations/Company";
import {
  CREATE_FULL_ADDRESS,
  UPDATE_FULL_ADDRESS,
} from "../Graphql/Mutations/FullAddress";
import {
  getCompaniesQuery,
  GET_COMPANY_BY_ID,
} from "../Graphql/Queries/Company";
import client from "./Client";
import { uploadFile } from "./files";
import { handleInfo, handleError } from "./toastMessage";
export const getAllCompanies =
  (page = 1, pageSize = 20, search, sortingOptions = "") =>
  async (dispatch) => {
    dispatch({ type: "COMPANIES_LOADING" });

    try {
      const { data } = await client.query({
        query: getCompaniesQuery(page, pageSize, search, sortingOptions),
      });
      dispatch({
        type: "FETCH_COMPANIES",
        payload: data.companies.data,
        totalPages: data.companies.meta?.pagination?.pageCount,
        page: data.companies.meta?.pagination?.page,
        total: data.companies.meta?.pagination?.total,
      });
    } catch (error) {
      dispatch(handleError("Error try again later", 1));
    }
  };

export const CreateCompany =
  (newCompany, attachementFiles) => async (dispatch) => {
    dispatch({ type: "COMPANIES_LOADING" });

    const attachedFiles = await dispatch(uploadFile(attachementFiles));
    try {
      let fullAddress = await client.mutate({
        mutation: CREATE_FULL_ADDRESS,
        variables: {
          address: newCompany.addresse,
          zipCode: parseInt(newCompany.zipCode),
          country: newCompany.country,
        },
      });
      const { data } = await client.mutate({
        mutation: CREATE_COMPANY,
        variables: {
          companyName: newCompany.companyName,
          website: newCompany.website,
          aboutUs: newCompany.aboutUs,
          companyType: newCompany.companyType,
          assetsUnderManagement: newCompany.assetsUnderManagement,
          fullAddress: fullAddress.data.createFullAddress.data.id,
          logo: attachedFiles.data[0].id,
        },
      });
      dispatch({
        type: "CREATE_COMPANY",
        payload: data.createCompany.data,
      });
      dispatch(handleInfo("Company added", 1));
    } catch (error) {
      dispatch(handleError("Error try again later", 1));
    }
  };

export const getCompanyItem = (id) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_COMPANY_BY_ID(id),
    });

    dispatch({
      type: "FETCH_COMPANY_ITEM",
      payload: data.companies.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch(handleError("Error try again later", 1));
  }
};

export const updateCompany =
  (newCompany, attachementFiles) => async (dispatch) => {
    dispatch({ type: "COMPANIES_LOADING" });
    const attachedFiles = await dispatch(uploadFile(attachementFiles));
    try {
      let fullAddress = await client.mutate({
        mutation: UPDATE_FULL_ADDRESS,
        variables: {
          id: parseInt(newCompany.addressId),
          address: newCompany.addresse,
          zipCode: parseInt(newCompany.zipCode),
          country: newCompany.country,
        },
      });
      const { data } = await client.mutate({
        mutation: UPDATE_COMPANY,
        variables: {
          id: parseInt(newCompany.companyId),
          companyName: newCompany.companyName,
          website: newCompany.website,
          aboutUs: newCompany.aboutUs,
          companyType: newCompany.companyType,
          assetsUnderManagement: newCompany.assetsUnderManagement,
          fullAddress: fullAddress.data.updateFullAddress.data.id,
          logo: attachedFiles.data[0].id,
        },
      });
      dispatch({
        type: "UPDATE_COMPANY",
        payload: data.updateCompany.data,
      });
      dispatch(handleInfo("Company updated", 1));
    } catch (error) {
      console.log(error);
      dispatch(handleError("Error try again later", 1));
    }
  };

export const UpdateCompanyState = (state, companyId) => async (dispatch) => {
  dispatch({ type: "COMPANY_LOADING" });
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_COMPANY_STATE,
      variables: {
        id: parseInt(companyId),
        state: state,
      },
    });

    dispatch({
      type: "UPDATE_COMPANY",
      payload: data.updateCompany.data,
    });

    dispatch({
      type: "FETCH_COMPANY_ITEM",
      payload: data.updateCompany.data,
    });

    dispatch(handleInfo("Company state updated", 1));
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};
