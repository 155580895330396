import React, { useEffect, useState } from "react";
import { sortFunction } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./SelectCountry.css";
import Flag from "../Flag";
import { useSelector } from "react-redux";
const SelectCountry = ({
  totalInputs,
  setValue,
  champs,
  value,
  placeholder = "",
  height = 44,
}) => {
  const { countries } = useSelector((state) => state.countriesReducer);

  const retrieveCountry = (id) => {
    let country = countries.find((c) => c?.id === id);

    return countries.indexOf(country);
  };

  const [isListOpen, setIsListOpen] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    let ccountryIndex = retrieveCountry(value);
    setSelected(ccountryIndex);
    // eslint-disable-next-line
  }, [value]);

  const handleSelect = (index) => {
    setSelected(index);
    setValue({ ...totalInputs, [champs]: countries[index]?.id });
    setIsListOpen(false);
  };
  function handleBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsListOpen(false);
    }
  }

  return (
    <div
      className={`selectMenu   ${isListOpen && "listOpen"}`}
      tabIndex="-1"
      onBlur={handleBlur}
      style={{ height: `${height}px` }}
    >
      <div
        className={"selectBtn h-100"}
        onClick={() => setIsListOpen(!isListOpen)}
      >
        <div className=" f12">
          {countries[selected]?.attributes.countryName ?? placeholder}
        </div>
        <div className=" f12">
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`icon ${isListOpen && "listOpen"}`}
          />
        </div>
      </div>
      <ul className={`options   ${isListOpen && "listOpen"}`}>
        {sortFunction(countries, "countryName", true).map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => handleSelect(index)}
              className=" option  "
            >
              <Flag options={countries} index={index} />
              <span className=" f12 m-3 text-dark ">
                {item.attributes.countryName}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectCountry;
