import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Text from "../Components/Common/Text";
import FeedBackList from "../Components/FeedBack/FeedBackList";
import PagginationButton from "../Components/paginationButton/paginationButton";
import { getAllFeedBacks } from "../REDUX/actions/feedback";
import { BreadCrumb } from "../utils/BreadCrumbs";

const FeedbackView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [sortingOptions, setsortingOptions] = useState(`sort: "id:desc"`);

  const { totalPages, pageNumber, feedbacks } = useSelector(
    (state) => state.feedbacksReducer
  );

  useEffect(() => {
    dispatch(getAllFeedBacks(1, 20, undefined, sortingOptions));
    // eslint-disable-next-line
  }, [sortingOptions]);

  return (
    <div className="container-fluid h6">
      <div className="d-flex justify-content-between mb-4 alignH ">
        <Text className={"sBold f30 text-deepBlue "}>
          {BreadCrumb(location.pathname)}
        </Text>
      </div>

      <div className="alignC mb-3">
        <PagginationButton
          action={getAllFeedBacks}
          pages={totalPages}
          pageNumber={pageNumber}
          sortingOptions={sortingOptions}
          totalItems={feedbacks.length}
        />
      </div>
      <FeedBackList
        sortingOptions={sortingOptions}
        setsortingOptions={setsortingOptions}
      />
    </div>
  );
};

export default FeedbackView;
