import React from "react";
import Text from "../Common/Text";
import ColoredText from "../Common/ColoredText";
import { useDispatch } from "react-redux";
import { openConfirmationModal } from "../../REDUX/actions/ConfirmationModal";
import { UpdateClientState } from "../../REDUX/actions/clients";
import useResponsive from "../../utils/useResponsive";
import StateUpdateBtn from "../Common/StateUpdateBtn/StateUpdateBtn";
const AdminItem = ({ item }) => {
  const dispatch = useDispatch();
  const isMobileSize = useResponsive();
  return (
    <tr className="itemTr">
      <th scope="col">
        <div className="alignC">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>
      </th>
      <th scope="col">
        <div className="alignC">
          <Text className={"f14 regular "}>{item.attributes.username}</Text>
        </div>
      </th>
      <th scope="col">
        <div className="alignC">
          <Text className={"f14 regular "}>{item.attributes.email}</Text>
        </div>
      </th>

      {/* <th scope="col">
        <div className="alignC">
          <ColoredText
            className={`f14 w-75 alignC regular ${
              isMobileSize ? "p-2" : "p-3"
            } text-white medium rounded `}
          >
            {item.attributes.state === 1
              ? "active"
              : item.attributes.state === 2
              ? "inactive"
              : item.attributes.state === 0
              ? "pending"
              : null}
          </ColoredText>
        </div>
      </th>
      <th scope="col">
        <div className="alignC">
          {!item.attributes.state || item.attributes.state === 0 ? (
            <StateUpdateBtn
              //disabled={item.attributes.state ? false : true}
              callBack={() =>
                dispatch(
                  openConfirmationModal({
                    callBack: () => dispatch(UpdateClientState(1, item.id)),
                    message: `Would you like to update the state of this investor ?`,
                    message2: `${item.attributes.companyName} `,
                    confirmButtonText: "Activate",
                    cancelButtonText: "disactivate",
                    cancelCallback: () =>
                      dispatch(UpdateClientState(2, item.id)),
                  })
                )
              }
            />
          ) : (
            <StateUpdateBtn
              callBack={() =>
                dispatch(
                  openConfirmationModal({
                    callBack: () =>
                      dispatch(
                        UpdateClientState(
                          item.attributes.state === 1 ? 2 : 1,
                          item.id
                        )
                      ),
                    icon: "archive",
                    message: `Would you like to ${
                      item.attributes.state === 1 ? "disactivate" : "activate"
                    } this admin ?`,
                    message2: `${item.attributes.username} `,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                  })
                )
              }
            />
          )}
        </div>
      </th> */}
    </tr>
  );
};

export default AdminItem;
