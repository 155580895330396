import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../REDUX/actions/clients";
import EmptyList from "../Common/EmptyList";

import Loading from "../Common/Loading";
import Text from "../Common/Text";
import ProfessionalsItem from "./ProfessionalItem";

const ProfessionalsList = ({ sortingOptions, setsortingOptions, filters }) => {
  const dispatch = useDispatch();

  const { clients, clientsLoading } = useSelector(
    (state) => state.clientsReducer
  );

  const handleSort = (champs) => {
    let temparray = sortingOptions;
    temparray = `${champs}:${sortingOptions.includes("asc") ? "desc" : "asc"}`;
    setsortingOptions(temparray);
    dispatch(getUsers(0, 1, filters, temparray));
  };
  return (
    <div className="pb-5">
      <div className="container-fluid w100 pagginationContainer">
        <div className="row h44">
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("id")}
          >
            <Text className={" f14 medium me-1 pointer "}>ID</Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("firstName")}
          >
            <Text className={" f14 medium me-1 pointer"}>First Name</Text>{" "}
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("lastName")}
          >
            <Text className={" f14 medium me-1 pointer"}>Last Name</Text>{" "}
          </div>
          <div
            className="col-2 alignC bordered py-3"
            onClick={() => handleSort("email")}
          >
            <Text className={" f14 medium me-1 pointer"}>Email</Text>
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("createdAt")}
          >
            <Text className={" f14 medium me-1 pointer"}>Created At</Text>
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("country.id")}
          >
            <Text className={" f14 medium me-1 pointer"}>Country</Text>
          </div>
          <div
            className="col-1 alignC bordered py-3"
            onClick={() => handleSort("lastConnected")}
          >
            <Text className={" f14 medium me-1 pointer"}>Last Connected</Text>
          </div>

          <div className="col alignC bordered py-3">
            <Text className={"f14 medium me-1 pointer"}>State</Text>
          </div>
          <div className="col-1 alignC bordered py-3">
            <Text className={" f14 medium me-1 pointer"}>Actions</Text>
          </div>
        </div>
      </div>
      <div>
        {clientsLoading ? (
          <Loading />
        ) : !clientsLoading && !clients?.length > 0 ? (
          <EmptyList text={"No Professionals corresponding your search"} />
        ) : (
          clients?.map((item, index) => {
            return (
              <ProfessionalsItem
                item={item}
                key={index}
                index={index}
                handleSort={handleSort}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default ProfessionalsList;
