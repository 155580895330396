const initialState = {
  companies: [],
  companiesLoading: false,
  company: null,
  totalPages: 0,
  pageNumber: 1,
  totalCompanies: 0,
};

const companiesReducer = (
  state = initialState,
  { type, payload, totalPages, page, total }
) => {
  switch (type) {
    case "COMPANIES_LOADING":
      return { ...state, companiesLoading: true };
    case "CREATE_COMPANY":
      return {
        ...state,
        companies: [...state.companies, payload],
      };
    case "FETCH_COMPANIES":
      return {
        ...state,
        companies: payload,
        companiesLoading: false,
        totalPages: totalPages,
        pageNumber: page,
        totalCompanies: total,
      };
    case "UPDATE_COMPANY":
      return {
        ...state,
        companies: state.companies.map((company) =>
          company.id === payload.id ? payload : company
        ),
        companiesLoading: false,
      };
    case "FETCH_COMPANY_ITEM":
      return { ...state, company: payload };
    default:
      return state;
  }
};
export default companiesReducer;
