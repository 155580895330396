import API from "../../Api/Api";
import {
  CREATE_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_STATE,
} from "../Graphql/Mutations/Clients";
import { GET_CLIENTS_BY_ID, GET_ADMINS } from "../Graphql/Queries/Clients";
import client from "./Client";
import { closeBlockModal } from "./ConfirmationModal";
import { deleteClubdeal } from "./deals";
import { uploadUserFile } from "./files";
import { closeModal } from "./modal";
import { handleError, handleInfo } from "./toastMessage";

export const getAdmins = () => async (dispatch) => {
  dispatch({ type: "CLIENTS_LOADING" });
  try {
    const { data } = await client.query({
      query: GET_ADMINS,
    });
    dispatch({
      type: "FETCH_ADMINS",
      payload: data.usersPermissionsUsers.data,
    });
  } catch (error) {
    dispatch(handleError("Error try again later"));
  }
};

// get professionals :
export const getUsers =
  (type = 0, page = 1, filters = {}, sortingOptions = "id:asc") =>
  async (dispatch) => {
    dispatch({ type: "CLIENTS_LOADING" });
    try {
      const { data } = await API.post(
        "/api/users-permissions/user/getUsersAdmin",
        {
          type,
          page,
          ...filters,
          sortingOptions,
        }
      );
      if (type === 0) {
        dispatch({
          type: "FETCH_CLIENTS",
          payload: data.users,
          totalPages: data?.totalPages,
          page: data?.page,
          total: data?.count,
        });
      } else {
        dispatch({
          type: "FETCH_INVESTORS",
          payload: data.users,
          totalPages: data?.totalPages,
          page: data?.page,
          total: data?.count,
        });
      }
    } catch (error) {
      dispatch(handleError("Error try again later", 1));
    }
  };
// get Investors :

export const getClientById = (id) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_ID,
      variables: {
        id: id,
      },
    });

    dispatch({
      type: "FETCH_CLIENT_ITEM",
      payload: data?.usersPermissionsUser?.data,
    });
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};

export const CreateClient =
  (newClient, attachementFiles) => async (dispatch) => {
    dispatch({ type: "CLIENTS_LOADING" });
    try {
      const { data } = await client.mutate({
        mutation: CREATE_CLIENT,
        variables: {
          username: newClient.username,
          firstName: newClient.firstName,
          lastName: newClient.lastName,
          email: newClient.email,
          jobTitle: newClient.jobTitle,
          phoneNumber: newClient.phoneNumber,
          password: newClient.password,
          country: newClient.country,
          profileType: newClient.profileType,
          premium: newClient.premium === 0 ? true : false,
          languages: newClient.languages,
          state: newClient.state,
          role: newClient?.role,
        },
      });
      if (attachementFiles) {
        await dispatch(
          uploadUserFile(
            attachementFiles,
            data.createUsersPermissionsUser.data.id
          )
        );
      }

      dispatch({
        type: "CREATE_CLIENTS",
        payload: data.createUsersPermissionsUser.data,
      });

      if (newClient.confirm) {
        dispatch(closeModal());
      }
      dispatch(handleInfo("Client added", 1));
    } catch (error) {
      console.log(error);

      dispatch(handleError("Error try again later", 1));
    }
  };

export const UpdateClient =
  (newClient, attachementFiles, userId) => async (dispatch) => {
    dispatch({ type: "CLIENTS_LOADING" });
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_CLIENT,
        variables: {
          id: parseInt(newClient.userId),
          username: newClient.username,
          firstName: newClient.firstName,
          lastName: newClient.lastName,
          email: newClient.email,
          jobTitle: newClient.jobTitle,
          phoneNumber: newClient.phoneNumber,
          password: newClient.password,
          country: newClient.country,
          profileType: newClient.profileType,
          premium: newClient.premium === 0 ? true : false,
          languages: newClient.languages,
          mailNotificationOnClubDeal: newClient?.mailNotificationOnClubDeal,
          mailNotificationOnComment: newClient.mailNotificationOnComment,
          mailNotificationOnMessage: newClient.mailNotificationOnMessage,
          mailNotificationOnTeamMember: newClient.mailNotificationOnTeamMember,
          mailNotificationOnPosts: newClient.mailNotificationOnPosts,
        },
      });

      await dispatch(
        uploadUserFile(
          attachementFiles,
          data.updateUsersPermissionsUser.data.id
        )
      );
      dispatch({
        type: "UPDATE_CLIENT",
        payload: data.updateUsersPermissionsUser.data,
      });

      dispatch(handleInfo("Client updated", 1));
    } catch (error) {
      console.log(error);

      dispatch(handleError("Error try again later", 1));
    }
  };

export const UpdateClientState = (state, userId) => async (dispatch) => {
  dispatch({ type: "CLIENTS_LOADING" });
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_CLIENT_STATE,
      variables: {
        id: parseInt(userId),
        state: state,
      },
    });

    data?.updateUsersPermissionsUser?.data?.attributes?.profileType === 1
      ? dispatch({
          type: "UPDATE_INVESTOR",
          payload: data.updateUsersPermissionsUser.data,
        })
      : dispatch({
          type: "UPDATE_CLIENT",
          payload: data.updateUsersPermissionsUser.data,
        });

    dispatch({
      type: "FETCH_CLIENT_ITEM",
      payload: data.updateUsersPermissionsUser.data,
    });
    dispatch(handleInfo("Client state updated", 1));
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};

export const deleteUser =
  (userId, newUser, clubDeals, reason, profileType) => async (dispatch) => {
    dispatch({ type: "CLIENTS_DELETE_LOADING" });
    try {
      const { data } = await client.mutate({
        mutation: DELETE_CLIENT,
        variables: {
          id: parseInt(userId),
          delete: newUser,
          blocked: newUser ? true : false,
          mailNotificationOnClubDeal: newUser ? false : true,
          mailNotificationOnComment: newUser ? false : true,
          mailNotificationOnMessage: newUser ? false : true,
          mailNotificationOnTeamMember: newUser ? false : true,
          mailNotificationOnPosts: newUser ? false : true,
          pushToken: null,
        },
      });
      if (clubDeals && newUser) {
        for (const deal of clubDeals?.data) {
          dispatch(deleteClubdeal(deal.id));
        }
      }
      if (newUser) {
        const { data } = await API.post(
          "/api/users-permissions/notifyBlockedUser",
          {
            userId,
            reason,
            isAdmin: true,
          }
        );
      }
      dispatch({
        type:
          profileType === 1
            ? "DELETE_INVESTOR_SUCCESS"
            : "DELETE_CLIENT_SUCCESS",
        payload: data.updateUsersPermissionsUser.data,
      });
      dispatch(
        handleInfo(
          newUser
            ? "Client deleted successfully"
            : "Client activated successfully",
          1
        )
      );
      dispatch(closeBlockModal());
    } catch (error) {
      console.log(error);
      dispatch({
        type: "DELETE_CLIENT_FAILED",
        payload: error,
      });
      dispatch(handleError("Error try again later", 1));
    }
  };
