export const API_END_POINT = "https://47x3sr3rtf29j9-api.dev/";
export const GRAPHQL_END_POINT = "https://47x3sr3rtf29j9-api.dev/graphql/";

// export const API_END_POINT = "https://api.planetoffinance.labo-linkibe.fr";
// export const GRAPHQL_END_POINT =
//   "https://api.planetoffinance.labo-linkibe.fr/graphql/";

// export const API_END_POINT = "https://pprod.47x3sr3rtf29j9-api.dev/";
// export const GRAPHQL_END_POINT =
//   "https://pprod.47x3sr3rtf29j9-api.dev/graphql/";

// export const API_END_POINT = "http://localhost:1337";
// export const GRAPHQL_END_POINT = "http://localhost:1337/graphql/";
