import React, { useEffect } from "react";
import Text from "../Common/Text";
import { assetsUnderManagement, companyType } from "../../utils/SelectData";
import { API_END_POINT } from "../../Api/EndPoints";
import "./company.css";
import { getCompanyItem } from "../../REDUX/actions/company";
import { useDispatch, useSelector } from "react-redux";
const CompanyDetails = ({ companyId }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companiesReducer);
  useEffect(() => {
    dispatch(getCompanyItem(companyId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid ps-4 pe-4 pb-4">
      <div className="logo">
        <img
          src={
            company?.attributes?.logo.data
              ? API_END_POINT + company?.attributes?.logo.data.attributes.url
              : process.env.PUBLIC_URL + "/default.jpg"
          }
          alt=""
        />
      </div>
      <div>
        <Text className={"f20 medium mb-3"}>Company Information</Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Name: </Text>
        <Text className={"col f16 regular "}>
          {company?.attributes?.companyName}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Company Type: </Text>
        <Text className={"col f18 regular "}>
          {
            companyType.find(
              (com) => com.value === company?.attributes?.companyType
            )?.label
          }
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Website: </Text>
        <Text className={"col f18 regular "}>
          {company?.attributes?.website}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Assets Under Management: </Text>
        <Text className={"col f18 regular "}>
          {
            assetsUnderManagement.find(
              (asset) =>
                asset.value === company?.attributes?.assetsUnderManagement
            )?.label
          }
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Hq City: </Text>
        <Text className={"col f18 regular "}>
          {company?.attributes?.fullAddress?.data?.attributes?.address}
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Hq Country: </Text>
        <Text className={"col f18 regular "}>
          {
            company?.attributes?.fullAddress?.data?.attributes?.country?.data
              .attributes.countryName
          }
        </Text>
      </div>
      <div className="row">
        <Text className={"col f16 medium "}>Hq zip: </Text>
        <Text className={"col f18 regular "}>
          {company?.attributes?.fullAddress?.data?.attributes?.zipCode}
        </Text>
      </div>
    </div>
  );
};

export default CompanyDetails;
