const initialState = {
  user: null,
  userLoading: false,
  error: null,
  allUsers: [],
  redirect: null,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload, userLoading: false };
    case "REDIRECT":
      return { ...state, redirect: action.payload };
    case "SUCCESS_AUTH":
      return { ...state, user: action.payload, userLoading: false };
    case "FETCH_USERS":
      return { ...state, allUsers: action.payload, userLoading: false };
    case "USERS_LOADING":
      return { ...state, userLoading: true, error: null };
    case "USER_ERROR":
      return { ...state, userLoading: false, error: action.payload };
    case "UNAUTHORIZED":
      return { ...state, userLoading: false, user: {}, error: null };
    default:
      return state;
  }
};
