const initialState = {
  clients: [],
  client: null,
  clientsLoading: false,
  clientDeleteLoading: false,
  totalProfessionalsPages: 0,
  pageNumber: 1,
  pageNumberInvestor: 1,
  admins: [],
  investors: [],
  totalInvestorsPages: 0,
  totalPros: 0,
  totalInvs: 0,
  error: null,
};

const clientsReducer = (
  state = initialState,
  { type, payload, totalPages, page, total }
) => {
  switch (type) {
    case "CLIENTS_LOADING":
      return { ...state, clientsLoading: true };
    case "CLIENTS_DELETE_LOADING":
      return { ...state, clientDeleteLoading: true };
    case "FETCH_CLIENTS":
      return {
        ...state,
        clients: payload,
        clientsLoading: false,
        totalProfessionalsPages: totalPages,
        pageNumber: page,
        totalPros: total,
      };
    case "FETCH_INVESTORS":
      return {
        ...state,
        investors: payload,
        clientsLoading: false,
        totalInvestorsPages: totalPages,
        pageNumberInvestor: page,
        totalInvs: total,
      };
    case "FETCH_ADMINS":
      return {
        ...state,
        admins: payload,
        clientsLoading: false,
      };
    case "CREATE_CLIENTS":
      return {
        ...state,
        clients: [...state.clients, payload],
        clientsLoading: false,
      };
    case "UPDATE_CLIENT":
      return {
        ...state,
        clients: state.clients.map((client) =>
          client.id === payload.id ? payload : client
        ),
        clientsLoading: false,
      };
    case "DELETE_CLIENT_SUCCESS":
      return {
        ...state,
        client: payload,
        clients: state.clients.map((client) =>
          client.id === Number(payload.id)
            ? { ...client, blocked: payload.attributes.blocked }
            : client
        ),
        clientDeleteLoading: false,
      };

    case "DELETE_INVESTOR_SUCCESS":
      return {
        ...state,
        client: payload,
        investors: state.investors.map((client) =>
          client.id === Number(payload.id)
            ? { ...client, blocked: payload.attributes.blocked }
            : client
        ),
        clientDeleteLoading: false,
      };

    case "DELETE_CLIENT_FAILED":
      return {
        ...state,
        error: payload,
        clientDeleteLoading: false,
      };
    case "UPDATE_INVESTOR":
      return {
        ...state,
        investors: state.investors.map((client) =>
          client.id === payload.id ? payload : client
        ),
        clientsLoading: false,
      };
    case "FETCH_CLIENT_ITEM":
      return { ...state, client: payload, clientsLoading: false };
    default:
      return state;
  }
};
export default clientsReducer;
