import React from "react";
import { API_END_POINT } from "../../Api/EndPoints";
const Flag = ({ options, index }) => {
  if (!options) return null;
  const currentOption = options[index];
  const flagUrl = currentOption?.attributes?.flag?.data?.attributes?.url;

  return <img width={18} height={12} src={API_END_POINT + flagUrl} alt="" />;
};

export default Flag;
