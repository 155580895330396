const initialState = {
  info: false,
  infoMessage: null,
  error: false,
  errorMessage: null,
};

const toastMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_INFO":
      return {
        ...state,
        info: true,
        infoMessage: action.payload.message,
      };
    case "HIDE_INFO":
      return { ...state, info: false, infoMessage: null };
    case "SHOW_ERROR":
      return { ...state, error: true, errorMessage: action.payload };
    case "HIDE_ERROR":
      return { ...state, error: false, errorMessage: null };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        error: false,
        errorMessage: null,
        info: false,
        infoMessage: null,
      };
    default:
      return state;
  }
};
export default toastMessagesReducer;
