const initialState = {
  open: false, // false,
  openBlockModal: false,
  message: null, // null,
  message2: null, //null
  cancelButtonText: null,
  confirmButtonText: null, //null
  callBack: null,
  cancelCallback: null,
};

const confirmationModalReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_CONFIRMATION_MODAL":
      return {
        ...state,
        open: true,
        message: payload.message,
        message2: payload.message2,
        cancelButtonText: payload.cancelButtonText,
        confirmButtonText: payload.confirmButtonText,
        callBack: payload.callBack,
        cancelCallback: payload.cancelCallback,
      };
    case "CLOSE_CONFIRMATION_MODAL":
      return {
        ...state,
        open: false,
        message: null,
        message2: null,
        cancelButtonText: null,
        confirmButtonText: null,
        callBack: null,
        cancelCallback: null,
      };
    case "OPEN_BLOCK_MODAL":
      return {
        ...state,
        openBlockModal: true,
      };
    case "CLOSE_BLOCK_MODAL":
      return {
        ...state,
        openBlockModal: false,
      };
    default:
      return state;
  }
};

export default confirmationModalReducer;
