import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useResponsive from "../../../utils/useResponsive";

const CercledButton = ({ iconColor, icon, callback, bgColor, disabled }) => {
  const isMobileSize = useResponsive();
  return (
    <button
      className={` alignC  m-1 ${
        isMobileSize ? "cercledButtonResp" : "cercledButton"
      } bg-${bgColor}`}
      disabled={disabled}
      onClick={callback}
    >
      <FontAwesomeIcon icon={icon} className={`text-${iconColor}`} />
    </button>
  );
};

export default CercledButton;
