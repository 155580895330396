import React, { useEffect, useState } from "react";
import { sortFunction } from "../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./SelectPhoneNumber.css";
import { useSelector } from "react-redux";
import Flag from "../Flag";
import Text from "../Text";
// validating international phone dependencies :
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

/* -------------------------------------------------------------------------- */
/*                                 componenet                                 */
/* -------------------------------------------------------------------------- */
const SelectPhoneNumber = ({ value, label, required }) => {
  const { countries } = useSelector((state) => state.countriesReducer);

  const [isListOpen, setIsListOpen] = useState(false);
  const [number, setNumber] = useState();
  const [selected, setSelected] = useState();
  const retrieveCode = () => {
    const number = phoneUtil.parseAndKeepRawInput(value);
    setNumber(phoneUtil.parseAndKeepRawInput(value)?.values_["2"]);

    const code = "+" + number.getCountryCode();
    setSelected(
      countries.findIndex(({ attributes }) => {
        return attributes?.prefixCode === code;
      })
    );
  };
  const handleSelect = (index) => {
    setSelected(index);
    setIsListOpen(false);
  };
  function handleBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsListOpen(false);
    }
  }
  const handleChange = (e) => {
    setNumber(e.target.value);
  };

  useEffect(() => {
    value?.length > 0 && countries?.length > 0 && retrieveCode();
    // eslint-disable-next-line
  }, [value, countries]);
  return (
    <div>
      <Text className={"xbold f12 mb-2"}>
        {label} {required ? <span className="text-red">*</span> : null}
      </Text>

      <div
        className={`containerSelect  ${isListOpen && "listOpen"}`}
        tabIndex="-1"
        onBlur={handleBlur}
      >
        <div className="alignC">
          <div
            className={"selectButton ps-2 "}
            onClick={() => setIsListOpen(!isListOpen)}
          >
            <Flag options={countries} index={selected} />

            <div className=" f12 ms-2">
              {countries[selected]?.attributes?.code}
            </div>

            <FontAwesomeIcon
              icon={faChevronDown}
              className={`icon ms-1 ${isListOpen && "listOpen"}`}
            />
          </div>
          <Text className=" f12 ms-2 me-1">
            {countries[selected]?.attributes?.prefixCode}
          </Text>
          <input
            type="tel"
            className="pof-input w-100 "
            placeholder="phone number"
            name="phoneNumber"
            value={number}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <ul className={`optionList   ${isListOpen && "listOpen"}`}>
          {sortFunction(countries, "countryName", true).map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleSelect(index)}
                className="optionItem"
              >
                <Flag options={countries} index={index} />
                <span className="m-3 f12 text-dark ">
                  {item.attributes.countryName}
                </span>
                <span className=" f12 text-dark ">
                  {item.attributes.prefixCode}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SelectPhoneNumber;
