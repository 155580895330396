import { DELETE_CLUBDEAL, UPDATE_DEAL_STATE } from "../Graphql/Mutations/Deal";
import { GET_DEALS, GET_DEAL_BY_ID } from "../Graphql/Queries/Deal";
import client from "./Client";
import { handleError, handleInfo } from "./toastMessage";

export const getAllDeals =
  (page = 1, pageSize = 20, search, sort) =>
  async (dispatch) => {
    dispatch({ type: "DEALS_LOADING" });

    try {
      const { data } = await client.query({
        query: GET_DEALS(page, pageSize, search, sort),
      });
      dispatch({
        type: "FETCH_DEALS",
        payload: data?.clubDeals?.data,
        totalPages: data?.clubDeals?.meta?.pagination?.pageCount,
        page: data?.clubDeals?.meta?.pagination?.page,
        total: data?.clubDeals?.meta?.pagination?.total,
      });
    } catch (error) {
      console.log(error);

      dispatch(handleError("Error try again later", 1));
    }
  };

export const UpdateDealState = (state, dealId) => async (dispatch) => {
  dispatch({ type: "DEALS_LOADING" });
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_DEAL_STATE,
      variables: {
        id: parseInt(dealId),
        state: state,
      },
    });

    dispatch({
      type: "UPDATE_DEALS",
      payload: data.updateClubDeal.data,
    });
    dispatch({
      type: "FETCH_DEAL_ITEM",
      payload: data.updateClubDeal.data,
    });

    dispatch(handleInfo("Deal state updated", 1));
  } catch (error) {
    console.log(error);

    dispatch(handleError("Error try again later", 1));
  }
};

export const getDealItem = (id) => async (dispatch) => {
  try {
    const { data } = await client.query({
      query: GET_DEAL_BY_ID(id),
    });

    dispatch({
      type: "FETCH_DEAL_ITEM",
      payload: data.clubDeals.data[0],
    });
  } catch (error) {
    console.log(error);
    dispatch(handleError("Error try again later", 1));
  }
};

export const deleteClubdeal = (id) => async (dispatch) => {
  dispatch({ type: "DELETE_CLUBDEALS_LOADING" });
  try {
    const { data } = await client.mutate({
      mutation: DELETE_CLUBDEAL,
      variables: { id },
    });
    dispatch({
      type: "DELETE_CLUBDEALS_SUCCES",
      payload: data?.deleteClubdeal?.data?.id,
    });
  } catch (error) {
    dispatch({ type: "DELETE_CLUBDEALS_FAIL" });
  }
};
