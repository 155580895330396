import React, { useState } from "react";
import Text from "./Text";
import { useDispatch } from "react-redux";
import "./Uploader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { setFile } from "../../REDUX/actions/files";
import { API_END_POINT } from "../../Api/EndPoints";
const Uploader = ({ label, totalInputs, setValue, value, champs }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChangeInput = ({ target: { files } }) => {
    // setfilesNames(files.length === 1 ? files[0].name : "");
    setSelectedFile(files[0]);
    setValue({ ...totalInputs, [champs]: files[0].name });

    dispatch(setFile({ files, champs }));
  };

  return (
    <div className="attachUploaderContainer">
      <div className="d-flex justify-content-between">
        <Text className={"xbold f12 mb-2"}>{label}</Text>
      </div>
      <label className={`attachUploadLabel `}>
        <input
          type="file"
          className="attachUploaderButton"
          onChange={handleChangeInput}
        />
        <div className={`attachFilesText `}>{value}</div>
        <FontAwesomeIcon icon={faCamera} />
      </label>
      {selectedFile || value ? (
        <div className={`${champs === "photo" ? "photo" : "coverP"}`}>
          <img
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : API_END_POINT + "/uploads/" + value
            }
            alt=""
          />
        </div>
      ) : null}
    </div>
  );
};

export default Uploader;
