import React from "react";
import { assetsUnderManagement, companyType } from "../../utils/SelectData";
import { useDispatch } from "react-redux";
import Text from "../Common/Text";
import { openModal } from "../../REDUX/actions/modal";
import CompanyDetails from "./CompanyDetails";
import { useNavigate } from "react-router-dom";
import CercledButton from "../Common/Button/CercledButton";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { UpdateCompanyState } from "../../REDUX/actions/company";
import { openConfirmationModal } from "../../REDUX/actions/ConfirmationModal";
import Switch from "../Common/Button/Switch";
import { formatDate } from "../../utils/utils";

const CompanyItem = ({ item, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nav = (item) => {
    dispatch({ type: "FETCH_COMPANY_ITEM", payload: item });
    navigate(`/companies/${item.id}`);
  };
  const updateCompanyState = (company) => {
    dispatch(
      openConfirmationModal({
        callBack: () =>
          dispatch(
            UpdateCompanyState(
              company?.attributes.state === 1 ? 2 : 1,
              company?.id
            )
          ),
        icon: "archive",
        message: `Would you like to ${
          company?.attributes.state === 1 ? "disactivate" : "activate"
        } this  company ?`,
        message2: `${company?.attributes.companyName} `,
        confirmButtonText: `${
          company?.attributes.state === 1 ? "disactivate" : "activate"
        }`,
        cancelButtonText: "Cancel",
      })
    );
  };

  const companyWebsite = item?.attributes?.website;
  return (
    <div
      className={`container-fluid w100 ${index % 2 === 0 ? "bg-darkGrey" : ""}`}
    >
      <div className="row">
        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>{item.id}</Text>
        </div>
        <div className="alignC bordered eltList col-2">
          <Text className={"f14 regular "}>{item.attributes.companyName}</Text>
        </div>
        <div className="alignC bordered eltList col-1">
          <Text className={"f14 regular "}>
            {
              companyType.find(
                (company) => company.value === item.attributes.companyType
              )?.label
            }
          </Text>
        </div>
        <div className="alignC bordered eltList col-2">
          <Text className={"f14 regular "}>
            {
              assetsUnderManagement.find(
                (asset) => asset.value === item.attributes.assetsUnderManagement
              )?.label
            }
          </Text>
        </div>
        <div className="alignC bordered eltList col-2">
          <a
            href={`${companyWebsite}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {companyWebsite}
          </a>
        </div>
        <div className="alignC bordered eltList col-1">
          {formatDate(item.attributes.createdAt)}
        </div>
        <div className="alignC bordered eltList col-1">
          {item.attributes.users?.data?.length}
        </div>
        <div className="alignC gap-2 bordered eltList col-2">
          {/* <CercledButton
            iconColor={"black"}
            icon={faPen}
            bgColor={"white"}
            callback={() => updateCompanyState(item)}
          /> */}
          <CercledButton
            iconColor={"black"}
            icon={faEye}
            bgColor={"white"}
            callback={() =>
              dispatch(openModal(<CompanyDetails companyId={item.id} />))
            }
          />
          <CercledButton
            iconColor={"white"}
            icon={faPenToSquare}
            bgColor={"blue"}
            callback={() => nav(item)}
          />
          <Switch
            value={item?.attributes.state !== 2}
            callBack={() => updateCompanyState(item)}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
