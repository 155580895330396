const initialState = {
  deals: [],
  dealsLoading: false,
  deal: null,
  totalDeals: 0,
};

const dealsReducer = (
  state = initialState,
  { type, payload, totalPages, page, total }
) => {
  switch (type) {
    case "DEALS_LOADING":
      return { ...state, dealsLoading: true };
    case "FETCH_DEALS":
      return {
        ...state,
        deals: payload,
        totalPages: totalPages,
        pageNumber: page,
        dealsLoading: false,
        totalDeals: total,
      };
    case "FETCH_DEAL_ITEM":
      return { ...state, deal: payload };
    case "UPDATE_DEALS":
      return {
        ...state,
        deals: state.deals.map((deal) =>
          deal.id === payload.id ? payload : deal
        ),
        dealsLoading: false,
      };
    default:
      return state;
  }
};
export default dealsReducer;
