import { gql } from "@apollo/client";

export const UPLOAD = gql`
  mutation ($file: Upload!) {
    upload(file: $file) {
      data {
        id
      }
    }
  }
`;

export const DELETE_FILE = gql`
  mutation ($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
      }
    }
  }
`;
