import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Common/Button/Button";
import Input from "../../Components/Common/Input";
import Select from "../../Components/Common/Selects/Select";
import SelectCountry from "../../Components/Common/Selects/SelectCountry";
import Text from "../../Components/Common/Text";
import Uploader from "../../Components/Common/Uploader";
import { CreateCompany } from "../../REDUX/actions/company";
import { assetsUnderManagement, companyType } from "../../utils/SelectData";
import useResponsive from "../../utils/useResponsive";
import "./NewCompanyView.css";
const InitialInput = {
  companyName: "",
  website: "",
  addresse: "",
  country: null,
  zipCode: 0,
  assetsUnderManagement: null,
  companyType: null,
  expertise: null,
  aboutUs: "",
  logo: "",
  state: 0,
};
const NewCompanyView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedFile } = useSelector((state) => state.filesReducer);
  const { countries } = useSelector((state) => state.countriesReducer);

  const [companyInput, setCompanyInput] = useState(InitialInput);
  const handleSubmit = () => {
    dispatch(CreateCompany(companyInput, { file: selectedFile }));
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const isMobileSize = useResponsive();
  return (
    <div className="container-fluid h6">
      <div className="d-flex mb-4 alignH ">
        <Text className={"bold f25 text-deepBlue "}>New Company</Text>
      </div>
      <div className=" border-0">
        <div className={`${isMobileSize ? "w70" : "w80 d-flex "}`}>
          <div className="col m-2">
            <Input
              label={"Name"}
              required
              setValue={setCompanyInput}
              champs="companyName"
              totalInputs={companyInput}
              value={companyInput.companyName}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Website"}
              required
              setValue={setCompanyInput}
              champs="website"
              totalInputs={companyInput}
              value={companyInput.website}
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Adresse"}
              required
              setValue={setCompanyInput}
              champs="addresse"
              totalInputs={companyInput}
              value={companyInput.addresse}
            />
          </div>
        </div>
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"}`}>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>Country</Text>
            <SelectCountry
              totalInputs={companyInput}
              setValue={setCompanyInput}
              champs="country"
              value={
                companyInput.country
                  ? countries.find(
                      (country) => country.id === companyInput.country
                    ).attributes.countryName
                  : null
              }
            />
          </div>
          <div className="col m-2">
            <Input
              label={"Zip Code"}
              required
              type="number"
              setValue={setCompanyInput}
              champs="zipCode"
              totalInputs={companyInput}
              value={companyInput.zipCode}
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>
              Asset under management<span className="text-red">*</span>
            </Text>
            <Select
              list={assetsUnderManagement}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              champs="assetsUnderManagement"
              value={
                companyInput.assetsUnderManagement !== null
                  ? assetsUnderManagement.find(
                      (asset) =>
                        asset.value === companyInput.assetsUnderManagement
                    ).label
                  : null
              }
            />
          </div>
        </div>
        <div className={`${isMobileSize ? "w70" : "w80 d-flex"} `}>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>
              Type of company <span className="text-red">*</span>
            </Text>
            <Select
              list={companyType}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              champs="companyType"
              value={
                companyInput.companyType
                  ? companyType.find(
                      (company) => company.value === companyInput.companyType
                    ).label
                  : null
              }
            />
          </div>
          <div className="col m-2">
            <Uploader
              label={"logo"}
              totalInputs={companyInput}
              setValue={setCompanyInput}
              value={companyInput.logo}
              champs="logo"
            />
          </div>
          <div className="col m-2">
            <Text className={"xbold f12 mb-2"}>About Us:</Text>

            <textarea
              className=" transition02  aboutUsContainer f12  p-2  "
              rows="5"
              value={companyInput.aboutUs}
              onChange={(e) =>
                setCompanyInput({ ...companyInput, aboutUs: e.target.value })
              }
            />
          </div>
        </div>
        <div
          className={`${
            isMobileSize ? "w70" : "w80 d-flex"
          } justify-content-end `}
        >
          <div className="d-flex  justify-content-end">
            <Button
              color={"blue"}
              className={"bg-white "}
              textButton="Cancel"
              callback={handleCancel}
            />
            <Button
              color={"white"}
              className={"bg-blue "}
              textButton="Submit"
              disabled={!companyInput.companyName || !companyInput.website}
              callback={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompanyView;
